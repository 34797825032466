import axiosClient from "../helpers/axiosClient";
import {SHIFTS, REACT_APP_CHAT, API_PAGES, API_SHIFTS, API_USER} from "./constants";
import {combineUrlParams} from "./../helpers/index";

export const getListShift = (page_id , page = 1 , limit = 50 , sort = '-from') => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${SHIFTS}`,
            {
                page : page,
                limit : limit,
                sort : sort,
            }
        )
    );
}

export const createShift = (page_id, user_id , from , to) => {
    let data = {};
    data.user_id = user_id;
    data.from = from;
    data.to = to;
    return axiosClient.post(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${SHIFTS}` , data);
}

export const removeShift = (shift_id) => {
    return axiosClient.delete(`${REACT_APP_CHAT}/${API_SHIFTS}/${shift_id}`);
}

export const editShift = (shift_id , from , to) => {
    let data = {};
    data.from = from;
    data.to = to;
    return axiosClient.put(`${REACT_APP_CHAT}/${API_SHIFTS}/${shift_id}` , data);
}

export const getStatusShift = () => {
    return axiosClient.get(`${REACT_APP_CHAT}/${API_USER}`);
}

export const changeActionShift = (action) => {
    let data = {};
    data.action = action;
    return axiosClient.post(`${REACT_APP_CHAT}/${API_SHIFTS}` , data);
}
