import {
    GET_LIST_QUICK_REPLY,
    GET_LIST_QUICK_REPLY_SUCCESS,
    GET_MORE_QUICK_REPLY, RESET_PAGE_PAGINATION_QUICK_REPLY,
    SAVE_SUGGEST_REPLY, SET_PAGE_PAGINATION_QUICK_REPLY
} from './constants';

import * as quickReplyApis from "./../../apis/quickReply";
import {toast} from "react-toastify";

export const getListQuickReply = () => ({
    type: GET_LIST_QUICK_REPLY,
    payload: {}
});

export const getListQuickReplySuccess = (listQuickReply) => ({
    type: GET_LIST_QUICK_REPLY_SUCCESS,
    payload: {
        listQuickReply: listQuickReply
    }
});

export const saveSuggestReply = (listSuggestReply) => ({
    type: SAVE_SUGGEST_REPLY,
    payload: {
        listSuggestReply: listSuggestReply
    }
});

export const getMoreQuickReply = (listQuickReply) => ({
    type: GET_MORE_QUICK_REPLY,
    payload: {
        listQuickReply: listQuickReply
    }
});

export const setPagePaginationQuickReply = (page) => ({
    type: SET_PAGE_PAGINATION_QUICK_REPLY,
    payload: {
        page: page
    }
});

export const resetPagePaginationQuickReply = (page) => ({
    type: RESET_PAGE_PAGINATION_QUICK_REPLY,
    payload: {
        page: page
    }
});

export const saveSuggestReplyRequest = (suggestReply) => {
    return (dispatch, getState) => {
        let listQuickReply = getState().QuickReply.listQuickReply;
        let listSuggestReply = listQuickReply.filter(reply => {
            return suggestReply && reply.name.toLowerCase().includes(suggestReply.toLowerCase()) || suggestReply && reply.content.toLowerCase().includes(suggestReply.toLowerCase());
        });
        dispatch(saveSuggestReply(listSuggestReply));
    }
}

export const getListQuickReplyRequest = (page_id) => {
    return dispatch => {
        dispatch(getListQuickReply());
        quickReplyApis.getListQuickReply(page_id)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListQuickReplySuccess(data));
            }).catch(error => {

        });
    }
}

export const createQuickReplyRequest = (data) => {
    return (dispatch, getState) => {
        const page_id = getState().Page.page_id;
        quickReplyApis.createQuickReply(page_id, data)
            .then(resp => {
                quickReplyApis.getListQuickReply(page_id)
                    .then(resp => {
                        const {data} = resp.data.data;
                        dispatch(getListQuickReplySuccess(data));
                        toast.success('Create quick reply success !');
                    }).catch(error => {

                });
            }).catch(error => {

        });
    }
}

export const removeQuickReplyRequest = (quick_reply_id) => {
    return (dispatch, getState) => {
        const page_id = getState().Page.page_id;
        quickReplyApis.removeQuickReply(quick_reply_id)
            .then(resp => {
                quickReplyApis.getListQuickReply(page_id)
                    .then(resp => {
                        const {data} = resp.data.data;
                        dispatch(getListQuickReplySuccess(data));
                        toast.success('Remove quick reply success !');
                    }).catch(error => {
                });
            }).catch(error => {
        });
    }
}

export const editQuickReplyRequest = (quick_reply_id, data) => {
    return (dispatch, getState) => {
        const page_id = getState().Page.page_id;
        quickReplyApis.editQuickReply(quick_reply_id, data)
            .then(resp => {
                quickReplyApis.getListQuickReply(page_id)
                    .then(resp => {
                        const {data} = resp.data.data;
                        dispatch(getListQuickReplySuccess(data));
                        toast.success('Edit quick reply success !');
                    }).catch(error => {
                });
            }).catch(error => {
        });
    }
}

export const searchQuickReplyRequest = (page_id, name) => {
    return dispatch => {
        dispatch(getListQuickReply());
        quickReplyApis.getListQuickReply(page_id, name)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListQuickReplySuccess(data));
            }).catch(error => {

        });
    }
}

export const getMoreQuickReplyRequest = (page_id, page , name) => {
    return (dispatch, getState) => {
        let listQuickReply = getState().QuickReply.listQuickReply;
        quickReplyApis.getMoreListQuickReply(page_id, page , name)
            .then(resp => {
                const {data} = resp.data.data;
                let listQuickReplyUpdate = [...listQuickReply].concat(data);
                dispatch(getMoreQuickReply(listQuickReplyUpdate));
            }).catch(error => {

        });
    }
}

