import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    API_FAILED,
    TOKEN_GOOGLE,
    TOKEN_GOOGLE_ERROR,
    TOKEN_GOOGLE_SUCCESS,
    LOGIN_FORM,
    LOGIN_FORM_SUCCESS,
    LOGIN_FORM_UPDATE,
    LOGIN_FORM_ERROR, LOGOUT_USER_EXPIRED
} from './constants';

import {getLoggedInUser} from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {...state, loading: true};
        case LOGIN_USER_SUCCESS:
            return {...state, user: action.payload, loading: false, error: null};

        case REGISTER_USER:
            return {...state, loading: true};
        case REGISTER_USER_SUCCESS:
            return {...state, user: action.payload, loading: false, error: null};

        case LOGOUT_USER:
            return {...state, user: null};

        case LOGOUT_USER_EXPIRED:
            return {...state, user: null};

        case FORGET_PASSWORD:
            return {...state, loading: true};
        case FORGET_PASSWORD_SUCCESS:
            return {...state, passwordResetStatus: action.payload, loading: false, error: null};

        case API_FAILED:
            return {...state, loading: false, error: action.payload};

        case TOKEN_GOOGLE:
            return {...state};
        case TOKEN_GOOGLE_SUCCESS:
            return {...state, user: action.payload.user , loading: false , error: null};
        case TOKEN_GOOGLE_ERROR:
            return {...state};

        case LOGIN_FORM:
            return {...state};
        case LOGIN_FORM_SUCCESS:
            return {...state, user: action.payload.user , loading: false , error: null};
        case LOGIN_FORM_UPDATE:
            return {...state, user: action.payload.user , loading: false , error: null};
        case LOGIN_FORM_ERROR:
            return {...state};

        default:
            return {...state};
    }
}

export default Auth;
