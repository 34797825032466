import axiosClient from "../helpers/axiosClient";
import {axiosFromDataClient} from "../helpers/axiosFromDataClient";
import {API_CHATS, CONVERSATIONS, MESSAGES, REACT_APP_CHAT} from "./constants";
import {combineUrlParams} from "./../helpers/index";

export const getListMessage = (chat_id , before , keyword , sort= "-time" , limit= 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${MESSAGES}`,
            {
                sort : sort,
                before : before,
                keyword : keyword,
                limit : limit,
            }
        )
    );
}

export const sendMessage = (chat_id , data ) => {
    const formData = new FormData();
    if(data.message){
        formData.append("message", data.message);
    }
    if(data.file){
        formData.append("file", data.file);
    }
    return axiosFromDataClient.post(`${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${MESSAGES}` , formData);
}

export const getMessageInConversation = (chat_id , conversation_id , before , keyword = null , sort= "-time" , limit= 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${CONVERSATIONS}/${conversation_id}/${MESSAGES}`,
            {
                sort : sort,
                before : before,
                keyword : keyword,
                limit : limit,
            }
        )
    );
}

export const getMessageInAllConversation = (chat_id , conversation_id , before , sort= "-time" , limit= 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${CONVERSATIONS}/${conversation_id}/${MESSAGES}`,
            {
                sort : sort,
                before : before,
                limit : limit,
            }
        )
    );
}
