export const REACT_APP_CHAT = process.env.REACT_APP_CHAT;
export const REACT_APP_AUTH = process.env.REACT_APP_AUTH;
export const REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
export const REACT_APP_AUTH_CLIENT_SECRET = process.env.REACT_APP_AUTH_CLIENT_SECRET;
export const API_PAGES = "api/pages";
export const API_CHATS = "api/chats";
export const CHATS = "chats";
export const MESSAGES = "messages";
export const OAUTH_TOKEN_GOOGLE = "oauth/token/google";
export const CONVERSATIONS = "conversations";
export const API_CONVERSATIONS = "api/conversations";
export const OAUTH_TOKEN = "oauth/token";
export const API_ME = "api/me";
export const TAGS = "tags";
export const CONVERSATIONS_TAGS = "conversations/tags";
export const API_TAGS = "api/tags";
export const ROLES = "roles";
export const ROLES_ASSIGN = "roles/assign";
export const USERS = "users";
export const QUICK_REPLIES = "quick-replies";
export const API_QUICK_REPLIES = "api/quick-replies";
export const API_LOGOUT = "api/auth/logout";
export const SHIFTS = "shifts";
export const API_SHIFTS = "api/shifts";
export const API_USER = "api/user";
export const API_CONVERSATIONS_TAGS = "api/conversations/tags";
export const REPORTS = "reports";
export const API_REPORTS_PAGES = "api/reports/pages";
export const REPORT_TAGS = "report/tags";
export const API_MANAGERS = "api/managers";
export const API_REPORTS_PAGES_MANAGER = "api/reports/pages/manager";
export const API_ARCHIVES = "api/archives";
export const API_FACEBOOK_APP = "api/facebook-apps";

export const FACEBOOK_URL = "https://www.facebook.com";
export const FACEBOOK_GRAPH_URL = "https://graph.facebook.com";
export const LIST_FACEBOOK_SCOPES = "pages_show_list,pages_messaging,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement,public_profile";
