import {
    GET_LIST_QUICK_REPLY,
    GET_LIST_QUICK_REPLY_SUCCESS,
    GET_MORE_QUICK_REPLY, RESET_PAGE_PAGINATION_QUICK_REPLY,
    SAVE_SUGGEST_REPLY,
    SET_PAGE_PAGINATION_QUICK_REPLY,
} from './constants';

const INIT_STATE = {
    listQuickReply: [],
    listSuggestReply: null,
    page: 1
};

const QuickReply = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_QUICK_REPLY:
            return {
                ...state,
                listQuickReply: []
            };

        case GET_LIST_QUICK_REPLY_SUCCESS:
            return {
                ...state,
                listQuickReply: action.payload.listQuickReply,
            };

        case SAVE_SUGGEST_REPLY:
            return {
                ...state,
                listSuggestReply: action.payload.listSuggestReply,
            };

        case GET_MORE_QUICK_REPLY:
            return {
                ...state,
                listQuickReply: action.payload.listQuickReply,
            };

        case SET_PAGE_PAGINATION_QUICK_REPLY:
            return {
                ...state,
                page: action.payload.page,
            };

        case RESET_PAGE_PAGINATION_QUICK_REPLY:
            return {
                ...state,
                page: action.payload.page
            };

        default:
            return {...state};
    }
}

export default QuickReply;
