import {
    GET_LIST_CONVERSATION,
    GET_LIST_CONVERSATION_SUCCESS,
    GET_LIST_CONVERSATION_ERROR,
    CREATE_CONVERSATION,
    CREATE_CONVERSATION_SUCCESS,
    CREATE_CONVERSATION_ERROR,
    SET_CONVERSATION_ITEM,
    SET_BEFORE_CONVERSATION_LOAD_MORE,
    UPDATE_STATUS_CONVERSATION,
    GET_LIST_ALL_CONVERSATION_IN_PAGE,
    GET_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS,
    GET_LIST_ALL_CONVERSATION_IN_PAGE_ERROR,
    SORT_RATING_ALL_CONVERSATION_IN_PAGE_SUCCESS,
    SORT_RATING_ALL_CONVERSATION_IN_PAGE,
    UPDATE_LIST_ALL_CONVERSATION_IN_PAGE,
    UPDATE_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS, UPDATE_PAGE_LOAD_MORE_SORT_CONVERSATION,
} from './constants';

import * as conversationApis from "./../../apis/conversations";
import {toast} from "react-toastify";
import {
    doneConversationSuccess,
    filterSearchChatSuccess,
    getListChatError,
    getListChatRequest,
    getListChatSuccess, removeAllCheckedToChatSuccess,
    updateChatAfterDoneConversation,
    updateChatAfterDoneMultiConv
} from "../chat/actions";
import * as chatApis from "../../apis/chat";
import {
    getListTagInConversationRequest,
    getListTagRequest,
    updateListObjTagInConversationAfterDone, updateListTagInConversationAfterDone, updateListTagsAfterDone
} from "../tag/actions";
import {UPDATE_PAGE_LOAD_MORE_CHAT} from "../chat/constants";

export const getListConversation = (chat_id) => ({
    type: GET_LIST_CONVERSATION,
    payload: chat_id
});

export const getListConversationSuccess = (data) => ({
    type: GET_LIST_CONVERSATION_SUCCESS,
    payload: {
        data
    }
});

export const getListConversationError = (error) => ({
    type: GET_LIST_CONVERSATION_ERROR,
    payload: {
        error
    }
});

export const getListConversationInPage = (page_id) => ({
    type: GET_LIST_ALL_CONVERSATION_IN_PAGE,
    payload: page_id
});

export const getListConversationInPageSuccess = (data) => ({
    type: GET_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS,
    payload: {
        data
    }
});

export const getListConversationInPageError = (error) => ({
    type: GET_LIST_ALL_CONVERSATION_IN_PAGE_ERROR,
    payload: {
        error
    }
});


export const createConversation = (chat_id) => ({
    type: CREATE_CONVERSATION,
    payload: chat_id
});

export const createConversationSuccess = (data) => ({
    type: CREATE_CONVERSATION_SUCCESS,
    payload: {
        data
    }
});

export const createConversationError = (error) => ({
    type: CREATE_CONVERSATION_ERROR,
    payload: {
        error
    }
});

export const setConversationItemSuccess = (conversation_id) => ({
    type: SET_CONVERSATION_ITEM,
    payload: {
        conversation_id
    }
});

export const setConversationLoadMoreSuccess = (loadMore) => ({
    type: SET_BEFORE_CONVERSATION_LOAD_MORE,
    payload: {
        loadMore : loadMore
    }
});

export const updateStatusConversation = (data) => ({
    type: UPDATE_STATUS_CONVERSATION,
    payload: {
        data : data
    }
});

export const sortRatingConversation = () => ({
    type: SORT_RATING_ALL_CONVERSATION_IN_PAGE,
    payload: {}
});

export const sortRatingConversationSuccess = (data) => ({
    type: SORT_RATING_ALL_CONVERSATION_IN_PAGE_SUCCESS,
    payload: {
        data : data
    }
});

export const updateListConversationInPage = () => ({
    type: UPDATE_LIST_ALL_CONVERSATION_IN_PAGE,
    payload: {}
});

export const updateListConversationInPageSuccess = (data) => ({
    type: UPDATE_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS,
    payload: {
        data : data
    }
});

export const updatePageLoadMoreSortConversation = (data) => ({
    type: UPDATE_PAGE_LOAD_MORE_SORT_CONVERSATION,
    payload: {
        data: data
    }
});

export const sortRatingConversationRequest = (sort) => {
    return dispatch => {
        dispatch(sortRatingConversationSuccess(sort));
    }
}

export const updateListConversationInPageRequest = (data) => {
    return dispatch => {
        dispatch(updateListConversationInPage());
        conversationApis.getListAllConversationsInPage(data.page_id , data.rating)
            .then(resp => {
                const { data } = resp.data.data;
                dispatch(updateListConversationInPageSuccess(data))
            }).catch(error => {
            console.log(error)
        });
    }
}

export const loadMoreListConversationInPageRequest = (data) => {
    return (dispatch, getState) => {
        let listConversationsInPage = getState().Conversation.listConversationsInPage;
        conversationApis.getListAllConversationsInPage(data.page_id , data.rating , data.page)
            .then(resp => {
                const { data } = resp.data.data;
                let listConversationsInPageUpdate = [...listConversationsInPage].concat(data);
                console.log(listConversationsInPageUpdate)
                dispatch(updateListConversationInPageSuccess(listConversationsInPageUpdate))
            }).catch(error => {
            console.log(error)
        });
    }
}

export const updateStatusConversationRequest = (id , status , status_name , conversationNew = null) => {
    return (dispatch, getState) => {
        var listConversations = getState().Conversation.listConversations;
        if(listConversations){
            var listConversationsUpdate = [...listConversations];
            var index = listConversationsUpdate.findIndex(conversation => conversation.id === id);
            if(index !== -1){
                listConversationsUpdate[index].status_name = status_name;
                listConversationsUpdate[index].status = status;
                dispatch(updateStatusConversation(listConversationsUpdate));
            }else if(index === -1 && conversationNew){
                dispatch(updateStatusConversation([conversationNew , ...listConversationsUpdate]));
            }
        }
    }
}

export const getListConversationRequest = (chat_id) => {
    return dispatch => {
        dispatch(getListConversation(chat_id));
        conversationApis.getListConversations(chat_id)
            .then(resp => {
                const { data } = resp.data.data;
                dispatch(getListConversationSuccess(data))
            }).catch(error => {
                dispatch(getListConversationError(error));
            });
    }
}

export const getListConversationInPageRequest = (page_id , rating = null) => {
    return dispatch => {
        dispatch(getListConversationInPage(page_id));
        conversationApis.getListAllConversationsInPage(page_id , rating)
            .then(resp => {
                const { data } = resp.data.data;
                dispatch(getListConversationInPageSuccess(data))
            }).catch(error => {
                dispatch(getListConversationInPageError(error));
            });
    }
}

export const createConversationRequest = (chat_id) => {
    return (dispatch, getState) => {
        let listChats = getState().Chat.listChats;
        let listTags = getState().Tag.listFullTags;
        dispatch(createConversation(chat_id));
        conversationApis.createConversations(chat_id)
            .then(resp => {
                let data  = resp.data.data;
                let id = resp.data.data.id;
                let status = resp.data.data.status;
                let status_name = resp.data.data.status_name;
                if(resp.data.code === 0){
                    let listChatsUpdate = [...listChats];
                    let index = listChats.findIndex(chat => chat._id === chat_id);
                    if(index !== -1){
                        listChatsUpdate[index].assignee_id = 0;
                        listChatsUpdate[index].assignee = null;
                        dispatch(doneConversationSuccess(listChatsUpdate));
                    }
                    dispatch(createConversationSuccess(data));
                    dispatch(updateStatusConversationRequest(id , status , status_name));
                    toast.success("Tạo conversation thành công !");
                    dispatch(updateListObjTagInConversationAfterDone());
                    dispatch(updateListTagInConversationAfterDone());
                    dispatch(updateListTagsAfterDone(listTags));
                }
            }).catch(error => {
                dispatch(createConversationError(error));
            });
    }
}

export const createMultiConversationRequest = (body , textSearch) => {
    return (dispatch, getState) => {
        let page_id = getState().Page.page_id;
        let listTags = getState().Tag.listFullTags;
        conversationApis.createMultiConversations(page_id , body)
            .then(resp => {
                if(resp.data.code === 0){
                    dispatch(updateListChatAndMultiStatusConversationRequest(textSearch));
                    dispatch(removeAllCheckedToChatSuccess());
                    dispatch(updateListObjTagInConversationAfterDone());
                    dispatch(updateListTagInConversationAfterDone());
                    dispatch(updateListTagsAfterDone(listTags));
                }
            }).catch(error => {
            dispatch(createConversationError(error));
        });
    }
}

export const updateListChatAndMultiStatusConversationRequest = (textSearch) => {
    return (dispatch, getState) => {
        let chat_id = getState().Chat.chat_id;
        let page_id = getState().Page.page_id;
        let assignee_id = getState().Chat.filterChat.assign && getState().Chat.filterChat.assign.value;
        let tags = getState().Chat.filterChat.tags && getState().Chat.filterChat.tags;
        let status = getState().Chat.filterChat.status && getState().Chat.filterChat.status.value;
        let sort = getState().Chat.filterChat.sort && getState().Chat.filterChat.sort.value;
        if(chat_id){
            conversationApis.getListConversations(chat_id)
                .then(resp => {
                    const { data } = resp.data.data;
                    dispatch(getListConversationSuccess(data))
                }).catch(error => {
                dispatch(getListConversationError(error));
            });
        }

        if(page_id){
            chatApis.getListChatFilter(page_id , textSearch , assignee_id , tags , status , 1 , sort)
                .then(resp => {
                    const { data } = resp.data.data;
                    dispatch(filterSearchChatSuccess(data))
                    let checkboxes = document.getElementsByClassName('check-chat-item');
                    for(let i=0, n=checkboxes.length;i<n;i++) {
                        checkboxes[i].checked = false;
                    }
                    //uncheck all
                    let checkall = document.getElementsByClassName('checkbox-all');
                    for(let j=0, m=checkall.length;j<m;j++) {
                        checkall[j].checked = false;
                    }
                    toast.success("Done conversation success!");
                }).catch(error => {

                });

            // chatApis.getListChat(page_id)
            //     .then(resp => {
            //         const { data } = resp.data.data;
            //         dispatch(getListChatSuccess(data))
            //         let checkboxes = document.getElementsByClassName('check-chat-item');
            //         for(let i=0, n=checkboxes.length;i<n;i++) {
            //             checkboxes[i].checked = false;
            //         }
            //         //uncheck all
            //         let checkall = document.getElementsByClassName('checkbox-all');
            //         for(let j=0, m=checkall.length;j<m;j++) {
            //             checkall[j].checked = false;
            //         }
            //         toast.success("Done conversation success!");
            //     }).catch(error => {
            //         dispatch(getListChatError(error));
            //     });
        }

        // let listConversations = getState().Conversation.listConversations;
        // let listChats = getState().Chat.listChats;
        // if(listConversations){
        //     let listConversationsUpdate = [...listConversations];
        //     let listChatsUpdate = [...listChats];
        //     if(listChatId.length !== 0){
        //         listChatId.map((value) => {
        //             let i = listConversationsUpdate.findIndex(conversation => conversation.chat_id === value);
        //             let y = listChatsUpdate.findIndex(chat => chat._id === value);
        //
        //             listConversationsUpdate[0].status = 2;
        //             listConversationsUpdate[0].chat.status = 2;
        //             listConversationsUpdate[0].status_name = "DONE";
        //             listConversationsUpdate[0].chat.status_name = "DONE";
        //             listConversationsUpdate[0].chat.unread_message_count = 0;
        //
        //             listChatsUpdate[y].unread_message_count = 0;
        //             listChatsUpdate[y].status = 2;
        //             listChatsUpdate[y].status_name = "DONE";
        //             listChatsUpdate[y].last_conversation.status = 2;
        //             listChatsUpdate[y].last_conversation.status_name = "DONE";
        //         })
        //         dispatch(updateStatusConversation(listConversationsUpdate));
        //         dispatch(updateChatAfterDoneMultiConv(listChatsUpdate));
        //         toast.success("Done conversation success!");
        //     }
        // }
    }
}

export const setConversationItemRequest = (conversation_id) => {
    return dispatch => {
        dispatch(setConversationItemSuccess(conversation_id));
    }
}

export const addTagToMultiChatRequest = (tag_id) => {
    return (dispatch , getState) => {
        let chat_ids = getState().Chat.listChecked;
        let chat_id = getState().Chat.chat_id;
        let page_id = getState().Page.page_id;
        conversationApis.addTagToMultiChat(chat_ids , tag_id)
            .then(resp => {
                if(resp.data.code === 0){
                    toast.success("Add tag to conversation success!");
                    dispatch(removeAllCheckedToChatSuccess());
                    chat_id && dispatch(getListTagInConversationRequest(chat_id));
                    page_id && dispatch(getListTagRequest(page_id));
                    chat_id && dispatch(getListConversationRequest(chat_id));
                }
            }).catch(error => {

        });
    }
}

export const removeTagToMultiChatRequest = (tag_id) => {
    return (dispatch , getState) => {
        let chat_ids = getState().Chat.listChecked;
        let chat_id = getState().Chat.chat_id;
        let page_id = getState().Page.page_id;
        conversationApis.removeTagToMultiChat(chat_ids , tag_id)
            .then(resp => {
                if(resp.data.code === 0){
                    toast.success("Remove tag to conversation success!");
                    dispatch(removeAllCheckedToChatSuccess());
                    chat_id && dispatch(getListTagInConversationRequest(chat_id));
                    page_id && dispatch(getListTagRequest(page_id));
                    chat_id && dispatch(getListConversationRequest(chat_id));
                }
            }).catch(error => {

        });
    }
}



