
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const API_FAILED = "AUTH_API_FAILED";

export const TOKEN_GOOGLE = "TOKEN_GOOGLE";
export const TOKEN_GOOGLE_ERROR = "TOKEN_GOOGLE_ERROR";
export const TOKEN_GOOGLE_SUCCESS = "TOKEN_GOOGLE_SUCCESS";

export const LOGIN_FORM = "LOGIN_FORM";
export const LOGIN_FORM_ERROR = "LOGIN_FORM_ERROR";
export const LOGIN_FORM_SUCCESS = "LOGIN_FORM_SUCCESS";
export const LOGIN_FORM_UPDATE = "LOGIN_FORM_UPDATE";

export const LOGOUT_USER_EXPIRED = "LOGOUT_USER_EXPIRED";

