import { createStore, applyMiddleware, compose } from 'redux';
import thunk from "redux-thunk";
// import createSagaMiddleware from "redux-saga";
import reducers from './reducers';
// import sagas from "./sagas";

// const sagaMiddleware = createSagaMiddleware();
// const middlewares = [sagaMiddleware];
const middlewares = [thunk];

export function configureStore() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        reducers,
        composeEnhancers(
            applyMiddleware(...middlewares)
        ),
    );
    // sagaMiddleware.run(sagas);
    return store;
}
