import {
    CHECK_TAG_UPDATE_IN_CONVERSATION_ITEM,
    CHECK_TAG_UPDATE_IN_CONVERSATION_SUCCESS,
    CREATE_TAG,
    CREATE_TAG_ERROR,
    CREATE_TAG_SUCCESS, GET_FULL_LIST_TAG_SUCCESS,
    GET_LIST_TAG,
    GET_LIST_TAG_ERROR, GET_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    GET_LIST_TAG_IN_CONVERSATION_SUCCESS, GET_LIST_TAG_SIDEBAR,
    GET_LIST_TAG_SIDEBAR_SUCCESS,
    GET_LIST_TAG_SUCCESS, GET_PAGINATION_TAG_SUCCESS,
    LIST_OBJ_TAG_IN_CONVERSATION, LIST_OBJ_TAG_IN_CONVERSATION_ITEM,
    REMOVE_TAG_IN_CONVERSATION_SUCCESS, REMOVE_TAG_SIDEBAR_SUCCESS,
    REMOVE_TAG_SUCCESS, RESET_PAGE_PAGINATION_TAG,
    SAVE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    SAVE_LIST_TAG_IN_CONVERSATION_SUCCESS,
    SAVE_OBJ_TAG_IN_CONVERSATION, SAVE_OBJ_TAG_IN_CONVERSATION_ITEM, UPDATE_LIST_OBJ_TAG_IN_CONVERSATION_AFTER_DONE,
    UPDATE_LIST_TAG_IN_CONVERSATION, UPDATE_LIST_TAG_IN_CONVERSATION_AFTER_DONE,
    UPDATE_LIST_TAG_IN_CONVERSATION_ITEM,
    UPDATE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    UPDATE_LIST_TAG_IN_CONVERSATION_SUCCESS,
    UPDATE_LIST_TAGS, UPDATE_LIST_TAGS_AFTER_DONE,
    UPDATE_LIST_TAGS_ITEM,
    UPDATE_LIST_TAGS_ITEM_REMOVE,
    UPDATE_LIST_TAGS_REMOVE,
    UPDATE_OBJ_TAG_IN_CONVERSATION,
    UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM,
    UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    UPDATE_OBJ_TAG_IN_CONVERSATION_SUCCESS,
    UPDATE_TAG_SIDEBAR_SUCCESS
} from './constants';

const INIT_STATE = {
    listFullTags : [],
    listObjTagInConversation : [],
    listTagInConversation: [],
    listTags: [],
    listTagsSidebar: [],
    listObjTagInConversationItem : [],
    listTagInConversationItem: [],
    listTagsItem: [],
    tag_id: null,
    page: 1,
};

const Tag = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_TAG:
            return {
                ...state,
                listTags: []
            };

        case GET_LIST_TAG_SIDEBAR:
            return {
                ...state,
                listTagsSidebar: []
            };

        case GET_LIST_TAG_SUCCESS:
            const {data} = action.payload;
            return {
                ...state,
                listTags: data,
                listTagsItem: data,
            };

        case GET_FULL_LIST_TAG_SUCCESS:
            return {
                ...state,
                listFullTags: action.payload.data,
            };

        case GET_LIST_TAG_SIDEBAR_SUCCESS:
            return {
                ...state,
                listTagsSidebar: action.payload.data,
            };

        case GET_LIST_TAG_ERROR:
            return {
                ...state,
                listTags: []
            };

        case CREATE_TAG:
            return {
                ...state
            };

        case CREATE_TAG_SUCCESS:
            const tag_id = action.payload.data;
            return {
                ...state,
                tag_id: tag_id
            };

        case CREATE_TAG_ERROR:
            return {
                ...state
            };

        case SAVE_OBJ_TAG_IN_CONVERSATION:
            const saveObjTagInConversation = [ ...state.listObjTagInConversation , action.payload.objTag];
            return {
                ...state,
                listObjTagInConversation: saveObjTagInConversation
            };

        case SAVE_OBJ_TAG_IN_CONVERSATION_ITEM:
            const saveObjTagInConversationItem = [ ...state.listObjTagInConversationItem , action.payload.objTag];
            return {
                ...state,
                listObjTagInConversationItem: saveObjTagInConversationItem
            };

        case LIST_OBJ_TAG_IN_CONVERSATION:
            const listObjTagInConversation = action.payload.objTag;
            return {
                ...state,
                listObjTagInConversation: listObjTagInConversation
            };

        case LIST_OBJ_TAG_IN_CONVERSATION_ITEM:
            const listObjTagInConversationItem = action.payload.objTag;
            return {
                ...state,
                listObjTagInConversationItem: listObjTagInConversationItem
            };

        case GET_LIST_TAG_IN_CONVERSATION_SUCCESS:
            const data_list_tag_in_conversation = action.payload.data;
            return {
                ...state,
                listTagInConversation: data_list_tag_in_conversation
            };

        case GET_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS:
            const data_list_tag_in_conversation_item = action.payload.data;
            return {
                ...state,
                listTagInConversationItem: data_list_tag_in_conversation_item
            };

        case SAVE_LIST_TAG_IN_CONVERSATION_SUCCESS:
            const saveListTagInConversation = [ ...state.listTagInConversation , action.payload.objItemTag];
            return {
                ...state,
                listTagInConversation: saveListTagInConversation
            };

        case SAVE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS:
            const saveListTagInConversationItem = [ ...state.listTagInConversationItem , action.payload.objItemTag];
            return {
                ...state,
                listTagInConversationItem: saveListTagInConversationItem
            };

        case REMOVE_TAG_IN_CONVERSATION_SUCCESS:
            const data_update = action.payload.data_update;
            return {
                ...state,
                listTagInConversation: data_update
            };

        case REMOVE_TAG_SUCCESS:
            const listTagsUpdate = action.payload.listTagsUpdate;
            return {
                ...state,
                listTags: listTagsUpdate
            };

        case REMOVE_TAG_SIDEBAR_SUCCESS:
            return {
                ...state,
                listTagsSidebar: action.payload.listTagsUpdate
            };

        case UPDATE_TAG_SIDEBAR_SUCCESS:
            return {
                ...state,
                listTagsSidebar: action.payload.listTagsUpdate
            };

        case UPDATE_LIST_TAGS:
            return {
                ...state,
                listTags: action.payload.listTagsUpdate
            };

        case UPDATE_LIST_TAGS_ITEM:
            return {
                ...state,
                listTagsItem: action.payload.listTagsItemUpdate
            };

        case UPDATE_OBJ_TAG_IN_CONVERSATION:
            return {
                ...state,
                listObjTagInConversation: []
            };

        case UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM:
            return {
                ...state,
                listObjTagInConversationItem: []
            };

        case UPDATE_OBJ_TAG_IN_CONVERSATION_SUCCESS:
            return {
                ...state,
                listObjTagInConversation: action.payload.objTag
            };

        case UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM_SUCCESS:
            return {
                ...state,
                listObjTagInConversationItem: action.payload.objTag
            };

        case UPDATE_LIST_TAG_IN_CONVERSATION:
            return {
                ...state,
                listTagInConversation: []
            };

        case UPDATE_LIST_TAG_IN_CONVERSATION_ITEM:
            return {
                ...state,
                listTagInConversationItem: []
            };

        case UPDATE_LIST_TAG_IN_CONVERSATION_SUCCESS:
            return {
                ...state,
                listTagInConversation: action.payload.objItemTag
            };

        case UPDATE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS:
            return {
                ...state,
                listTagInConversationItem: action.payload.objItemTag
            };

        case CHECK_TAG_UPDATE_IN_CONVERSATION_SUCCESS:
            return {
                ...state,
                listTags : action.payload.listTagsUpdate,
                listObjTagInConversation : [ ...state.listObjTagInConversation , action.payload.listObjTagInConversationUpdate],
                listTagInConversation: [ ...state.listTagInConversation , action.payload.listTagInConversationUpdate],
            };

        case CHECK_TAG_UPDATE_IN_CONVERSATION_ITEM:
            return {
                ...state,
                listTagsItem : action.payload.listTagsItemUpdate,
                listObjTagInConversationItem : [ ...state.listObjTagInConversationItem , action.payload.listObjTagInConversationItemUpdate],
                listTagInConversationItem: [ ...state.listTagInConversationItem , action.payload.listTagInConversationItemUpdate],
            };

        case UPDATE_LIST_TAGS_REMOVE:
            return {
                ...state,
                listTags: [ action.payload.listTagsUpdateRemove , ...state.listTags]
            };

        case UPDATE_LIST_TAGS_ITEM_REMOVE:
            return {
                ...state,
                listTagsItem: [ action.payload.listTagsUpdateItemRemove , ...state.listTagsItem]
            };

        case GET_PAGINATION_TAG_SUCCESS:
            return {
                ...state,
                listTagsSidebar: action.payload.listTagsSidebar,
            };

        case RESET_PAGE_PAGINATION_TAG:
            return {
                ...state,
                page: 1,
            };

        case UPDATE_LIST_TAGS_AFTER_DONE:
            return {
                ...state,
                listTags: action.payload.listTags,
            };

        case UPDATE_LIST_OBJ_TAG_IN_CONVERSATION_AFTER_DONE:
            return {
                ...state,
                listObjTagInConversation: [],
            };

        case UPDATE_LIST_TAG_IN_CONVERSATION_AFTER_DONE:
            return {
                ...state,
                listTagInConversation: [],
            };

        default:
            return {...state};
    }
}

export default Tag;
