import {
    ERROR_QUICK_CREATE_TAG_SIDEBAR,
} from './constants';

import {toast} from "react-toastify";

export const errorQuickCreateTagSidebar = (errorQuickCreateTag) => ({
    type: ERROR_QUICK_CREATE_TAG_SIDEBAR,
    payload: {
        errorQuickCreateTag : errorQuickCreateTag
    }
});



