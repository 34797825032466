import {
    CHANGE_ACTION_SHIFT,
    GET_LIST_SHIFT_SIDEBAR,
    GET_LIST_SHIFT_SIDEBAR_SUCCESS,
    GET_PAGINATION_SHIFT_SUCCESS,
    GET_STATUS_SHIFT,
    GET_WORKING_STATUS,
    REMOVE_SHIFT_SIDEBAR_SUCCESS, SAVE_END_DATE_FILTER, SAVE_FROM_DATE_FILTER,
    SAVE_LIST_DATE_FILTER,
    SAVE_LIST_DAY_FILTER, UPDATE_END_DATE_FILTER, UPDATE_FROM_DATE_FILTER,
    UPDATE_LIST_DATE_FILTER,
    UPDATE_LIST_DAY_FILTER,
    UPDATE_LIST_SHIFT, UPDATE_STATUS_SHIFT
} from './constants';

const INIT_STATE = {
    listShifts: [],
    shift_id: null,
    statusShift: false,
    page: 1,
    listDayFilter: [],
    listDateFilter: [],
    fromDateFilter: null,
    endDateFilter: null,
};

const Shift = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_SHIFT_SIDEBAR:
            return {
                ...state,
                listShifts: []
            };

        case GET_LIST_SHIFT_SIDEBAR_SUCCESS:
            return {
                ...state,
                listShifts: action.payload.data,
            };

        case GET_PAGINATION_SHIFT_SUCCESS:
            return {
                ...state,
                listShifts: action.payload.listShifts,
            };

        case REMOVE_SHIFT_SIDEBAR_SUCCESS:
            return {
                ...state,
                listShifts: action.payload.listShifts
            };

        case GET_STATUS_SHIFT:
            return {
                ...state,
                statusShift: action.payload.statusShift
            };

        case CHANGE_ACTION_SHIFT:
            return {
                ...state,
                statusShift: action.payload.statusShift
            };

        case UPDATE_LIST_SHIFT:
            return {
                ...state,
                listShifts: action.payload.listShifts
            };

        case GET_WORKING_STATUS:
            return {
                ...state,
                statusShift: action.payload.statusShift
            };

        case SAVE_LIST_DATE_FILTER:
            return {
                ...state,
                listDateFilter: action.payload.listDateFilter
            };

        case SAVE_LIST_DAY_FILTER:
            return {
                ...state,
                listDayFilter: action.payload.listDayFilter
            };

        case UPDATE_LIST_DATE_FILTER:
            return {
                ...state,
                listDateFilter: action.payload.listDateFilter
            };

        case UPDATE_LIST_DAY_FILTER:
            return {
                ...state,
                listDayFilter: action.payload.listDayFilter
            };

        case SAVE_FROM_DATE_FILTER:
            return {
                ...state,
                fromDateFilter: action.payload.fromDateFilter
            };

        case UPDATE_FROM_DATE_FILTER:
            return {
                ...state,
                fromDateFilter: action.payload.fromDateFilter
            };

        case SAVE_END_DATE_FILTER:
            return {
                ...state,
                endDateFilter: action.payload.endDateFilter
            };

        case UPDATE_END_DATE_FILTER:
            return {
                ...state,
                endDateFilter: action.payload.endDateFilter
            };

        case UPDATE_STATUS_SHIFT:
            return {
                ...state,
                statusShift: action.payload.statusShift
            };

        default:
            return {...state};
    }
}

export default Shift;
