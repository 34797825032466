import {
    SET_FONT_SIZE_MESSAGE,
} from './constants';

const getFontSizeMessage = () => {
    let setting = localStorage.getItem("setting");
    let fontSizeSelected = null;
    if(setting){
        let setting_parse = setting ? (typeof (setting) == 'object' ? setting : JSON.parse(setting)) : null;
        fontSizeSelected = setting_parse?.setting?.message;
    }else{
        let setting_update = {
            setting : {
                message : 15
            }
        }
        localStorage.setItem("setting", JSON.stringify(setting_update));
        fontSizeSelected = 15;
    }
    return fontSizeSelected ;
}

const INIT_STATE = {
    message : {
        fontSize : getFontSizeMessage() || 15
    }
};

const Setting = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SET_FONT_SIZE_MESSAGE:
            return {
                ...state,
                message: action.payload.message
            };

        default:
            return {...state};
    }
}

export default Setting;
