import {
    GET_LIST_CONVERSATION,
    GET_LIST_CONVERSATION_SUCCESS,
    GET_LIST_CONVERSATION_ERROR,
    CREATE_CONVERSATION,
    CREATE_CONVERSATION_SUCCESS,
    CREATE_CONVERSATION_ERROR,
    SET_CONVERSATION_ITEM,
    SET_BEFORE_CONVERSATION_LOAD_MORE,
    UPDATE_STATUS_CONVERSATION,
    GET_LIST_ALL_CONVERSATION_IN_PAGE,
    GET_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS,
    GET_LIST_ALL_CONVERSATION_IN_PAGE_ERROR,
    SORT_RATING_ALL_CONVERSATION_IN_PAGE_SUCCESS,
    SORT_RATING_ALL_CONVERSATION_IN_PAGE,
    UPDATE_LIST_ALL_CONVERSATION_IN_PAGE,
    UPDATE_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS, UPDATE_PAGE_LOAD_MORE_SORT_CONVERSATION,
} from './constants';

const INIT_STATE = {
    loadingCreateConversation : false,
    conversation_id : null,
    loadMore : false,
    listConversationsInPage : [],
    sort : 'all',
    page_sort_pagination : 1,
};

const Conversation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CONVERSATION:
            return {
                ...state,
                listConversations: []
            };
        case GET_LIST_CONVERSATION_SUCCESS:
            const { data } = action.payload;
            return {
                ...state,
                listConversations: data
            };
        case GET_LIST_CONVERSATION_ERROR:
            return {
                ...state,
                listConversations: []
            };

        case GET_LIST_ALL_CONVERSATION_IN_PAGE:
            return {
                ...state,
                listConversationsInPage: []
            };
        case GET_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS:
            return {
                ...state,
                listConversationsInPage: action.payload.data
            };
        case GET_LIST_ALL_CONVERSATION_IN_PAGE_ERROR:
            return {
                ...state,
                listConversationsInPage: []
            };

        case CREATE_CONVERSATION:
            return {
                ...state,
                loadingCreateConversation: false
            };
        case CREATE_CONVERSATION_SUCCESS:
            return {
                ...state,
                loadingCreateConversation: true
            };
        case CREATE_CONVERSATION_ERROR:
            return {
                ...state,
                loadingCreateConversation: false
            };
        case SET_CONVERSATION_ITEM:
            return {
                ...state,
                conversation_id: action.payload.conversation_id
            };

        case SET_BEFORE_CONVERSATION_LOAD_MORE:
            return {
                ...state,
                loadMore: action.payload.loadMore
            };

        case UPDATE_STATUS_CONVERSATION:
            return {
                ...state,
                listConversations: action.payload.data
            };

        case SORT_RATING_ALL_CONVERSATION_IN_PAGE:
            return {
                ...state,
                sort: 'all'
            };

        case SORT_RATING_ALL_CONVERSATION_IN_PAGE_SUCCESS:
            return {
                ...state,
                sort: action.payload.data
            };

        case UPDATE_LIST_ALL_CONVERSATION_IN_PAGE:
            return {
                ...state,
                listConversationsInPage: []
            };

        case UPDATE_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS:
            return {
                ...state,
                listConversationsInPage: action.payload.data
            };

        case UPDATE_PAGE_LOAD_MORE_SORT_CONVERSATION:
            return {
                ...state,
                page_sort_pagination: action.payload.data
            };

        default: return { ...state };
    }
}

export default Conversation;
