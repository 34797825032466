export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";

export const GET_LIST_CHAT = "GET_LIST_CHAT";
export const GET_LIST_CHAT_SUCCESS = "GET_LIST_CHAT_SUCCESS";
export const GET_LIST_CHAT_ERROR = "GET_LIST_CHAT_ERROR";
export const ACTIVE_CHAT = "ACTIVE_CHAT";
export const ACTIVE_FB_USER_ID = "ACTIVE_FB_USER_ID";
export const ACTIVE_INFO = "ACTIVE_INFO";

export const UPDATE_LAST_MESSAGE_IN_CHAT = "UPDATE_LAST_MESSAGE_IN_CHAT";

export const UPDATE_LIST_CHAT_WHEN_NEW_MESSAGE = "UPDATE_LIST_CHAT_WHEN_NEW_MESSAGE";

export const UPDATE_LIST_CHAT = "UPDATE_LIST_CHAT";
export const CHECK_STATUS_NAME_NEW_MESSAGE = "CHECK_STATUS_NAME_NEW_MESSAGE";
export const SET_STATUS_LIST_CHAT = "SET_STATUS_LIST_CHAT";

export const DONE_CONVERSATION = "DONE_CONVERSATION";
export const SET_OPTION_FILTER = "SET_OPTION_FILTER";

export const FILTER_SEARCH_CHAT = "FILTER_SEARCH_CHAT";
export const CLEAR_FILTER_SEARCH_CHAT = "CLEAR_FILTER_SEARCH_CHAT";

export const ASSIGN_CHAT_SUCCESS = "ASSIGN_CHAT_SUCCESS";

export const MARK_READ_CHAT_SUCCESS = "MARK_READ_CHAT_SUCCESS";

export const UPDATE_PAGE_LOAD_MORE_CHAT = "UPDATE_PAGE_LOAD_MORE_CHAT";

export const UPDATE_CHAT_AFTER_DONE_CONVERSATION = "UPDATE_CHAT_AFTER_DONE_CONVERSATION";

export const UPDATE_CHAT_AFTER_DONE_MULTI_CONVERSATION = "UPDATE_CHAT_AFTER_DONE_MULTI_CONVERSATION";

export const ADD_CHECKED_TO_CHAT = "ADD_CHECKED_TO_CHAT";
export const ADD_ALL_CHECKED_TO_CHAT = "ADD_ALL_CHECKED_TO_CHAT";
export const REMOVE_ALL_CHECKED_TO_CHAT = "REMOVE_ALL_CHECKED_TO_CHAT";
export const UPDATE_CHECKED_TO_CHAT = "UPDATE_CHECKED_TO_CHAT";
export const REMOVE_CHECKED_TO_CHAT = "REMOVE_CHECKED_TO_CHAT";
export const RESET_CHECKED_TO_CHAT = "RESET_CHECKED_TO_CHAT";

export const UPDATE_LIST_CHATS_AFTER_ASSIGN = "UPDATE_LIST_CHATS_AFTER_ASSIGN";

export const SAVE_NAME_FILTER_CHAT = "SAVE_NAME_FILTER_CHAT";

export const CHANGE_HEIGHT_INPUT_CHAT = "CHANGE_HEIGHT_INPUT_CHAT";
export const CHANGE_HEIGHT_INPUT_CHAT_MOBILE = "CHANGE_HEIGHT_INPUT_CHAT_MOBILE";

export const ACTIVE_CHAT_BY_ID = "ACTIVE_CHAT_BY_ID";
