export const GET_LIST_CONVERSATION = "GET_LIST_CONVERSATION";
export const GET_LIST_CONVERSATION_SUCCESS = "GET_LIST_CONVERSATION_SUCCESS";
export const GET_LIST_CONVERSATION_ERROR = "GET_LIST_CONVERSATION_ERROR";

export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_ERROR = "CREATE_CONVERSATION_ERROR";

export const SET_CONVERSATION_ITEM = "SET_CONVERSATION_ITEM";
export const SET_BEFORE_CONVERSATION_LOAD_MORE = "SET_BEFORE_CONVERSATION_LOAD_MORE";

export const UPDATE_STATUS_CONVERSATION = "UPDATE_STATUS_CONVERSATION";

export const GET_LIST_ALL_CONVERSATION_IN_PAGE = "GET_LIST_ALL_CONVERSATION_IN_PAGE";
export const GET_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS = "GET_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS";
export const GET_LIST_ALL_CONVERSATION_IN_PAGE_ERROR = "GET_LIST_ALL_CONVERSATION_IN_PAGE_ERROR";

export const SORT_RATING_ALL_CONVERSATION_IN_PAGE = "SORT_RATING_ALL_CONVERSATION_IN_PAGE";
export const SORT_RATING_ALL_CONVERSATION_IN_PAGE_SUCCESS = "SORT_RATING_ALL_CONVERSATION_IN_PAGE_SUCCESS";
export const SORT_RATING_ALL_CONVERSATION_IN_PAGE_ERROR = "SORT_RATING_ALL_CONVERSATION_IN_PAGE_ERROR";

export const UPDATE_LIST_ALL_CONVERSATION_IN_PAGE = "UPDATE_LIST_ALL_CONVERSATION_IN_PAGE";
export const UPDATE_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS = "UPDATE_LIST_ALL_CONVERSATION_IN_PAGE_SUCCESS";
export const UPDATE_LIST_ALL_CONVERSATION_IN_PAGE_ERROR = "UPDATE_LIST_ALL_CONVERSATION_IN_PAGE_ERROR";


export const UPDATE_PAGE_LOAD_MORE_SORT_CONVERSATION = "UPDATE_PAGE_LOAD_MORE_SORT_CONVERSATION";


