import axiosClient from "../helpers/axiosClient";
import {REACT_APP_CHAT, API_PAGES, ROLES, ROLES_ASSIGN} from "./constants";
import {combineUrlParams} from "./../helpers/index";

export const getListRole = (page_id , limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${ROLES}`,
            {
                limit : limit,
            }
        )
    );
}

export const assignRole = (page_id, data) => {
    return axiosClient.post(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${ROLES_ASSIGN}` , data);
}

export const removeUserToRole = (page_id, data) => {
    return axiosClient.post(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${ROLES_ASSIGN}` , data);
}

