export const GET_LIST_PAGE = "GET_LIST_PAGE";
export const GET_LIST_PAGE_SUCCESS = "GET_LIST_PAGE_SUCCESS";
export const GET_LIST_PAGE_ERROR = "GET_LIST_PAGE_ERROR";
export const ACTIVE_PAGES = "ACTIVE_PAGES";
export const ACTIVE_NAME_PAGES = "ACTIVE_NAME_PAGES";
export const ACTIVE_PAGES_IN_ROLES = "ACTIVE_PAGES_IN_ROLES";
export const GET_LIST_PAGE_IN_ROLE_SUCCESS = "GET_LIST_PAGE_IN_ROLE_SUCCESS";
export const CHOOSE_PAGE_IN_ROLES = "CHOOSE_PAGE_IN_ROLES";
export const ACTIVE_PAGES_IN_TAGS = "ACTIVE_PAGES_IN_TAGS";
export const CHOOSE_PAGE_IN_TAGS = "CHOOSE_PAGE_IN_TAGS";
export const UPDATE_NOT_REPLY = "UPDATE_NOT_REPLY";
export const CHOOSE_PAGE_IN_SHIFTS = "CHOOSE_PAGE_IN_SHIFTS";
export const ACTIVE_PAGES_IN_SHIFTS = "ACTIVE_PAGES_IN_SHIFTS";
export const TOTAL_NOT_REPLIED = "TOTAL_NOT_REPLIED";
export const TOTAL_REPLIED = "TOTAL_REPLIED";

export const ACTIVE_PAGE_BY_ID = "ACTIVE_PAGE_BY_ID";

export const SHOW_ARCHIVED = "SHOW_ARCHIVED";
export const HIDE_ARCHIVED = "HIDE_ARCHIVED";
