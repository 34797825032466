import axiosClient from "../helpers/axiosClient";
import {TAGS, REACT_APP_CHAT, API_PAGES} from "./constants";
import {combineUrlParams} from "./../helpers/index";

export const getListTag = (page_id , page = 1 , limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${TAGS}`,
            {
                page : page,
                limit : limit,
            }
        )
    );
}

export const getListTagSearch = (name , page_id , limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${TAGS}`,
            {
                limit : limit,
                name : name,
            }
        )
    );
}

export const createTag = (page_id, name , is_bug = false) => {
    let data = {};
    data.name = name;
    data.is_bug = is_bug;
    return axiosClient.post(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${TAGS}` , data);
}

export const removeTag = (page_id , tag_id) => {
    return axiosClient.delete(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${TAGS}/${tag_id}`);
}

export const editTag = (page_id , tag_id , name , is_bug = false) => {
    let data = {};
    data.name = name;
    data.is_bug = is_bug;
    return axiosClient.put(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${TAGS}/${tag_id}` , data);
}
