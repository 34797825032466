import axiosClient from "../helpers/axiosClient";
import {
    REACT_APP_CHAT,
    API_PAGES,
    REPORTS,
    API_REPORTS_PAGES,
    REPORT_TAGS,
    API_REPORTS_PAGES_MANAGER
} from "./constants";
import {combineUrlParams} from "./../helpers/index";

export const getReportDailyHavePageId = ( from , to , page_id , user_id , limit = 50 , period = 'DAILY' ) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${REPORTS}`,
            {
                period : period,
                from : from,
                to : to,
                user_id : user_id,
                limit : limit,
            }
        )
    );
}

export const getReportDailyGeneral = ( from , to , limit = 50 , period = 'DAILY' ) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_REPORTS_PAGES}`,
            {
                period : period,
                from : from,
                to : to,
                limit : limit,
            }
        )
    );
}

export const getReportTagBug = ( from , to , page_id , is_bug = 1 ) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${REPORT_TAGS}`,
            {
                from : from,
                to : to,
                is_bug : is_bug,
            }
        )
    );
}

export const getManagerAggregateReports = ( data ) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_REPORTS_PAGES_MANAGER}`,
            {
                period : data.period,
                from : data.from,
                to : data.to,
                user_id : data.user_id,
            }
        )
    );
}
