export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const OPEN_CONVERSATION_SIDEBAR = 'OPEN_CONVERSATION_SIDEBAR';
export const CLOSE_CONVERSATION_SIDEBAR = 'CLOSE_CONVERSATION_SIDEBAR';

export const OPEN_LIST_TAG_SIDEBAR = 'OPEN_LIST_TAG_SIDEBAR';
export const CLOSE_LIST_TAG_SIDEBAR = 'CLOSE_LIST_TAG_SIDEBAR';

export const ACTIVE_USER_CHAT = 'ACTIVE_USER_CHAT';
export const CLOSE_USER_CHAT = 'CLOSE_USER_CHAT';

export const ACTIVE_LIST_SHIFT = 'ACTIVE_LIST_SHIFT';
export const CLOSE_LIST_SHIFT = 'CLOSE_LIST_SHIFT';

export const ACTIVE_REPORT = 'ACTIVE_REPORT';
export const CLOSE_REPORT = 'CLOSE_REPORT';

export const ACTIVE_SEARCH_IN_ALL_CONVERSATION = 'ACTIVE_SEARCH_IN_ALL_CONVERSATION';
export const CLOSE_SEARCH_IN_ALL_CONVERSATION = 'CLOSE_SEARCH_IN_ALL_CONVERSATION';
