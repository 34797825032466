export const GET_LIST_QUICK_REPLY = "GET_LIST_QUICK_REPLY";
export const GET_LIST_QUICK_REPLY_SUCCESS = "GET_LIST_QUICK_REPLY_SUCCESS";

export const REMOVE_QUICK_REPLY = "REMOVE_QUICK_REPLY";
export const UPDATE_QUICK_REPLY = "UPDATE_QUICK_REPLY";
export const CREATE_QUICK_REPLY = "CREATE_QUICK_REPLY";

export const SAVE_SUGGEST_REPLY = "SAVE_SUGGEST_REPLY";

export const GET_MORE_QUICK_REPLY = "GET_MORE_QUICK_REPLY";
export const SET_PAGE_PAGINATION_QUICK_REPLY = "SET_PAGE_PAGINATION_QUICK_REPLY";

export const RESET_PAGE_PAGINATION_QUICK_REPLY = "RESET_PAGE_PAGINATION_QUICK_REPLY";
