import axios from "axios";
import {toast} from "react-toastify";

const axiosFromDataAuthClient = axios.create({
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    },
});

axiosFromDataAuthClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    switch(error.response.status) {
        case 400:
            toast.error(error.response.data.message);
            break;
        case 401:
            toast.error(error.response.data.message);
            break;
        case 404:
            toast.error(error.response.data.message);
            break;
        case 500:
            toast.error(error.response.data.message);
            break;
        default: toast.error(error.response.data.message);
    }
    return Promise.reject(error);
});

axiosFromDataAuthClient.interceptors.request.use((config) => {
    try {
        config.headers = {
            ...config.headers
        };
        return config;
    } catch (error) {
        return Promise.reject(error);
    }
});

export {axiosFromDataAuthClient};
