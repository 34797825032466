import axiosClient from "../helpers/axiosClient";
import {API_ARCHIVES, API_PAGES, REACT_APP_CHAT} from "./constants";
import {combineUrlParams} from "./../helpers/index";

// limit = 50
export const getListPage = (name = null , with_archived = 0 , limit = 100) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}`,
            {
                limit : limit,
                name : name,
                with_archived : with_archived,
            }
        )
    );
}

export const getListPageArchived = (name = null , limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_ARCHIVES}`,
            {
                limit : limit,
                name : name,
            }
        )
    );
}

export const addArchivedPage = (data) => {
    return axiosClient.post(`${REACT_APP_CHAT}/${API_ARCHIVES}` , data);
}

export const removeArchivedPage = (archived_page_id) => {
    return axiosClient.delete(`${REACT_APP_CHAT}/${API_ARCHIVES}/${archived_page_id}`);
}


