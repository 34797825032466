import {
    CHECK_TAG_UPDATE_IN_CONVERSATION_ITEM,
    CHECK_TAG_UPDATE_IN_CONVERSATION_SUCCESS,
    CREATE_TAG,
    CREATE_TAG_ERROR,
    CREATE_TAG_SUCCESS,
    GET_FULL_LIST_TAG_SUCCESS,
    GET_LIST_TAG,
    GET_LIST_TAG_ERROR,
    GET_LIST_TAG_IN_CONVERSATION,
    GET_LIST_TAG_IN_CONVERSATION_ITEM,
    GET_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    GET_LIST_TAG_IN_CONVERSATION_SUCCESS,
    GET_LIST_TAG_SIDEBAR,
    GET_LIST_TAG_SIDEBAR_SUCCESS,
    GET_LIST_TAG_SUCCESS,
    GET_PAGINATION_TAG_SUCCESS,
    LIST_OBJ_TAG_IN_CONVERSATION,
    LIST_OBJ_TAG_IN_CONVERSATION_ITEM,
    REMOVE_TAG_IN_CONVERSATION_SUCCESS,
    REMOVE_TAG_SIDEBAR_SUCCESS,
    REMOVE_TAG_SUCCESS,
    RESET_PAGE_PAGINATION_TAG,
    SAVE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    SAVE_LIST_TAG_IN_CONVERSATION_SUCCESS,
    SAVE_OBJ_TAG_IN_CONVERSATION,
    SAVE_OBJ_TAG_IN_CONVERSATION_ITEM,
    UPDATE_LIST_OBJ_TAG_IN_CONVERSATION_AFTER_DONE,
    UPDATE_LIST_TAG_IN_CONVERSATION,
    UPDATE_LIST_TAG_IN_CONVERSATION_AFTER_DONE,
    UPDATE_LIST_TAG_IN_CONVERSATION_ITEM,
    UPDATE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    UPDATE_LIST_TAG_IN_CONVERSATION_SUCCESS,
    UPDATE_LIST_TAGS,
    UPDATE_LIST_TAGS_AFTER_DONE,
    UPDATE_LIST_TAGS_ITEM,
    UPDATE_LIST_TAGS_ITEM_REMOVE,
    UPDATE_LIST_TAGS_REMOVE,
    UPDATE_OBJ_TAG_IN_CONVERSATION,
    UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM,
    UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    UPDATE_OBJ_TAG_IN_CONVERSATION_SUCCESS,
    UPDATE_TAG_SIDEBAR_SUCCESS
} from './constants';

import * as tagApis from "./../../apis/tag";
import * as conversationApis from "../../apis/conversations";
import {toast} from "react-toastify";
import {errorQuickCreateTagSidebar} from "../error/actions";

export const getListTag = () => ({
    type: GET_LIST_TAG
});

export const getListTagSidebar = () => ({
    type: GET_LIST_TAG_SIDEBAR
});

export const getListTagSuccess = (data) => ({
    type: GET_LIST_TAG_SUCCESS,
    payload: {
        data
    }
});

export const getFullListTagSuccess = (data) => ({
    type: GET_FULL_LIST_TAG_SUCCESS,
    payload: {
        data
    }
});

export const getListTagSidebarSuccess = (data) => ({
    type: GET_LIST_TAG_SIDEBAR_SUCCESS,
    payload: {
        data
    }
});

export const getListTagError = (error) => ({
    type: GET_LIST_TAG_ERROR,
    payload: {
        error
    }
});

export const createTag = () => ({
    type: CREATE_TAG
});

export const createTagSuccess = (data) => ({
    type: CREATE_TAG_SUCCESS,
    payload: {
        data
    }
});

export const createTagError = (error) => ({
    type: CREATE_TAG_ERROR,
    payload: {
        error
    }
});

export const getListTagInConversation = () => ({
    type: GET_LIST_TAG_IN_CONVERSATION
});

export const getListTagInConversationItem = () => ({
    type: GET_LIST_TAG_IN_CONVERSATION_ITEM
});

export const getListTagInConversationSuccess = (data) => ({
    type: GET_LIST_TAG_IN_CONVERSATION_SUCCESS,
    payload: {
        data
    }
});

export const getListTagInConversationItemSuccess = (data) => ({
    type: GET_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    payload: {
        data
    }
});

export const removeTagInConversationSuccess = (data_update) => ({
    type: REMOVE_TAG_IN_CONVERSATION_SUCCESS,
    payload: {
        data_update
    }
});

export const removeTagSuccess = (listTagsUpdate) => ({
    type: REMOVE_TAG_SUCCESS,
    payload: {
        listTagsUpdate
    }
});

export const removeTagSidebarSuccess = (listTagsUpdate) => ({
    type: REMOVE_TAG_SIDEBAR_SUCCESS,
    payload: {
        listTagsUpdate
    }
});

export const editTagSidebarSuccess = (listTagsUpdate) => ({
    type: UPDATE_TAG_SIDEBAR_SUCCESS,
    payload: {
        listTagsUpdate
    }
});

export const updateListTagsSuccess = (listTagsUpdate) => ({
    type: UPDATE_LIST_TAGS,
    payload: {
        listTagsUpdate
    }
});

export const updateListTagsItemSuccess = (listTagsItemUpdate) => ({
    type: UPDATE_LIST_TAGS_ITEM,
    payload: {
        listTagsItemUpdate
    }
});

export const listObjTagInConversationSuccess = (objTag) => ({
    type: LIST_OBJ_TAG_IN_CONVERSATION,
    payload: {
        objTag
    }
});

export const listObjTagInConversationItemSuccess = (objTag) => ({
    type: LIST_OBJ_TAG_IN_CONVERSATION_ITEM,
    payload: {
        objTag
    }
});

export const saveObjTagInConversationSuccess = (objTag) => ({
    type: SAVE_OBJ_TAG_IN_CONVERSATION,
    payload: {
        objTag
    }
});

export const saveObjTagInConversationItemSuccess = (objTag) => ({
    type: SAVE_OBJ_TAG_IN_CONVERSATION_ITEM,
    payload: {
        objTag
    }
});

export const saveListTagInConversationSuccess = (objItemTag) => ({
    type: SAVE_LIST_TAG_IN_CONVERSATION_SUCCESS,
    payload: {
        objItemTag
    }
});

export const saveListTagInConversationItemSuccess = (objItemTag) => ({
    type: SAVE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    payload: {
        objItemTag
    }
});

export const updateListTagInConversationSuccess = (objItemTag) => ({
    type: UPDATE_LIST_TAG_IN_CONVERSATION_SUCCESS,
    payload: {
        objItemTag
    }
});

export const updateListTagInConversationItemSuccess = (objItemTag) => ({
    type: UPDATE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    payload: {
        objItemTag
    }
});

export const updateObjTagInConversationSuccess = (objTag) => ({
    type: UPDATE_OBJ_TAG_IN_CONVERSATION_SUCCESS,
    payload: {
        objTag
    }
});

export const updateObjTagInConversationItemSuccess = (objTag) => ({
    type: UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM_SUCCESS,
    payload: {
        objTag
    }
});

export const updateObjTagInConversation = () => ({
    type: UPDATE_OBJ_TAG_IN_CONVERSATION,
    payload: {}
});

export const updateListTagInConversation = () => ({
    type: UPDATE_LIST_TAG_IN_CONVERSATION,
    payload: {}
});

export const updateObjTagInConversationItem = () => ({
    type: UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM,
    payload: {}
});

export const updateListTagInConversationItem = () => ({
    type: UPDATE_LIST_TAG_IN_CONVERSATION_ITEM,
    payload: {}
});

export const checkTagUpdateInConversationSuccess = (listTagsUpdate, listObjTagInConversationUpdate, listTagInConversationUpdate) => ({
    type: CHECK_TAG_UPDATE_IN_CONVERSATION_SUCCESS,
    payload: {
        listTagsUpdate,
        listObjTagInConversationUpdate,
        listTagInConversationUpdate,
    }
});

export const checkTagUpdateInConversationItemSuccess = (listTagsItemUpdate, listObjTagInConversationItemUpdate, listTagInConversationItemUpdate) => ({
    type: CHECK_TAG_UPDATE_IN_CONVERSATION_ITEM,
    payload: {
        listTagsItemUpdate,
        listObjTagInConversationItemUpdate,
        listTagInConversationItemUpdate,
    }
});

export const updateListTagsRemoveSuccess = (listTagsUpdateRemove) => ({
    type: UPDATE_LIST_TAGS_REMOVE,
    payload: {
        listTagsUpdateRemove
    }
});

export const updateListTagsRemoveItemSuccess = (listTagsUpdateItemRemove) => ({
    type: UPDATE_LIST_TAGS_ITEM_REMOVE,
    payload: {
        listTagsUpdateItemRemove
    }
});

export const getListMoreSuccess = (listTagsSidebar) => ({
    type: GET_PAGINATION_TAG_SUCCESS,
    payload: {
        listTagsSidebar: listTagsSidebar,
    }
});

export const resetPagePaginationTag = () => ({
    type: RESET_PAGE_PAGINATION_TAG,
    payload: {}
});

export const updateListObjTagInConversationAfterDone = () => ({
    type: UPDATE_LIST_OBJ_TAG_IN_CONVERSATION_AFTER_DONE,
    payload: {}
});

export const updateListTagInConversationAfterDone = () => ({
    type: UPDATE_LIST_TAG_IN_CONVERSATION_AFTER_DONE,
    payload: {}
});

export const updateListTagsAfterDone = (listTags) => ({
    type: UPDATE_LIST_TAGS_AFTER_DONE,
    payload: {
        listTags: listTags
    }
});

export const getListTagRequest = (page_id) => {
    return dispatch => {
        dispatch(getListTag());
        tagApis.getListTag(page_id)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListTagSuccess(data))
            }).catch(error => {
            dispatch(getListTagError(error))
        });
    }
}

export const getFullListTagRequest = (page_id) => {
    return dispatch => {
        tagApis.getListTag(page_id)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getFullListTagSuccess(data))
            }).catch(error => {

        });
    }
}

export const createTagSidebarRequest = (name, is_bug) => {
    return (dispatch, getState) => {
        const page_id = getState().Page.page_id;
        dispatch(createTag());
        tagApis.createTag(page_id, name, is_bug)
            .then(resp => {
                tagApis.getListTag(page_id)
                    .then(resp => {
                        const {data} = resp.data.data;
                        toast.success("Create tag success!");
                        dispatch(getListTagSidebarSuccess(data))
                    }).catch(error => {
                    dispatch(getListTagError(error))
                });
            }).catch(error => {
            dispatch(createTagError(error))
        });
    }
}

export const createTagRequest = (name) => {
    return (dispatch, getState) => {
        dispatch(createTag());
        const page_id = getState().Page.page_id;
        tagApis.createTag(page_id, name)
            .then(resp => {
                const data = resp.data.data.id;
                dispatch(createTagSuccess(data));
                dispatch(saveObjTagInConversationSuccess(resp.data.data));
                const tag_id = getState().Tag.tag_id;
                const chat_id = getState().Chat.chat_id;
                const itemTag = {
                    id: tag_id.toString(),
                    text: name
                }
                dispatch(saveListTagInConversationSuccess(itemTag));
                conversationApis.addTagToConversations(chat_id, tag_id)
                    .then(resp => {

                    }).catch(error => {

                })
            }).catch(error => {
            dispatch(errorQuickCreateTagSidebar(true));
            return error;
        });
    }
}

export const getListTagInConversationRequest = (chat_id) => {
    return (dispatch, getState) => {
        dispatch(getListTagInConversation());
        let listConversations = getState().Conversation.listConversations;
        const tags = [];
        const tagsObj = [];
        listConversations && listConversations[0]?.tags && listConversations[0]?.tags.map(tag => {
            tags.push({
                id: tag.id && tag.id.toString(),
                text: tag.name.en && tag.name.en
            });
            tagsObj.push(tag);
        });
        dispatch(listObjTagInConversationSuccess(tagsObj));
        dispatch(getListTagInConversationSuccess(tags));


        // conversationApis.getListConversations(chat_id)
        //     .then(resp => {
        //         const listConversations = resp.data.data.data;
        //         const tags = [];
        //         const tagsObj = [];
        //         // listConversations && listConversations.map(conversation => {
        //         listConversations && listConversations[0].tags && listConversations[0].tags.map(tag => {
        //             tags.push({
        //                 id: tag.id && tag.id.toString(),
        //                 text: tag.name.en && tag.name.en
        //             });
        //             tagsObj.push(tag);
        //         });
        //         // });
        //         dispatch(listObjTagInConversationSuccess(tagsObj));
        //         dispatch(getListTagInConversationSuccess(tags));
        //     }).catch(error => {
        //
        // });
    }
}

export const getListTagInConversationItemRequest = (chat_id) => {
    return (dispatch, getState) => {
        const conversation_id = getState().Conversation.conversation_id;
        dispatch(getListTagInConversationItem());
        conversationApis.getListConversations(chat_id)
            .then(resp => {
                const listConversationsItem = resp.data.data.data;
                const tags = [];
                const tagsObj = [];
                listConversationsItem && listConversationsItem.map(conversation => {
                    if (conversation_id === conversation.id) {
                        conversation.tags && conversation.tags.map(tag => {
                            tags.push({
                                id: tag.id && tag.id.toString(),
                                text: tag.name.en && tag.name.en
                            });
                            tagsObj.push(tag);
                        });
                    }
                });
                dispatch(listObjTagInConversationItemSuccess(tagsObj));
                dispatch(getListTagInConversationItemSuccess(tags));
            }).catch(error => {

        });
    }
}

export const removeTagInConversationRequest = (chat_id, tag_id, data_update) => {
    return (dispatch, getState) => {
        const listObjTagInConversation = getState().Tag.listObjTagInConversation;
        const listTagsUpdate = listObjTagInConversation && listObjTagInConversation.filter(o => o.id === parseInt(tag_id));
        conversationApis.removeTagToConversations(chat_id, tag_id)
            .then(resp => {
                const dataUpdateTagInConversation = resp.data.data.tags;
                //fix load
                // dispatch(updateListTagInConversation());
                // dispatch(updateObjTagInConversation());
                let listTagsInConversationUpdate = data_update && data_update.filter(item => item.id !== tag_id);
                dispatch(updateListTagsRemoveSuccess(listTagsUpdate && listTagsUpdate[0]));
                dispatch(updateListTagInConversationSuccess(listTagsInConversationUpdate));
                dispatch(updateObjTagInConversationSuccess(dataUpdateTagInConversation));
            }).catch(error => {

        });
    }
}

export const removeTagRequest = (page_id, tag_id) => {
    return (dispatch, getState) => {
        tagApis.removeTag(page_id, tag_id)
            .then(resp => {
                let listTags = [...getState().Tag.listTags];
                let listTagsUpdate = listTags && listTags.filter(item => item.id !== tag_id);
                dispatch(removeTagSuccess(listTagsUpdate));
                toast.success("Remove tag success!");
            }).catch(error => {

        });
    }
}

export const removeTagSidebarRequest = (page_id, tag_id) => {
    return (dispatch, getState) => {
        tagApis.removeTag(page_id, tag_id)
            .then(resp => {
                let listTagsSidebar = [...getState().Tag.listTagsSidebar];
                let listTagsUpdate = listTagsSidebar && listTagsSidebar.filter(item => item.id !== tag_id);
                dispatch(removeTagSidebarSuccess(listTagsUpdate));
                toast.success("Remove tag success!");
            }).catch(error => {

        });
    }
}

export const updateListTagsRequest = () => {
    return (dispatch, getState) => {
        var listTags = [...getState().Tag.listTags];
        var listTagsInConversation = [...getState().Tag.listTagInConversation];
        if (listTags && listTagsInConversation) {
            var idArr = [];
            listTagsInConversation && listTagsInConversation.map(tag => {
                idArr.push(parseInt(tag.id));
            });
            var listTagsUpdate = listTags && listTags.filter(item => !idArr.includes(item.id));
            dispatch(updateListTagsSuccess(listTagsUpdate));
        }
    }
}

export const checkTagRequest = (tag_id) => {
    return (dispatch, getState) => {
        let chat_id = getState().Chat.chat_id;
        let listTags = getState().Tag.listTags;
        let listTagsUpdate = listTags && listTags.filter(item => item.id !== tag_id);
        let listObjTagInConversationUpdate = listTags.find(o => o.id === tag_id);
        let listTagInConversationUpdate = {
            id: listObjTagInConversationUpdate.id.toString(),
            text: listObjTagInConversationUpdate.name.en,
        };
        dispatch(checkTagUpdateInConversationSuccess(listTagsUpdate, listObjTagInConversationUpdate, listTagInConversationUpdate));
        conversationApis.addTagToConversations(chat_id, tag_id)
            .then(resp => {
                if (resp.data.code !== 0) {
                    dispatch(checkTagUpdateInConversationSuccess(listTags, getState().Chat.listObjTagInConversation, getState().Chat.listTagInConversation));
                }
                // let listTagsUpdate = listTags && listTags.filter(item => item.id !== tag_id);
                // let listObjTagInConversationUpdate = listTags.find(o => o.id === tag_id);
                // let listTagInConversationUpdate = {
                //     id : listObjTagInConversationUpdate.id.toString(),
                //     text : listObjTagInConversationUpdate.name.en,
                // };
                // dispatch(checkTagUpdateInConversationSuccess(listTagsUpdate , listObjTagInConversationUpdate , listTagInConversationUpdate));
            }).catch(error => {

        });
    }
}

export const checkTagItemRequest = (tag_id) => {
    return (dispatch, getState) => {
        var chat_id = getState().Chat.chat_id;
        var conversation_id = getState().Conversation.conversation_id;
        var listConversations = getState().Conversation.listConversations;
        var listTagsItem = getState().Tag.listTags;
        conversationApis.addTagToConversationsItem(chat_id, tag_id, conversation_id)
            .then(resp => {
                let listTagsItemUpdate = listTagsItem && listTagsItem.filter(item => item.id !== tag_id);
                let listObjTagInConversationItemUpdate = listTagsItem.find(o => o.id === tag_id);
                let listTagInConversationItemUpdate = {
                    id: listObjTagInConversationItemUpdate.id.toString(),
                    text: listObjTagInConversationItemUpdate.name.en,
                };
                dispatch(checkTagUpdateInConversationItemSuccess(listTagsItemUpdate, listObjTagInConversationItemUpdate, listTagInConversationItemUpdate));
                if (listConversations && listConversations[0].id === conversation_id) {
                    dispatch(checkTagUpdateInConversationSuccess(listTagsItemUpdate, listObjTagInConversationItemUpdate, listTagInConversationItemUpdate));
                }
            }).catch(error => {

        });
    }
}

export const updateListTagsItemRequest = () => {
    return (dispatch, getState) => {
        var listTagsItem = getState().Tag.listTagsItem;
        var listTagsInConversationItem = getState().Tag.listTagInConversationItem;
        if (listTagsItem && listTagsInConversationItem) {
            var idArr = [];
            listTagsInConversationItem && listTagsInConversationItem.map(tag => {
                idArr.push(parseInt(tag.id));
            });
            var listTagsItemUpdate = listTagsItem && listTagsItem.filter(item => !idArr.includes(item.id));
            dispatch(updateListTagsItemSuccess(listTagsItemUpdate));
        }
    }
}

export const createTagItemRequest = (name) => {
    return (dispatch, getState) => {
        dispatch(createTag());
        const page_id = getState().Page.page_id;
        const conversation_id = getState().Conversation.conversation_id;
        tagApis.createTag(page_id, name)
            .then(resp => {
                const data = resp.data.data.id;
                dispatch(createTagSuccess(data));
                dispatch(saveObjTagInConversationItemSuccess(resp.data.data));
                const tag_id = getState().Tag.tag_id;
                const chat_id = getState().Chat.chat_id;
                const itemTag = {
                    id: tag_id.toString(),
                    text: name
                }
                dispatch(saveListTagInConversationItemSuccess(itemTag));
                conversationApis.addTagToConversationsItem(chat_id, tag_id, conversation_id)
                    .then(resp => {

                    }).catch(error => {

                })
                toast.success("Create tag success!");
            }).catch(error => {
            dispatch(createTagError(error));
        });
    }
}

export const removeTagInConversationItemRequest = (chat_id, tag_id, data_update) => {
    return (dispatch, getState) => {
        const conversation_id = getState().Conversation.conversation_id;
        const listObjTagInConversationItem = getState().Tag.listObjTagInConversationItem;
        const listTagsItemUpdate = listObjTagInConversationItem && listObjTagInConversationItem.filter(o => o.id === parseInt(tag_id));
        var listConversations = getState().Conversation.listConversations;
        conversationApis.removeTagToConversationsItem(chat_id, tag_id, conversation_id)
            .then(resp => {
                const dataUpdateTagInConversationItem = resp.data.data.tags;
                //fix load
                dispatch(updateListTagInConversationItem());
                dispatch(updateObjTagInConversationItem());
                let listTagsInConversationItemUpdate = data_update && data_update.filter(item => item.id !== tag_id);
                dispatch(updateListTagsRemoveItemSuccess(listTagsItemUpdate && listTagsItemUpdate[0]));
                dispatch(updateListTagInConversationItemSuccess(listTagsInConversationItemUpdate));
                dispatch(updateObjTagInConversationItemSuccess(dataUpdateTagInConversationItem));
                //update tag if conversation last
                if (listConversations && listConversations[0].id === conversation_id) {
                    dispatch(updateListTagsRemoveSuccess(listTagsItemUpdate && listTagsItemUpdate[0]));
                    dispatch(updateListTagInConversationSuccess(listTagsInConversationItemUpdate));
                    dispatch(updateObjTagInConversationSuccess(dataUpdateTagInConversationItem));
                }
                toast.success("Remove tag success!");
            }).catch(error => {

        });
    }
}

export const editTagSidebarRequest = (page_id, tag_id, name, is_bug) => {
    return (dispatch, getState) => {
        tagApis.editTag(page_id, tag_id, name, is_bug)
            .then(resp => {
                tagApis.getListTag(page_id)
                    .then(resp => {
                        const {data} = resp.data.data;
                        toast.success("Edit tag success!");
                        dispatch(getListTagSidebarSuccess(data))
                    }).catch(error => {
                    dispatch(getListTagError(error))
                });
            }).catch(error => {
        });
    }
}

export const filterSearchTagRequest = (name) => {
    return (dispatch, getState) => {
        var page_id = getState().Page.page_id;
        tagApis.getListTagSearch(name, page_id)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListTagSidebarSuccess(data))
            }).catch(error => {

        });
    }
}

export const getMoreTagRequest = (page_id, page) => {
    return (dispatch, getState) => {
        var listTagsSidebar = getState().Tag.listTagsSidebar;
        dispatch(getListTag());
        tagApis.getListTag(page_id, page)
            .then(resp => {
                const {data} = resp.data.data;
                var listTagsSidebarUpdate = [...listTagsSidebar].concat(data);
                dispatch(getListMoreSuccess(listTagsSidebarUpdate));
            }).catch(error => {

        });
    }
}

export const filterSearchTagRightSideBarRequest = (name) => {
    return (dispatch, getState) => {
        var page_id = getState().Page.page_id;
        var listTagsInConversation = [...getState().Tag.listTagInConversation];
        tagApis.getListTagSearch(name, page_id)
            .then(resp => {
                const listTags = resp.data.data.data;
                if (listTags && listTagsInConversation) {
                    var idArr = [];
                    listTagsInConversation && listTagsInConversation.map(tag => {
                        idArr.push(parseInt(tag.id));
                    });
                    var listTagsUpdate = listTags && listTags.filter(item => !idArr.includes(item.id));
                    dispatch(updateListTagsSuccess(listTagsUpdate));
                }
            }).catch(error => {

        });
    }
}

export const filterSearchTagItemRightSideBarRequest = (name) => {
    return (dispatch, getState) => {
        var page_id = getState().Page.page_id;
        var listTagInConversationItem = [...getState().Tag.listTagInConversationItem];
        tagApis.getListTagSearch(name, page_id)
            .then(resp => {
                const listTagsItem = resp.data.data.data;
                if (listTagsItem && listTagInConversationItem) {
                    var idArr = [];
                    listTagInConversationItem && listTagInConversationItem.map(tag => {
                        idArr.push(parseInt(tag.id));
                    });
                    var listTagsItemUpdate = listTagsItem && listTagsItem.filter(item => !idArr.includes(item.id));
                    dispatch(updateListTagsItemSuccess(listTagsItemUpdate));
                }
            }).catch(error => {

        });
    }
}
