import {
    ACTIVE_NAME_PAGES,
    ACTIVE_PAGE_BY_ID,
    ACTIVE_PAGES,
    ACTIVE_PAGES_IN_ROLES,
    ACTIVE_PAGES_IN_SHIFTS,
    ACTIVE_PAGES_IN_TAGS,
    CHOOSE_PAGE_IN_ROLES,
    CHOOSE_PAGE_IN_SHIFTS,
    CHOOSE_PAGE_IN_TAGS,
    GET_LIST_PAGE,
    GET_LIST_PAGE_ERROR,
    GET_LIST_PAGE_SUCCESS, HIDE_ARCHIVED, SHOW_ARCHIVED,
    TOTAL_NOT_REPLIED,
    TOTAL_REPLIED,
    UPDATE_NOT_REPLY
} from './constants';

import * as pageApis from "./../../apis/page";
import * as userApis from "../../apis/user";
import {getListUserInPageRequest, getListUserInPageSuccess} from "../user/actions";
import * as roleApis from "../../apis/role";
import * as tagApis from "../../apis/tag";
import * as shiftApis from "../../apis/shift";
import {activeRoleDefault, getListRoleInPageSuccess} from "../role/actions";
import {getListTagError, getListTagSidebar, getListTagSidebarSuccess} from "../tag/actions";
import {getListShiftSidebar, getListShiftSidebarSuccess} from "../shift/actions";


export const getListPage = () => ({
    type: GET_LIST_PAGE
});

export const getListPageSuccess = (data) => ({
    type: GET_LIST_PAGE_SUCCESS,
    payload: {
        data
    }
});

export const getListPageError = (error) => ({
    type: GET_LIST_PAGE_ERROR,
    payload: {
        error
    }
});

export const activePages = (page_id) => ({
    type: ACTIVE_PAGES,
    payload: page_id
});

export const showArchived = () => ({
    type: SHOW_ARCHIVED,
    payload : true
});

export const hideArchived = () => ({
    type: HIDE_ARCHIVED,
    payload : false
});

export const activePagesInRoles = (page_id, name_page) => ({
    type: ACTIVE_PAGES_IN_ROLES,
    payload: {
        page_id: page_id,
        name_page: name_page,
    }
});

export const activePagesInTags = (page_id, name_page) => ({
    type: ACTIVE_PAGES_IN_TAGS,
    payload: {
        page_id: page_id,
        name_page: name_page,
    }
});

export const activePagesInShifts = (page_id, name_page) => ({
    type: ACTIVE_PAGES_IN_SHIFTS,
    payload: {
        page_id: page_id,
        name_page: name_page,
    }
});

export const activeNamePages = (name_page) => ({
    type: ACTIVE_NAME_PAGES,
    payload: name_page
});

export const choosePageInRoles = (page_id, name_page) => ({
    type: CHOOSE_PAGE_IN_ROLES,
    payload: {
        page_id: page_id,
        name_page: name_page,
    }
});

export const choosePageInTags = (page_id, name_page) => ({
    type: CHOOSE_PAGE_IN_TAGS,
    payload: {
        page_id: page_id,
        name_page: name_page,
    }
});

export const choosePageInShifts = (page_id, name_page) => ({
    type: CHOOSE_PAGE_IN_SHIFTS,
    payload: {
        page_id: page_id,
        name_page: name_page,
    }
});

export const updateNotRepLy = (listPagesUpdate) => ({
    type: UPDATE_NOT_REPLY,
    payload: {
        data: listPagesUpdate
    }
});

export const saveToTalNotRepLy = (totalNotReplied) => ({
    type: TOTAL_NOT_REPLIED,
    payload: {
        totalNotReplied: totalNotReplied
    }
});

export const saveToTalRepLy = (totalReplied) => ({
    type: TOTAL_REPLIED,
    payload: {
        totalReplied: totalReplied
    }
});

export const activePageById = (page_id) => ({
    type: ACTIVE_PAGE_BY_ID,
    payload: {
        page_id: page_id
    }
});

export const updateNotRepLyRequest = (page_id) => {
    return dispatch => {
        pageApis.getListPage()
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(updateNotRepLy(data));
            }).catch(error => {

        });
    }
}

export const getListPageRequest = () => {
    return dispatch => {
        dispatch(getListPage());
        pageApis.getListPage()
            .then(resp => {
                const {data} = resp.data.data;

                let totalNotReplied = resp.data.data.total_not_replied;
                let totalReplied = resp.data.data.total_replied;
                dispatch(saveToTalNotRepLy(totalNotReplied));
                dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListPageSuccess(data))
            }).catch(error => {
            dispatch(getListPageError(error));
        });
    }
}

export const getListPageArchivedRequest = () => {
    return dispatch => {
        dispatch(getListPage());
        pageApis.getListPageArchived()
            .then(resp => {
                const {data} = resp.data.data;

                // let totalNotReplied = resp.data.data.total_not_replied;
                // let totalReplied = resp.data.data.total_replied;
                // dispatch(saveToTalNotRepLy(totalNotReplied));
                // dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListPageSuccess(data))
            }).catch(error => {
            dispatch(getListPageError(error));
        });
    }
}

export const addArchivedPageRequest = (page_id) => {
    return (dispatch , getState) => {
        let data = {
            page_id : page_id
        }
        pageApis.addArchivedPage(data)
            .then(resp => {
                if(resp.data.code !== 0) return
                let listPages = getState().Page.listPages;
                let newListPages =  listPages.filter(item => item._id !== page_id);
                dispatch(getListPageSuccess(newListPages));
            }).catch(error => {
        });
    }
}

export const removeArchivedPageRequest = (page_id) => {
    return (dispatch , getState) => {
        pageApis.removeArchivedPage(page_id)
            .then(resp => {
                if(resp.data.code !== 0) return
                let listPages = getState().Page.listPages;
                let newListPages =  listPages.filter(item => item._id !== page_id);
                dispatch(getListPageSuccess(newListPages));
            }).catch(error => {
        });
    }
}

export const choosePageInRolesRequest = (page_id, name_page) => {
    return dispatch => {
        dispatch(choosePageInRoles(page_id, name_page));
    }
}

export const choosePageInTagsRequest = (page_id, name_page) => {
    return dispatch => {
        dispatch(choosePageInTags(page_id, name_page));
        tagApis.getListTag(page_id)
            .then(resp => {
                const {data} = resp.data.data;

                let totalNotReplied = resp.data.data.total_not_replied;
                let totalReplied = resp.data.data.total_replied;
                dispatch(saveToTalNotRepLy(totalNotReplied));
                dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListTagSidebarSuccess(data))
            }).catch(error => {
            dispatch(getListTagError(error))
        });
    }
}

export const getListPageInRoleRequest = () => {
    return dispatch => {
        pageApis.getListPage()
            .then(resp => {
                const {data} = resp.data.data;
                const page_id = data && data[0]._id || null;
                const name_page = data && data[0].name || null;

                let totalNotReplied = resp.data.data.total_not_replied;
                let totalReplied = resp.data.data.total_replied;
                dispatch(saveToTalNotRepLy(totalNotReplied));
                dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListPageSuccess(data));
                dispatch(activePagesInRoles(page_id, name_page));
                roleApis.getListRole(page_id)
                    .then(rs => {
                        const listRole = rs.data.data.data;
                        const roleDefault = listRole[0].id ? listRole[0].id : null;
                        dispatch(getListRoleInPageSuccess(listRole));
                        dispatch(activeRoleDefault(roleDefault));
                    }).catch(error => {

                });
                userApis.getUserInPage(page_id)
                    .then(res => {
                        const dataUserInPage = res.data.data.data;
                        dispatch(getListUserInPageSuccess(dataUserInPage));
                    }).catch(error => {

                });
            }).catch(error => {
            dispatch(getListPageError(error));
        });
    }
}

export const getListPageInTagRequest = () => {
    return (dispatch, getState) => {
        pageApis.getListPage()
            .then(resp => {
                let name_page;
                let page_id;
                const {data} = resp.data.data;
                if (getState().Page.page_id) {
                    page_id = getState().Page.page_id;
                    name_page = getState().Page.name_page;
                } else {
                    page_id = data && data[0]._id || null;
                    name_page = data && data[0].name || null;
                }

                let totalNotReplied = resp.data.data.total_not_replied;
                let totalReplied = resp.data.data.total_replied;
                dispatch(saveToTalNotRepLy(totalNotReplied));
                dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListPageSuccess(data));
                dispatch(activePagesInTags(page_id, name_page));
                tagApis.getListTag(page_id)
                    .then(rs => {
                        const listTags = rs.data.data.data;
                        dispatch(getListTagSidebar());
                        dispatch(getListTagSidebarSuccess(listTags));
                    }).catch(error => {

                });
            }).catch(error => {
            dispatch(getListPageError(error));
        });
    }
}

export const getListPageSearchRequest = (name) => {
    return dispatch => {
        dispatch(getListPage());
        pageApis.getListPage(name)
            .then(resp => {
                const {data} = resp.data.data;

                let totalNotReplied = resp.data.data.total_not_replied;
                let totalReplied = resp.data.data.total_replied;
                dispatch(saveToTalNotRepLy(totalNotReplied));
                dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListPageSuccess(data))
            }).catch(error => {
            dispatch(getListPageError(error));
        });
    }
}

export const getListPageInShiftRequest = () => {
    return (dispatch, getState) => {
        pageApis.getListPage()
            .then(resp => {
                const {data} = resp.data.data;
                if (getState().Page.page_id) {
                    var page_id = getState().Page.page_id;
                    var name_page = getState().Page.name_page;
                } else {
                    var page_id = data && data[0]._id || null;
                    var name_page = data && data[0].name || null;
                }

                let totalNotReplied = resp.data.data.total_not_replied;
                let totalReplied = resp.data.data.total_replied;
                dispatch(saveToTalNotRepLy(totalNotReplied));
                dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListPageSuccess(data));
                dispatch(activePagesInShifts(page_id, name_page));
                page_id && shiftApis.getListShift(page_id)
                    .then(rs => {
                        const listTags = rs.data.data.data;
                        dispatch(getListShiftSidebar());
                        dispatch(getListShiftSidebarSuccess(listTags));
                    }).catch(error => {

                    });
            }).catch(error => {

        });
    }
}

export const choosePageInShiftsRequest = (page_id, name_page) => {
    return dispatch => {
        dispatch(choosePageInShifts(page_id, name_page));
        dispatch(getListUserInPageRequest(page_id));
        page_id && shiftApis.getListShift(page_id)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListShiftSidebarSuccess(data))
            }).catch(error => {

            });
    }
}

export const getListPageInItemChatRequest = (pageId) => {
    return dispatch => {
        dispatch(getListPage());
        pageApis.getListPage()
            .then(resp => {
                const {data} = resp.data.data;
                let totalNotReplied = resp.data.data.total_not_replied;
                let totalReplied = resp.data.data.total_replied;
                dispatch(saveToTalNotRepLy(totalNotReplied));
                dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListPageSuccess(data))
                let namePage = data && data.find(el => (el._id === pageId))?.name || window.location.replace("/not-found");
                dispatch(activeNamePages(namePage));
            }).catch(error => {
            dispatch(getListPageError(error));
        });
    }
}

export const checkUrlChatItem = (pageId, chatId) => {
    return dispatch => {
        dispatch(getListPage());
        pageApis.getListPage()
            .then(resp => {
                const {data} = resp.data.data;
                let totalNotReplied = resp.data.data.total_not_replied;
                let totalReplied = resp.data.data.total_replied;
                dispatch(saveToTalNotRepLy(totalNotReplied));
                dispatch(saveToTalRepLy(totalReplied));

                dispatch(getListPageSuccess(data))
                let namePage = data && data.find(el => (el._id === pageId))?.name;
                dispatch(activeNamePages(namePage));
            }).catch(error => {
            dispatch(getListPageError(error));
        });
    }
}


