import {
    ACTIVE_ROLE_DEFAULT,
    GET_LIST_ROLE_IN_PAGE,
    GET_LIST_ROLE_IN_PAGE_SUCCESS,
} from './constants';

const INIT_STATE = {
    listRole : []
};

const Role = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_ROLE_IN_PAGE:
            return {
                ...state
            };

        case GET_LIST_ROLE_IN_PAGE_SUCCESS:
            return {
                ...state,
                listRole: action.payload.data
            };

        case ACTIVE_ROLE_DEFAULT:
            return {
                ...state,
                roleDefault: action.payload.roleDefault
            };

        default:
            return {...state};
    }
}

export default Role;
