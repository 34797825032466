import {
	SET_ACTIVE_TAB,
	OPEN_CONVERSATION_SIDEBAR,
	CLOSE_CONVERSATION_SIDEBAR,
	OPEN_LIST_TAG_SIDEBAR,
	CLOSE_LIST_TAG_SIDEBAR,
	ACTIVE_USER_CHAT,
	CLOSE_USER_CHAT,
	ACTIVE_LIST_SHIFT,
	CLOSE_LIST_SHIFT,
	ACTIVE_REPORT,
	CLOSE_REPORT,
	ACTIVE_SEARCH_IN_ALL_CONVERSATION, CLOSE_SEARCH_IN_ALL_CONVERSATION
} from "./constants";
import {saveListPageReport, saveReportData, updateSearchReport} from "../report/actions";
import * as reportApis from "../../apis/report";
import moment from "moment";
import {
	convertDateFromTimeStampInReport,
	convertDateToTimeStampInReport,
} from "../../helpers";

export const setActiveTab = (tabId) => ({
	type: SET_ACTIVE_TAB,
	payload: tabId
});

export const openConversationSidebar = () => ({
	type: OPEN_CONVERSATION_SIDEBAR
});

export const closeConversationSidebar = () => ({
	type: CLOSE_CONVERSATION_SIDEBAR
});

export const openListTagSidebar = () => ({
	type: OPEN_LIST_TAG_SIDEBAR
});

export const closeListTagSidebar = () => ({
	type: CLOSE_LIST_TAG_SIDEBAR
});

export const activeUserChatSuccess = () => ({
	type: ACTIVE_USER_CHAT
});

export const closeUserChat = () => ({
	type: CLOSE_USER_CHAT
});

export const activeListShiftSuccess = () => ({
	type: ACTIVE_LIST_SHIFT
});

export const closeListShift = () => ({
	type: CLOSE_LIST_SHIFT
});

export const activeReportSuccess = () => ({
	type: ACTIVE_REPORT
});

export const closeReport = () => ({
	type: CLOSE_REPORT
});

export const activeSearchInConversationSuccess = () => ({
	type: ACTIVE_SEARCH_IN_ALL_CONVERSATION
});

export const closeSearchInConversation = () => ({
	type: CLOSE_SEARCH_IN_ALL_CONVERSATION
});

export const activeUserChat = () => {
	return dispatch => {
		dispatch(activeUserChatSuccess());
		dispatch(closeListShift());
		dispatch(closeReport());
		dispatch(closeSearchInConversation());
	}
}

export const activeListShift = () => {
	return dispatch => {
		dispatch(activeListShiftSuccess());
		dispatch(closeUserChat());
		dispatch(closeReport());
		dispatch(closeSearchInConversation());
	}
}

export const activeSearchInConversation = () => {
	return dispatch => {
		dispatch(activeSearchInConversationSuccess());
		dispatch(closeUserChat());
		dispatch(closeReport());
		dispatch(closeListShift());
	}
}

export const activeReport = () => {
	return (dispatch , getState) => {
		let searchReport = getState().Report.searchReport;
		let from = moment().subtract(7,'d').format('YYYY-MM-DD');
		let to = moment().subtract(1,'d').format('YYYY-MM-DD');
		searchReport.from = from;
		searchReport.to = to;
		searchReport.report_by = 'page';
		dispatch(updateSearchReport(searchReport));

		let roles = getState().User.dataMe.roles;
		let listPages = getState().User.dataMe.pages;
		let data_roles = roles?.filter((v,i,a)=>a.findIndex(t=>((t.display_name === "Admin" || t.display_name === "Manager") && t.page_id===v.page_id))===i);
		let data = [];
		data_roles?.map((role) => {
			let name_page = listPages.find(o => o._id === role.page_id)?.name || null;
			if(name_page){
				data.push({
					_id : role.page_id,
					name : name_page,
				});
			}
		});
		let objAll = {
			_id : null ,
			name : "All" ,
		}

		data.unshift(objAll);
		dispatch(saveListPageReport(data));
		//get default report
		reportApis.getReportDailyGeneral(convertDateFromTimeStampInReport(from) , convertDateToTimeStampInReport(to))
			.then(rs => {
				if(rs.data.code === 0){
					let data_report = rs.data.data;
					let total = 0;
					let not_replied = 0;
					let replied = 0;
					let done = 0;
					let late_reply = 0;
					let dataTicket = [];
					let dataOutOfDate = [];
					let dataDetailTicket = [];
					let dataDetailOutOfDate = [];
					for(let key in data_report) {
						total += data_report[key]?.conversations?.total || 0;
						not_replied += data_report[key]?.conversations?.not_replied || 0;
						replied += data_report[key]?.conversations?.replied || 0;
						done += data_report[key]?.conversations?.done || 0;
						let date = moment(key, 'YYYY-MM-DD').format('DD/MM');
						let total_ticket = data_report[key]?.conversations?.total || 0;
						let late_3_hours = data_report[key]?.conversations?.late_3_hours || 0;
						let late_24_hours = data_report[key]?.conversations?.late_24_hours || 0;
						late_reply += late_3_hours + late_24_hours;
						dataTicket.push({date : date , conv : total_ticket});
						dataOutOfDate.push({
							date : date ,
							total : total_ticket,
							late_3_hours : late_3_hours,
							late_24_hours : late_24_hours,
						});
						dataDetailTicket.push({
							time : date,
							total_conv : total_ticket,
							replied : data_report[key]?.conversations?.replied || 0,
							new : data_report[key]?.conversations?.new || 0,
							not_reply : data_report[key]?.conversations?.not_replied || 0,
							done : data_report[key]?.conversations?.done || 0,
							late_reply : late_3_hours + late_24_hours,
							total_mess_sent : data_report[key]?.messages?.total || 0,
						});
						let ratio_3_hours = (late_3_hours + late_24_hours) !== 0 ? (late_3_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "0 %";
						let ratio_24_hours = (late_3_hours + late_24_hours) !== 0 ? (late_24_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "0 %";
						dataDetailOutOfDate.push({
							time : date,
							total_conv : total_ticket,
							total_late_conv : late_3_hours + late_24_hours,
							late_3h : {
								qty : late_3_hours,
								ratio : ratio_3_hours
							},
							late_24h : {
								qty : late_24_hours,
								ratio : ratio_24_hours
							}
						});
					}

					let from_report = moment(from, 'YYYY-MM-DD').format('DD/MM/YYYY');
					let to_report = moment(to, 'YYYY-MM-DD').format('DD/MM/YYYY');

					let reportData = {
						head : {
							username : null,
							from : from_report,
							to : to_report,
							all : total,
							not_reply : not_replied,
							replied : replied,
							done : done,
							late_reply : late_reply,
						},
						dataTicket : dataTicket ,
						dataOutOfDate : dataOutOfDate ,
						dataBug : [] ,
						dataDetailTicket : dataDetailTicket,
						dataDetailOutOfDate : dataDetailOutOfDate,
						dataDetailBug : [] ,
					}
					dispatch(saveReportData(reportData));
				}

			}).catch(err => {

		});

		dispatch(activeReportSuccess());
		dispatch(closeUserChat());
		dispatch(closeListShift());
		dispatch(closeSearchInConversation());
	}
}

export const activeReportIos = () => {
	return (dispatch , getState) => {
		let searchReport = getState().Report.searchReport;
		let from_api = moment().subtract(7,'d').utcOffset(7).set({hour:0,minute:0,second:0,millisecond:0}).format("x")/1000;
		let to_api = moment().subtract(1,'d').utcOffset(7).set({hour:23,minute:59,second:0,millisecond:0}).format("x")/1000;
		let from = moment().subtract(7,'d').utcOffset(7).set({hour:0,minute:0,second:0,millisecond:0}).toISOString();
		let to = moment().subtract(1,'d').utcOffset(7).set({hour:23,minute:59,second:0,millisecond:0}).toISOString();
		// let from = moment().subtract(6,'d').format('YYYY-MM-DD');
		// let to = moment().format('YYYY-MM-DD');
		searchReport.from = from;
		searchReport.to = to;
		searchReport.report_by = 'page';
		dispatch(updateSearchReport(searchReport));

		let roles = getState().User.dataMe.roles;
		let listPages = getState().User.dataMe.pages;
		let data_roles = roles && roles.filter((v,i,a)=>a.findIndex(t=>((t.display_name === "Admin" || t.display_name === "Manager") && t.page_id===v.page_id))===i);
		let data = [];
		data_roles && data_roles.map((role) => {
			let name_page = listPages.find(o => o._id === role.page_id).name;
			data.push({
				_id : role.page_id,
				name : name_page,
			});
		});

		let objAll = {
			_id : null ,
			name : "All" ,
		}

		data.unshift(objAll);

		dispatch(saveListPageReport(data));
		//get default report
		reportApis.getReportDailyGeneral(from_api , to_api)
			.then(rs => {
				if(rs.data.code === 0){
					let data_report = rs.data.data;
					let total = 0;
					let not_replied = 0;
					let replied = 0;
					let done = 0;
					let late_reply = 0;
					let dataTicket = [];
					let dataOutOfDate = [];
					let dataDetailTicket = [];
					let dataDetailOutOfDate = [];
					for(let key in data_report) {
						total += data_report[key]?.conversations?.total || 0;
						not_replied += data_report[key]?.conversations?.not_replied || 0;
						replied += data_report[key]?.conversations?.replied || 0;
						done += data_report[key]?.conversations?.done || 0;
						let date = moment(key, 'YYYY-MM-DD').format('DD/MM');
						let total_ticket = data_report[key]?.conversations?.total || 0;
						let late_3_hours = data_report[key]?.conversations?.late_3_hours || 0;
						let late_24_hours = data_report[key]?.conversations?.late_24_hours || 0;
						late_reply += late_3_hours + late_24_hours;
						dataTicket.push({date : date , conv : total_ticket});
						dataOutOfDate.push({
							date : date ,
							total : total_ticket,
							late_3_hours : late_3_hours,
							late_24_hours : late_24_hours,
						});
						dataDetailTicket.push({
							time : date,
							total_conv : total_ticket,
							new : data_report[key]?.conversations?.new || 0,
							replied : data_report[key]?.conversations?.replied || 0,
							not_reply : data_report[key]?.conversations?.not_replied || 0,
							done : data_report[key]?.conversations?.done || 0,
							late_reply : late_3_hours + late_24_hours,
							total_mess_sent : data_report[key]?.messages?.total || 0,
						});
						let ratio_3_hours = (late_3_hours + late_24_hours) !== 0 ? (late_3_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "0 %";
						let ratio_24_hours = (late_3_hours + late_24_hours) !== 0 ? (late_24_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "0 %";
						dataDetailOutOfDate.push({
							time : date,
							total_conv : total_ticket,
							total_late_conv : late_3_hours + late_24_hours,
							late_3h : {
								qty : late_3_hours,
								ratio : ratio_3_hours
							},
							late_24h : {
								qty : late_24_hours,
								ratio : ratio_24_hours
							}
						});
					}

					// let from_report = moment(from, 'YYYY-MM-DD').format('DD/MM/YYYY');
					// let to_report = moment(to, 'YYYY-MM-DD').format('DD/MM/YYYY');
					let from_report = moment(from, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
					let to_report = moment(to, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');

					let reportData = {
						head : {
							username : null,
							from : from_report,
							to : to_report,
							all : total,
							not_reply : not_replied,
							replied : replied,
							done : done,
							late_reply : late_reply,
						},
						dataTicket : dataTicket ,
						dataOutOfDate : dataOutOfDate ,
						dataBug : [] ,
						dataDetailTicket : dataDetailTicket,
						dataDetailOutOfDate : dataDetailOutOfDate,
						dataDetailBug : [] ,
					}
					dispatch(saveReportData(reportData));
				}

			}).catch(err => {


		});


		dispatch(activeReportSuccess());
		dispatch(closeUserChat());
		dispatch(closeListShift());
		dispatch(closeSearchInConversation());
	}
}

export const closeAllActive = () => {
	return dispatch => {
		dispatch(closeListShift());
		dispatch(closeUserChat());
		dispatch(closeReport());
		dispatch(closeSearchInConversation());
	}
}

