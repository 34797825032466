import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    UncontrolledTooltip
} from "reactstrap";
import classnames from "classnames";
import {connect} from "react-redux";
import {
    activeListShift,
    activeNamePages,
    activePages,
    activeReport,
    activeReportIos,
    choosePageInRolesRequest,
    choosePageInTagsRequest,
    clearFilterSearchChatSuccess,
    clearSearchReportRequest,
    closeAllActive,
    closeUserChat,
    getListPageRequest,
    getListRoleInPageRequest,
    getListShiftRequest,
    getListUserFilterShiftInPageRequest,
    getListUserInPageRequest,
    getWorkingStatusRequest,
    saveListDateFilterRequest,
    saveListDayFilterRequest,
    setActiveTab,
    updatePageLoadMoreChat
} from "../../redux/actions";
import logo from "../../assets/images/logo.svg"
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import "./../../assets/scss/custom.scss";
import {isIOS,} from "react-device-detect";
import {
    getTimestampEndCurrentWeek,
    getTimestampEndCurrentWeekIOS,
    getTimestampStartCurrentWeek,
    getTimestampStartCurrentWeekIOS, handleCheckOneChat
} from "../../helpers";
import {checkPermissionShowMenuCollaborator} from "../../helpers/permissions";

function LeftSidebarMenu(props) {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

    const toggleTab = tab => {
        //check tab
        if(window.location.pathname === '/dashboard' && !handleCheckOneChat()){
            props.setActiveTab(tab);
            props.closeUserChat();
            props.getListPageRequest();
            if(tab === 'tag' && props.page_id && props.name_page){
                props.choosePageInTagsRequest(props.page_id , props.name_page);
                props.closeAllActive();
            }else if(tab === 'role' && props.page_id && props.name_page){
                props.choosePageInRolesRequest(props.page_id , props.name_page);
                props.getListUserInPageRequest(props.page_id);
                props.getListRoleInPageRequest(props.page_id);
                props.closeAllActive();
            }else if(tab === 'group'){
                props.updatePageLoadMoreChat(1);
                props.closeAllActive();
            }else if(tab === 'shift'){
                if(props.page_id && props.name_page){
                    props.activePages(props.page_id);
                    props.activeNamePages(props.name_page);
                    props.clearFilterSearchChatSuccess();
                    props.activeListShift();
                    if(isIOS){
                        props.getListUserFilterShiftInPageRequest(getTimestampStartCurrentWeekIOS(),getTimestampEndCurrentWeekIOS());
                    }else{
                        props.getListUserFilterShiftInPageRequest(getTimestampStartCurrentWeek(),getTimestampEndCurrentWeek());
                    }
                    props.saveListDateFilterRequest();
                    props.saveListDayFilterRequest();
                }
            }else if(tab === 'profile'){
                // props.getWorkingStatusRequest();
                props.closeAllActive();
            }else if(tab === 'settings'){
                props.closeAllActive();
            }else if(tab === 'report'){
                if(isIOS){
                    props.activeReportIos();
                }else{
                    props.activeReport();
                }
            }
            document.getElementById('checkbox-all-chat').checked = false;
            //reset page all report
            props.clearSearchReportRequest();
        }else{
            window.location.href = '/dashboard';
        }
    }

    const activeTab = props.activeTab;

    return (
        <React.Fragment>
            <div className="side-menu flex-lg-column me-lg-1" id="side-menu">
                {/* LOGO */}
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="logo" height="30"/>
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={logo} alt="logo" height="30"/>
                        </span>
                    </Link>
                    <h5 className="font-size-13 mb-0 text-body">
                        v1.0.51.6
                    </h5>
                </div>
                {/* end navbar-brand-box  */}

                {/* Start side-menu nav */}
                <div className="flex-lg-column my-auto">
                    <Nav pills className="side-menu-nav justify-content-center" role="tablist">
                        <NavItem id="Groups">
                            <NavLink id="pills-groups-tab" className={classnames({active: activeTab === 'group'})}
                                     onClick={() => {
                                         toggleTab('group');
                                     }}>
                                <i className="ri-message-3-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Groups" placement="top">
                            Pages
                        </UncontrolledTooltip>
                        <NavItem id="Tags">
                            <NavLink id="pills-tags-tab" className={classnames({active: activeTab === 'tag'})}
                                     onClick={() => {
                                         toggleTab('tag');
                                     }}>
                                <i className="ri-price-tag-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Tags" placement="top">
                            Tags
                        </UncontrolledTooltip>
                        <NavItem id="Roles">
                            <NavLink id="pills-roles-tab" className={classnames({active: activeTab === 'role'})}
                                     onClick={() => {
                                         toggleTab('role');
                                     }}>
                                <i className="ri-shield-keyhole-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Roles" placement="top">
                            Roles
                        </UncontrolledTooltip>

                        <NavItem id="Shifts">
                            <NavLink id="pills-shifts-tab" className={classnames({active: activeTab === 'shift'})}
                                     onClick={() => {
                                         toggleTab('shift');
                                     }}>
                                <i className="ri-time-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Shifts" placement="top">
                            Shifts
                        </UncontrolledTooltip>

                        {/*report*/}
                        {
                            props.dataMe && checkPermissionShowMenuCollaborator(props.dataMe) &&
                            <>
                                <NavItem id="Reports">
                                    <NavLink id="pills-reports-tab"
                                             className={classnames({active: activeTab === 'report'})}
                                             onClick={() => {
                                                 toggleTab('report');
                                             }}>
                                        <i className="ri-bar-chart-line"></i>
                                    </NavLink>
                                </NavItem>
                                <UncontrolledTooltip target="Reports" placement="top">
                                    Reports
                                </UncontrolledTooltip>
                            </>
                        }
                        {/*end report*/}

                        <Dropdown nav isOpen={dropdownOpenMobile} toggle={toggleMobile}
                                  className="profile-user-dropdown-fix d-inline-block d-lg-none">
                            <DropdownToggle nav>
                                {
                                    props.imageUrl &&
                                    <img src={props.imageUrl} alt="" className="profile-user rounded-circle"/>
                                }
                                {
                                    !props.imageUrl &&
                                    <img src={avatar1} alt="" className="profile-user rounded-circle"/>
                                }

                            </DropdownToggle>
                            <DropdownMenu className="fix-top-dropdown-mobile">
                                <DropdownItem onClick={() => {
                                    toggleTab('settings');
                                }}>Setting <i className="ri-settings-3-line float-end text-muted"></i></DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem onClick={() => {
                                    toggleTab('profile');
                                }}>Profile <i className="ri-profile-line float-end text-muted"></i></DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem href="/logout">Log out <i
                                    className="ri-logout-circle-r-line float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                {/* end side-menu nav */}

                <div className="flex-lg-column d-none d-lg-block">
                    <Nav className="side-menu-nav justify-content-center">
                        <Dropdown nav isOpen={dropdownOpen}
                                  className="btn-group dropup nav-item profile-user-dropdown d-inline-block"
                                  toggle={toggle}>
                            <DropdownToggle nav>
                                {
                                    props.imageUrl &&
                                    <img src={props.imageUrl} alt="" className="profile-user rounded-circle"/>
                                }
                                {
                                    !props.imageUrl &&
                                    <img src={avatar1} alt="" className="profile-user rounded-circle"/>
                                }

                            </DropdownToggle>
                            <DropdownMenu className={"fix-top-dropdown"}>
                                <DropdownItem onClick={() => {
                                    toggleTab('settings');
                                }}>Setting <i className="ri-settings-3-line float-end text-muted"></i></DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem onClick={() => {
                                    toggleTab('profile');
                                }}>Profile <i className="ri-profile-line float-end text-muted"></i></DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem href="/logout">Log out <i
                                    className="ri-logout-circle-r-line float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                {/* Side menu user */}
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const {imageUrl} = state.Auth.user;
    const {name_page, page_id} = state.Page;
    const {dataMe} = state.User;
    return {
        ...state.Layout,
        imageUrl,
        name_page,
        page_id,
        dataMe
    };
};

export default connect(mapStatetoProps, {
    setActiveTab,
    closeUserChat,
    choosePageInTagsRequest,
    choosePageInRolesRequest,
    getListUserInPageRequest,
    getListRoleInPageRequest,
    updatePageLoadMoreChat,
    getListPageRequest,
    getWorkingStatusRequest,
    getListShiftRequest,
    activeListShift,
    activePages,
    activeNamePages,
    clearFilterSearchChatSuccess,
    getListUserFilterShiftInPageRequest,
    saveListDateFilterRequest,
    saveListDayFilterRequest,
    closeAllActive,
    activeReport,
    clearSearchReportRequest,
    activeReportIos
})(LeftSidebarMenu);
