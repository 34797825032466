import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import {configureStore} from './redux/store';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
    <Provider store={configureStore()}>
        <ToastContainer/>
        <App/>
    </Provider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register({
//     onUpdate: registration => {
//         alert('New version!  Ready to update?');
//         if (registration && registration.waiting) {
//             registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         }
//         window.location.reload();
//     }
// });
