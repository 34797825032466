export const GET_LIST_MESSAGE = "GET_LIST_MESSAGE";
export const GET_LIST_MESSAGE_SUCCESS = "GET_LIST_MESSAGE_SUCCESS";
export const GET_LIST_MESSAGE_ERROR = "GET_LIST_MESSAGE_ERROR";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

export const GET_NEW_MESSAGE_SUCCESS = "GET_NEW_MESSAGE_SUCCESS";
export const GET_UPDATED_MESSAGE_SUCCESS = "GET_UPDATED_MESSAGE_SUCCESS";

export const SEND_MESSAGE_SCROLL = "SEND_MESSAGE_SCROLL";
export const GET_NEW_MESSAGE_SCROLL_SUCCESS = "GET_NEW_MESSAGE_SCROLL_SUCCESS";
export const GET_NEW_MESSAGE_SCROLL_ERROR = "GET_NEW_MESSAGE_SCROLL_ERROR";

export const LOADING_ATTACHMENT = "LOADING_ATTACHMENT";

export const SHOW_CONVERSATION = "SHOW_CONVERSATION";
export const SHOW_CONVERSATION_SUCCESS = "SHOW_CONVERSATION_SUCCESS";
export const SHOW_CONVERSATION_ERROR = "SHOW_CONVERSATION_ERROR";

export const GET_LIST_MESSAGE_SEARCH_SUCCESS = "GET_LIST_MESSAGE_SEARCH_SUCCESS";
export const RESET_FILTER_SEARCH_MESSAGE_SUCCESS = "RESET_FILTER_SEARCH_MESSAGE_SUCCESS";

export const GET_LIST_MESSAGE_SEARCH_CONVERSATION_SUCCESS = "GET_LIST_MESSAGE_SEARCH_CONVERSATION_SUCCESS";
export const RESET_SEARCH_CONVERSATION_SUCCESS = "RESET_SEARCH_CONVERSATION_SUCCESS";

export const GET_LIST_MESSAGE_IN_ALL_CONVERSATION = "GET_LIST_MESSAGE_IN_ALL_CONVERSATION";
export const GET_LIST_MESSAGE_IN_ALL_CONVERSATION_SUCCESS = "GET_LIST_MESSAGE_IN_ALL_CONVERSATION_SUCCESS";
export const GET_LIST_MESSAGE_IN_ALL_CONVERSATION_ERROR = "GET_LIST_MESSAGE_IN_ALL_CONVERSATION_ERROR";
