import {
    CHANGE_ACTION_SHIFT,
    GET_LIST_SHIFT_SIDEBAR,
    GET_LIST_SHIFT_SIDEBAR_SUCCESS,
    GET_PAGINATION_SHIFT_SUCCESS,
    GET_STATUS_SHIFT,
    GET_WORKING_STATUS,
    REMOVE_SHIFT_SIDEBAR_SUCCESS,
    SAVE_END_DATE_FILTER,
    SAVE_FROM_DATE_FILTER,
    SAVE_LIST_DATE_FILTER,
    SAVE_LIST_DAY_FILTER,
    UPDATE_END_DATE_FILTER,
    UPDATE_FROM_DATE_FILTER,
    UPDATE_LIST_DATE_FILTER,
    UPDATE_LIST_DAY_FILTER,
    UPDATE_LIST_SHIFT,
    UPDATE_STATUS_SHIFT
} from './constants';
import * as shiftApis from "../../apis/shift";
import * as userApis from "../../apis/user";
import {toast} from "react-toastify";
import {getDateInAnyTime, getDateInCurrentWeek, getDayInAnyTime, getDayInCurrentWeek} from "./../../helpers/index";
import {getListUserFilterShiftInPageRequest, saveDataMeSuccess} from "../user/actions";

export const getListShiftSidebar = () => ({
    type: GET_LIST_SHIFT_SIDEBAR
});

export const getListShiftSidebarSuccess = (data) => ({
    type: GET_LIST_SHIFT_SIDEBAR_SUCCESS,
    payload: {
        data
    }
});

export const getListMoreShiftSuccess = (listShifts) => ({
    type: GET_PAGINATION_SHIFT_SUCCESS,
    payload: {
        listShifts: listShifts,
    }
});

export const removeShiftSidebarSuccess = (listShifts) => ({
    type: REMOVE_SHIFT_SIDEBAR_SUCCESS,
    payload: {
        listShifts
    }
});

export const getStatusShiftSuccess = (statusShift) => ({
    type: GET_STATUS_SHIFT,
    payload: {
        statusShift: statusShift
    }
});

export const changeActionShiftSuccess = (statusShift) => ({
    type: CHANGE_ACTION_SHIFT,
    payload: {
        statusShift: statusShift
    }
});

export const updateListShiftSuccess = (listShifts) => ({
    type: UPDATE_LIST_SHIFT,
    payload: {
        listShifts: listShifts
    }
});

export const getWorkingStatusSuccess = (statusShift) => ({
    type: GET_WORKING_STATUS,
    payload: {
        statusShift: statusShift
    }
});

export const saveListDateFilterSuccess = (listDateFilter) => ({
    type: SAVE_LIST_DATE_FILTER,
    payload: {
        listDateFilter: listDateFilter
    }
});

export const saveListDayFilterSuccess = (listDayFilter) => ({
    type: SAVE_LIST_DAY_FILTER,
    payload: {
        listDayFilter: listDayFilter
    }
});

export const updateListDateFilterSuccess = (listDateFilter) => ({
    type: UPDATE_LIST_DATE_FILTER,
    payload: {
        listDateFilter: listDateFilter
    }
});

export const updateListDayFilterSuccess = (listDayFilter) => ({
    type: UPDATE_LIST_DAY_FILTER,
    payload: {
        listDayFilter: listDayFilter
    }
});

export const saveFromDateFilterSuccess = (fromDateFilter) => ({
    type: SAVE_FROM_DATE_FILTER,
    payload: {
        fromDateFilter: fromDateFilter
    }
});

export const updateFromDateFilterSuccess = (fromDateFilter) => ({
    type: UPDATE_FROM_DATE_FILTER,
    payload: {
        fromDateFilter: fromDateFilter
    }
});

export const saveEndDateFilterSuccess = (endDateFilter) => ({
    type: SAVE_END_DATE_FILTER,
    payload: {
        endDateFilter: endDateFilter
    }
});

export const updateEndDateFilterSuccess = (endDateFilter) => ({
    type: UPDATE_END_DATE_FILTER,
    payload: {
        endDateFilter: endDateFilter
    }
});

export const updateStatusShiftSuccess = (statusShift) => ({
    type: UPDATE_STATUS_SHIFT,
    payload: {
        statusShift: statusShift
    }
});

export const getListShiftRequest = () => {
    return (dispatch, getState) => {
        let page_id = getState().Page.page_id;
        page_id && shiftApis.getListShift(page_id)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListShiftSidebarSuccess(data));
            }).catch(error => {
            });
    }
}

export const getMoreShiftRequest = (page_id, page) => {
    return (dispatch, getState) => {
        var listShifts = getState().Shift.listShifts;
        page_id && shiftApis.getListShift(page_id, page)
            .then(resp => {
                const {data} = resp.data.data;
                var listShiftsSidebarUpdate = [...listShifts].concat(data);
                dispatch(getListMoreShiftSuccess(listShiftsSidebarUpdate));
            }).catch(error => {

            });
    }
}

export const removeShiftSidebarRequest = (shift_id) => {
    return (dispatch, getState) => {
        shift_id && shiftApis.removeShift(shift_id)
            .then(resp => {
                let listShifts = [...getState().Shift.listShifts];
                let listShiftsUpdate = listShifts && listShifts.filter(item => item._id !== shift_id);
                dispatch(removeShiftSidebarSuccess(listShiftsUpdate));
                toast.success("Remove shift success!");
            }).catch(error => {

            });
    }
}

export const createShiftSidebarRequest = (request) => {
    return (dispatch, getState) => {
        var page_id = getState().Page.page_id;
        page_id && shiftApis.createShift(page_id, request.user_id, request.from, request.to)
            .then(resp => {
                page_id && shiftApis.getListShift(page_id)
                    .then(resp => {
                        const {data} = resp.data.data;
                        let code = resp.data.code;
                        if (code === 0) {
                            dispatch(getListShiftSidebarSuccess(data));
                            toast.success("Create shift success!");
                        }
                    }).catch(error => {

                    });

            }).catch(error => {

            });
    }
}

export const createShiftSidebarBrowserRequest = (request) => {
    return (dispatch, getState) => {
        let page_id = getState().Page.page_id;
        let fromDateFilter = getState().Shift.fromDateFilter;
        let endDateFilter = getState().Shift.endDateFilter;
        shiftApis.createShift(page_id, request.user_id, request.from, request.to)
            .then(resp => {
                page_id && shiftApis.getListShift(page_id)
                    .then(resp => {
                        // const {data} = resp.data.data;
                        let code = resp.data.code;
                        if (code === 0) {
                            dispatch(getListUserFilterShiftInPageRequest(fromDateFilter, endDateFilter));
                            toast.success("Create shift success!");
                        }
                    }).catch(error => {

                    });

            }).catch(error => {

        });
    }
}

export const removeShiftSidebarBrowserRequest = (shift_id) => {
    return (dispatch, getState) => {
        let page_id = getState().Page.page_id;
        let fromDateFilter = getState().Shift.fromDateFilter;
        let endDateFilter = getState().Shift.endDateFilter;
        shift_id && shiftApis.removeShift(shift_id)
            .then(res => {
                if (res.data.code === 0) {
                    page_id && shiftApis.getListShift(page_id)
                        .then(resp => {
                            let code = resp.data.code;
                            if (code === 0) {
                                dispatch(getListUserFilterShiftInPageRequest(fromDateFilter, endDateFilter));
                                toast.success("Remove shift success!");
                            }
                        }).catch(error => {
                        });
                }
            }).catch(error => {

            });
    }
}

export const editShiftSidebarBrowserRequest = (request) => {
    return (dispatch, getState) => {
        let page_id = getState().Page.page_id;
        let fromDateFilter = getState().Shift.fromDateFilter;
        let endDateFilter = getState().Shift.endDateFilter;
        shiftApis.editShift(request.shift_id, request.from, request.to)
            .then(resp => {
                page_id && shiftApis.getListShift(page_id)
                    .then(resp => {
                        // const {data} = resp.data.data;
                        dispatch(getListUserFilterShiftInPageRequest(fromDateFilter, endDateFilter));
                        toast.success("Edit shift success!");
                    }).catch(error => {

                    });
            }).catch(error => {
        });
    }
}

export const editShiftSidebarRequest = (request) => {
    return (dispatch, getState) => {
        const page_id = getState().Page.page_id;
        shiftApis.editShift(request.shift_id, request.from, request.to)
            .then(resp => {
                page_id && shiftApis.getListShift(page_id)
                    .then(resp => {
                        const {data} = resp.data.data;
                        toast.success("Edit shift success!");
                        dispatch(getListShiftSidebarSuccess(data));
                    }).catch(error => {

                    });
            }).catch(error => {
        });
    }
}

export const getStatusShiftRequest = () => {
    return (dispatch, getState) => {
        let statusShift = getState().User.dataMe.in_shift;
        dispatch(getStatusShiftSuccess(statusShift));
        // shiftApis.getStatusShift()
        //     .then(resp => {
        //         let code  = resp.data.code;
        //         if(code === 0){
        //             let statusShift  = resp.data.data.in_shift;
        //             dispatch(getStatusShiftSuccess(statusShift));
        //         }
        //     }).catch(error => {
        //
        // });
    }
}

export const changeActionShiftRequest = (action) => {
    return (dispatch, getState) => {
        shiftApis.changeActionShift(action)
            .then(resp => {
                let code = resp.data.code;
                if (code === 0) {
                    let statusShift = resp.data.data && resp.data.data.in_shift;
                    dispatch(changeActionShiftSuccess(statusShift));
                    dispatch(getListShiftRequest());
                }
            }).catch(error => {

        });
    }
}

export const getWorkingStatusRequest = () => {
    return (dispatch) => {
        userApis.getMe()
            .then(resp => {
                let code = resp.data.code;
                if (code === 0) {
                    let statusShift = resp.data.data.in_shift;
                    let dataMe = resp.data.data;
                    dispatch(getWorkingStatusSuccess(statusShift));
                    dispatch(saveDataMeSuccess(dataMe));
                }
            }).catch(error => {

        });
    }
}

export const saveListDateFilterRequest = () => {
    return (dispatch) => {
        dispatch(saveListDateFilterSuccess(getDateInCurrentWeek()));
    }
}

export const saveListDayFilterRequest = () => {
    return (dispatch) => {
        dispatch(saveListDayFilterSuccess(getDayInCurrentWeek()));
    }
}

export const updateListDateFilterRequest = (start, end) => {
    return (dispatch) => {
        dispatch(updateListDateFilterSuccess(getDateInAnyTime(start, end)));
    }
}

export const updateListDayFilterRequest = (start, end) => {
    return (dispatch) => {
        dispatch(updateListDayFilterSuccess(getDayInAnyTime(start, end)));
    }
}
