export const ADMIN = 'Admin';
export const MANAGER = 'Manager';
export const COLLABORATOR = 'Collaborator';
export const PAGE = 'Page';
export const ROLE = 'Role';
export const CHAT = 'Chat';
export const TAG = 'Tag';
export const REPORT = 'Report';
export const CONVERSATION = 'Conversation';
export const QUICK_REPLY = 'Quick_reply';
export const SHIFT = 'Shift';
export const USER_DETAIL = 'User_detail';
export const VIEW = 'View';
export const REPLY = 'Reply';
export const CREATE = 'Create';
export const DELETE = 'Delete';
export const ADD = 'Add';
export const REMOVE = 'Remove';
export const ASSIGN = 'Assign';
export const EDIT = 'Edit';
export const DONE = 'Done';

export const listPermissions = (page_id , roles) => {
    let permission = roles.find(el => (el.page_id === page_id))?.permissions;
    return permission && permission.map(element => element.display_name) || [];
}

//nameFunc : Chat
//action : View
//data : dataMe
export const checkPermission = (page_id , data ,  nameFunc , action) => {
    if(!page_id || !data?.roles){
        return false;
    }
    let page_action = nameFunc + ' - ' + action;
    let page_action_any = nameFunc + ' - *';
    let arr_permission = null;
    let flag = false;
    arr_permission = listPermissions(page_id , data.roles);
    if(arr_permission.length !== 0 ){
        arr_permission && arr_permission.forEach((permission)=>{
            if(permission === page_action || permission === page_action_any){
                flag = true;
            }
        });
    }else if(arr_permission.length === 0  && data.is_super_admin === true){
        flag = true;
    }

    return flag;
};

export const checkPermissionShowMenuCollaborator = (data) => {
    if(!data.roles && data.is_super_admin === false){
        return false;
    }
    if(data.is_super_admin === true){
        return true;
    }else{
        let check = data.roles && data.roles.findIndex(x => x.display_name === ADMIN || x.display_name === MANAGER);
        if(check !== -1){
            return true;
        }else{
            return false;
        }
    }
}


//manager ko dc edit , create tag bug
export const checkPermissionTag = (page_id , data ,  nameFunc , action) => {
    let flagRoleName = false;
    let checkPer = checkPermission(page_id , data ,  nameFunc , action);
    let checkRoleName = data.roles && data.roles.find(el => (el.page_id === page_id))?.display_name;

    if(checkRoleName === ADMIN){
        flagRoleName = true;
    }

    if(flagRoleName === true && checkPer === true){
        return true;
    }else{
        return false;
    }
}

