import {
    SET_FONT_SIZE_MESSAGE
} from './constants';

export const setFontSizeMessage = (message) => ({
    type: SET_FONT_SIZE_MESSAGE,
    payload: {
        message : message
    }
});

export const setFontSizeMessageRequest = (fontSize) => {
    return (dispatch) => {
        let message = {
            fontSize : fontSize
        }
        dispatch(setFontSizeMessage(message));
    }
}


