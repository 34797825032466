import axios from "axios";
import {parseToken} from "./index";

const axiosFromDataClient = axios.create({
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    },
});


axiosFromDataClient.interceptors.request.use((config) => {
    try {
        const access_token = localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).access_token;
        const decodedJwt = parseToken(access_token);
        if (decodedJwt.exp * 1000 < Date.now()) {
            window.location.replace("/logout-expired");
        }else{
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${access_token}`,
            };
            return config;
        }

    } catch (error) {
        return Promise.reject(error);
    }
});

export {axiosFromDataClient};
