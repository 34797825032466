import {
    CLEAR_SEARCH_REPORT, SAVE_LIST_MANAGER_REPORT,
    SAVE_LIST_PAGE_REPORT,
    SAVE_LIST_USER_REPORT,
    SAVE_REPORT_DATA,
    SAVE_SEARCH_REPORT, UPDATE_LIST_MANAGER_REPORT,
    UPDATE_LIST_PAGE_REPORT,
    UPDATE_LIST_USER_REPORT,
    UPDATE_SEARCH_REPORT,
    UPDATE_USER_ID_SEARCH_REPORT,
} from './constants';

const INIT_STATE = {
    searchReport : {
        from : null ,
        to : null ,
        page_id : null ,
        user_id : null ,
        manager_id : null ,
        report_by : 'page' ,
    } ,
    listUser : [],
    listPage : [],
    listManager : [],
    reportData : {}
};

const Report = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SAVE_SEARCH_REPORT:
            return {
                ...state,
                searchReport: action.payload.searchReport
            };

        case UPDATE_SEARCH_REPORT:
            return {
                ...state,
                searchReport: action.payload.searchReport
            };

        case SAVE_LIST_USER_REPORT:
            return {
                ...state,
                listUser: action.payload.listUser
            };

        case UPDATE_LIST_USER_REPORT:
            return {
                ...state,
                listUser: action.payload.listUser
            };

        case SAVE_LIST_MANAGER_REPORT:
            return {
                ...state,
                listManager: action.payload.listManager
            };

        case UPDATE_LIST_MANAGER_REPORT:
            return {
                ...state,
                listManager: action.payload.listManager
            };

        case SAVE_LIST_PAGE_REPORT:
            return {
                ...state,
                listPage: action.payload.listPage
            };

        case UPDATE_LIST_PAGE_REPORT:
            return {
                ...state,
                listPage: action.payload.listPage
            };

        case CLEAR_SEARCH_REPORT:
            return {
                ...state,
                listUser: action.payload.listUser,
                searchReport : action.payload.searchReport
            };

        case SAVE_REPORT_DATA:
            return {
                ...state,
                reportData: action.payload.reportData,
            };

        case UPDATE_USER_ID_SEARCH_REPORT:
            return {
                ...state,
                searchReport: action.payload.searchReport,
            };

        default:
            return {...state};
    }
}

export default Report;
