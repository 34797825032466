export const GET_REPORT_ALL_PAGE = "GET_REPORT_ALL_PAGE";
export const GET_REPORT_BY_PAGE_USER = "GET_REPORT_BY_PAGE_USER";

export const SAVE_SEARCH_REPORT = "SAVE_SEARCH_REPORT";
export const UPDATE_SEARCH_REPORT = "UPDATE_SEARCH_REPORT";

export const SAVE_LIST_USER_REPORT = "SAVE_LIST_USER_REPORT";
export const UPDATE_LIST_USER_REPORT = "UPDATE_LIST_USER_REPORT";

export const SAVE_LIST_PAGE_REPORT = "SAVE_LIST_PAGE_REPORT";
export const UPDATE_LIST_PAGE_REPORT = "UPDATE_LIST_PAGE_REPORT";

export const CLEAR_SEARCH_REPORT = "CLEAR_SEARCH_REPORT";

export const SAVE_REPORT_DATA = "SAVE_REPORT_DATA";

export const UPDATE_USER_ID_SEARCH_REPORT = "UPDATE_USER_ID_SEARCH_REPORT";

export const SAVE_LIST_MANAGER_REPORT = "SAVE_LIST_MANAGER_REPORT";
export const UPDATE_LIST_MANAGER_REPORT = "UPDATE_LIST_MANAGER_REPORT";
