import {
    GET_LIST_USER_FILTER_SHIFT_IN_PAGE_SUCCESS,
    GET_LIST_USER_IN_PAGE,
    GET_LIST_USER_IN_PAGE_SUCCESS, SAVE_DATA_ME, UPDATE_LIST_USER_FILTER_SHIFT_IN_PAGE,
    UPDATE_LIST_USER_IN_PAGE
} from './constants';

const INIT_STATE = {
    listUser : [],
    listUserFilterInShift : [],
    dataMe : [],
};

const User = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_USER_IN_PAGE:
            return {
                ...state
            };

        case GET_LIST_USER_IN_PAGE_SUCCESS:
            return {
                ...state,
                listUser: action.payload.listUser,
            };

        case UPDATE_LIST_USER_IN_PAGE:
            return {
                ...state,
                listUser: action.payload.listUser,
            };

        case GET_LIST_USER_FILTER_SHIFT_IN_PAGE_SUCCESS:
            return {
                ...state,
                listUserFilterInShift: action.payload.listUserFilterInShift,
            };

        case UPDATE_LIST_USER_FILTER_SHIFT_IN_PAGE:
            return {
                ...state,
                listUserFilterInShift: action.payload.listUserFilterInShift,
            };

        case SAVE_DATA_ME:
            return {
                ...state,
                dataMe: action.payload.dataMe,
            };

        default:
            return {...state};
    }
}

export default User;
