import axiosClient from "../helpers/axiosClient";
import {API_CHATS, API_PAGES, CHATS, REACT_APP_CHAT} from "./constants";
import {combineUrlParams} from "./../helpers/index";

export const getListChat = (page_id, status = null, page = 1, limit = 50, sort = "-last_message_at") => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${CHATS}`,
            {
                status: status,
                page: page,
                limit: limit,
                sort: sort
            }
        )
    );
}

export const getListChatFilter = (page_id, name, assignee_id, tags, status, page = 1, sort) => {
    const params_before = {
        status: status,
        limit: 50,
        name: name,
        assignee_id: assignee_id,
        page: page,
        sort: sort ? sort : "-last_message_at"
    };
    const params_tag = [];
    if (tags) {

        tags && tags.forEach((tag, key) => {
            params_tag["tags[" + key + "]"] = tag.value;
        });
    }
    const params = {...params_before, ...{...params_tag}};
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${CHATS}`,
            params
        )
    );
}

export const assignChatToUser = (chat_id, user_id) => {
    let data = {};
    data.user_id = user_id;
    return axiosClient.put(`${REACT_APP_CHAT}/${API_CHATS}/${chat_id}`, data);
}

export const markRead = (chat_id) => {
    return axiosClient.post(`${REACT_APP_CHAT}/${API_CHATS}/${chat_id}`);
}

export const getListChatScroll = (page_id, page, limit = 50, sort = "-last_message_at") => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${CHATS}`,
            {
                page: page,
                limit: limit,
                sort: sort
            }
        )
    );
}

export const assignMultiChatToUser = (page_id, chat_ids, user_id) => {
    let data = {};
    data.user_id = user_id;
    data.chat_ids = chat_ids;
    return axiosClient.put(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${CHATS}`, data);
}
