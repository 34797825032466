export const GET_LIST_SHIFT_SIDEBAR = "GET_LIST_SHIFT_SIDEBAR";
export const GET_LIST_SHIFT_SIDEBAR_SUCCESS = "GET_LIST_SHIFT_SIDEBAR_SUCCESS";
export const GET_PAGINATION_SHIFT_SUCCESS = "GET_PAGINATION_SHIFT_SUCCESS";
export const REMOVE_SHIFT_SIDEBAR_SUCCESS = "REMOVE_SHIFT_SIDEBAR_SUCCESS";
export const GET_STATUS_SHIFT = "GET_STATUS_SHIFT";
export const CHANGE_ACTION_SHIFT = "CHANGE_ACTION_SHIFT";
export const UPDATE_LIST_SHIFT = "UPDATE_LIST_SHIFT";
export const GET_WORKING_STATUS = "GET_WORKING_STATUS";

export const SAVE_LIST_DATE_FILTER = "SAVE_LIST_DATE_FILTER";
export const UPDATE_LIST_DATE_FILTER = "UPDATE_LIST_DATE_FILTER";

export const SAVE_LIST_DAY_FILTER = "SAVE_LIST_DAY_FILTER";
export const UPDATE_LIST_DAY_FILTER = "UPDATE_LIST_DAY_FILTER";

export const SAVE_FROM_DATE_FILTER = "SAVE_FROM_DATE_FILTER";
export const UPDATE_FROM_DATE_FILTER = "UPDATE_FROM_DATE_FILTER";

export const SAVE_END_DATE_FILTER = "SAVE_END_DATE_FILTER";
export const UPDATE_END_DATE_FILTER = "UPDATE_END_DATE_FILTER";

export const UPDATE_STATUS_SHIFT = "UPDATE_STATUS_SHIFT";


