import {
    GET_LIST_MESSAGE,
    GET_LIST_MESSAGE_ERROR,
    GET_LIST_MESSAGE_SUCCESS,
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_ERROR,
    GET_NEW_MESSAGE_SUCCESS,
    GET_UPDATED_MESSAGE_SUCCESS,
    SEND_MESSAGE_SCROLL,
    GET_NEW_MESSAGE_SCROLL_SUCCESS,
    GET_NEW_MESSAGE_SCROLL_ERROR,
    LOADING_ATTACHMENT,
    SHOW_CONVERSATION,
    SHOW_CONVERSATION_SUCCESS,
    SHOW_CONVERSATION_ERROR,
    GET_LIST_MESSAGE_SEARCH_SUCCESS,
    RESET_FILTER_SEARCH_MESSAGE_SUCCESS,
    GET_LIST_MESSAGE_SEARCH_CONVERSATION_SUCCESS,
    GET_LIST_MESSAGE_IN_ALL_CONVERSATION,
    GET_LIST_MESSAGE_IN_ALL_CONVERSATION_SUCCESS,
    GET_LIST_MESSAGE_IN_ALL_CONVERSATION_ERROR,
} from './constants';
import {ACTIVE_USER} from "../chat/constants";

const INIT_STATE = {
    loadingAttachment : false,
    optionSearch : {},
    listMessageInAllConversation : []
};

const Message = (state = INIT_STATE, action) => {
    let listMessages = []

    switch (action.type) {
        case GET_LIST_MESSAGE:
            return {
                ...state,
                listMessages: []
            };
        case GET_LIST_MESSAGE_SUCCESS:
            let {data} = action.payload;
            listMessages = data.reverse();
            return {
                ...state,
                listMessages: listMessages
            };
        case GET_LIST_MESSAGE_ERROR:
            return {
                ...state,
                listMessages: []
            };
        case GET_LIST_MESSAGE_IN_ALL_CONVERSATION:
            return {
                ...state,
                listMessageInAllConversation: []
            };
        case GET_LIST_MESSAGE_IN_ALL_CONVERSATION_SUCCESS:
            return {
                ...state,
                listMessageInAllConversation: action.payload.data.reverse()
            };
        case GET_LIST_MESSAGE_IN_ALL_CONVERSATION_ERROR:
            return {
                ...state,
                listMessageInAllConversation: []
            };
        case SEND_MESSAGE:
            return {
                ...state,
                message: []
            };
        case SEND_MESSAGE_SUCCESS:
            const {message} = action.payload;
            return {
                ...state,
                message: message,
            };
        case SEND_MESSAGE_ERROR:
            return {
                ...state,
                message: []
            };
        case GET_NEW_MESSAGE_SUCCESS:
            return {
                ...state,
                listMessages: action.payload.listMessages,
                loadingAttachment: false,
            };

        case GET_UPDATED_MESSAGE_SUCCESS:
            let updatedMessage = action.payload.data

            let updatedListMessages = [...state.listMessages]

            state.listMessages.find((message, index) => {
                if (message._id === updatedMessage._id) {
                    updatedMessage.updated_at = (new Date().getTime())
                    updatedListMessages[index] = updatedMessage

                    return true
                }

                return false
            })

            return {
                ...state,
                listMessages: updatedListMessages
            }
        case SEND_MESSAGE_SCROLL:
            return {
                ...state,
                message: []
            };

        case GET_NEW_MESSAGE_SCROLL_ERROR:
            return {
                ...state,
                message: []
            };
        case GET_NEW_MESSAGE_SCROLL_SUCCESS:
            let newMessageScroll = action.payload.data.reverse();
            let resultScroll = state.listMessages.find(message => message._id === newMessageScroll._id)

            if (!resultScroll) {
                listMessages = newMessageScroll.concat(state.listMessages);
            } else {
                listMessages = state.listMessages
            }

            return {
                ...state,
                listMessages: listMessages
            };
        case LOADING_ATTACHMENT:
            return {
                ...state,
                loadingAttachment : action.payload
            };
        case SHOW_CONVERSATION:
            return {
                ...state,
                listMessages: []
            };
        case SHOW_CONVERSATION_SUCCESS:
            console.log(action.payload.data)
            let data_conversation = action.payload.data.reverse();

            // if (state.listMessages.length) {
            //     listMessages = [data_conversation.reverse(), ...state.listMessages]
            // } else {
            //     listMessages = data_conversation.reverse()
            // }

            return {
                ...state,
                listMessages: data_conversation
            };

        case SHOW_CONVERSATION_ERROR:
            return {
                ...state,
                listMessages: []
            };

        case GET_LIST_MESSAGE_SEARCH_SUCCESS:

            if (state.listMessages.length) {
                listMessages = [action.payload.data.reverse(), ...state.listMessages]
            } else {
                listMessages = action.payload.data.reverse()
            }

            return {
                ...state,
                listMessages: listMessages,
                optionSearch : action.payload.optionSearch
            };

        case GET_LIST_MESSAGE_SEARCH_CONVERSATION_SUCCESS:

            listMessages = action.payload.data.reverse();

            return {
                ...state,
                listMessages: listMessages,
                // optionSearch : action.payload.optionSearch
            };

        case RESET_FILTER_SEARCH_MESSAGE_SUCCESS:
            return {
                ...state,
                optionSearch: {}
            };

        default:
            return {...state};
    }
}

export default Message;
