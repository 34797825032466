import {
    GET_LIST_MESSAGE,
    GET_LIST_MESSAGE_ERROR,
    GET_LIST_MESSAGE_IN_ALL_CONVERSATION,
    GET_LIST_MESSAGE_IN_ALL_CONVERSATION_ERROR,
    GET_LIST_MESSAGE_IN_ALL_CONVERSATION_SUCCESS,
    GET_LIST_MESSAGE_SEARCH_CONVERSATION_SUCCESS,
    GET_LIST_MESSAGE_SEARCH_SUCCESS,
    GET_LIST_MESSAGE_SUCCESS,
    GET_NEW_MESSAGE_SCROLL_ERROR,
    GET_NEW_MESSAGE_SCROLL_SUCCESS,
    GET_NEW_MESSAGE_SUCCESS,
    GET_UPDATED_MESSAGE_SUCCESS,
    LOADING_ATTACHMENT,
    RESET_FILTER_SEARCH_MESSAGE_SUCCESS,
    RESET_SEARCH_CONVERSATION_SUCCESS,
    SEND_MESSAGE,
    SEND_MESSAGE_ERROR,
    SEND_MESSAGE_SCROLL,
    SEND_MESSAGE_SUCCESS,
    SHOW_CONVERSATION,
    SHOW_CONVERSATION_ERROR,
    SHOW_CONVERSATION_SUCCESS,
} from './constants';

import * as messageApis from "./../../apis/message";
import {setConversationItemSuccess, setConversationLoadMoreSuccess} from "../conversation/actions";
import {toast} from "react-toastify";

export const getListMessage = (chat_id) => ({
    type: GET_LIST_MESSAGE,
    payload: chat_id
});

export const getListMessageSuccess = (data) => ({
    type: GET_LIST_MESSAGE_SUCCESS,
    payload: {
        data
    }
});

export const getListMessageInAllConversation = () => ({
    type: GET_LIST_MESSAGE_IN_ALL_CONVERSATION,
    payload: {}
});

export const getListMessageInAllConversationSuccess = (data) => ({
    type: GET_LIST_MESSAGE_IN_ALL_CONVERSATION_SUCCESS,
    payload: {
        data : data
    }
});

export const getListMessageInAllConversationError = (error) => ({
    type: GET_LIST_MESSAGE_IN_ALL_CONVERSATION_ERROR,
    payload: {
        error
    }
});

export const getListMessageSearchSuccess = (data, optionSearch) => ({
    type: GET_LIST_MESSAGE_SEARCH_SUCCESS,
    payload: {
        data: data,
        optionSearch: optionSearch
    }
});

export const getListMessageSearchConversationSuccess = (data, optionSearch) => ({
    type: GET_LIST_MESSAGE_SEARCH_CONVERSATION_SUCCESS,
    payload: {
        data: data,
        // optionSearch : optionSearch
    }
});

export const getNewMessageSuccess = (listMessages) => ({
    type: GET_NEW_MESSAGE_SUCCESS,
    payload: {
        listMessages: listMessages
    }
});

export const getUpdatedMessageSuccess = (data) => ({
    type: GET_UPDATED_MESSAGE_SUCCESS,
    payload: {
        data
    }
});

export const getListMessageError = (error) => ({
    type: GET_LIST_MESSAGE_ERROR,
    payload: {
        error
    }
});

export const sendMessage = (chat_id) => ({
    type: SEND_MESSAGE,
    payload: chat_id
});

export const sendMessageSuccess = (data) => ({
    type: SEND_MESSAGE_SUCCESS,
    payload: {
        data
    }
});

export const sendMessageError = (error) => ({
    type: SEND_MESSAGE_ERROR,
    payload: {
        error
    }
});

export const sendMessageScroll = (chat_id) => ({
    type: SEND_MESSAGE_SCROLL,
    payload: chat_id
});

export const getNewMessageScrollSuccess = (data) => ({
    type: GET_NEW_MESSAGE_SCROLL_SUCCESS,
    payload: {
        data
    }
});

export const sendMessageScrollError = (error) => ({
    type: GET_NEW_MESSAGE_SCROLL_ERROR,
    payload: {
        error
    }
});

export const loadingAttachment = (type) => ({
    type: LOADING_ATTACHMENT,
    payload: type
});

export const showConversation = (conversation_id) => ({
    type: SHOW_CONVERSATION,
    payload: conversation_id
});

export const showConversationSuccess = (data) => ({
    type: SHOW_CONVERSATION_SUCCESS,
    payload: {
        data
    }
});

export const showConversationError = (error) => ({
    type: SHOW_CONVERSATION_ERROR,
    payload: {
        error
    }
});

export const resetFilterSearchMessageSuccess = () => ({
    type: RESET_FILTER_SEARCH_MESSAGE_SUCCESS,
    payload: {}
});

export const resetFilterSearchConversationSuccess = () => ({
    type: RESET_SEARCH_CONVERSATION_SUCCESS,
    payload: {}
});


export const getListMessageRequest = (chat_id, before) => {
    return dispatch => {
        dispatch(getListMessage(chat_id));
        messageApis.getListMessage(chat_id, before)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListMessageSuccess(data))
            }).catch(error => {
            dispatch(getListMessageError(error));
        });
    }
}

export const getListMessageInAllConversationRequest = (chat_id , conversation_id, before) => {
    return dispatch => {
        dispatch(getListMessageInAllConversation());
        messageApis.getMessageInAllConversation(chat_id , conversation_id, before)
            .then(resp => {
                dispatch(getListMessageInAllConversationSuccess(resp.data.data.data))
            }).catch(error => {
            dispatch(getListMessageInAllConversationError(error));
        });
    }
}

export const sendMessageRequest = (chat_id, obj) => {
    return dispatch => {
        dispatch(sendMessage(chat_id));
        messageApis.sendMessage(chat_id, obj)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(sendMessageSuccess(data))
            })
            .catch(error => {
                //bad word
                toast.error(error.response.data.message);
                dispatch(sendMessageError(error));
            });
    }
}

export const sendMessageScrollRequest = (chat_id, before) => {
    return (dispatch, getState) => {
        const keyword = getState().Message.optionSearch && getState().Message.optionSearch.keyword || '';
        dispatch(sendMessageScroll(chat_id));
        messageApis.getListMessage(chat_id, before, keyword)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getNewMessageScrollSuccess(data))
            }).catch(error => {
            dispatch(sendMessageScrollError(error));
        });
    }
}

export const showConversationRequest = (conversation_id) => {
    return (dispatch, getState) => {
        const chat_id = getState().Chat.chat_id;
        const before = new Date().getTime();
        messageApis.getMessageInConversation(chat_id, conversation_id, before)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(showConversationSuccess(data));
                dispatch(setConversationLoadMoreSuccess(true));
                dispatch(setConversationItemSuccess(conversation_id));
            }).catch(error => {

        });
    }
}

export const getMessageSearchRequest = (chat_id, before, keyword) => {
    return dispatch => {
        dispatch(getListMessage(chat_id));
        messageApis.getListMessage(chat_id, before, keyword)
            .then(resp => {
                const {data} = resp.data.data;
                const optionSearch = {
                    before: before,
                    keyword: keyword
                };
                dispatch(getListMessageSearchSuccess(data, optionSearch))
            }).catch(error => {
            dispatch(getListMessageError(error));
        });
    }
}

export const getNewMessageRequest = (newMessage) => {
    return (dispatch, getState) => {
        var listMessages = getState().Message.listMessages;
        var listMessagesNew = [...listMessages];
        var result = listMessagesNew.find(message => message._id === newMessage._id);
        var listMessagesUpdate = null;
        if (!result) {
            listMessagesUpdate = [...listMessagesNew, newMessage];
        } else {
            listMessagesUpdate = [...listMessagesNew];
        }
        dispatch(getNewMessageSuccess(listMessagesUpdate));
    }
}

export const getMessageInConversationRequest = (before) => {
    return (dispatch, getState) => {
        const chat_id = getState().Chat.chat_id;
        const conversation_id = getState().Conversation.conversation_id;
        // const keyword = getState().Message.optionSearch && getState().Message.optionSearch.keyword || '';
        messageApis.getMessageInConversation(chat_id, conversation_id, before)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getNewMessageScrollSuccess(data))
            }).catch(error => {

        });
    }
}

export const getMessageSearchConversationRequest = (chat_id, before, keyword) => {
    return (dispatch, getState) => {
        dispatch(getListMessage(chat_id));
        const conversation_id = getState().Conversation.conversation_id;
        messageApis.getMessageInConversation(chat_id, conversation_id, before, keyword)
            .then(resp => {
                const {data} = resp.data.data;
                // const optionSearch = {
                //     before : before,
                //     keyword : keyword
                // };
                dispatch(getListMessageSearchConversationSuccess(data))
            }).catch(error => {
            dispatch(getListMessageError(error));
        });
    }
}

export const resetMessageSearchConversationRequest = () => {
    return (dispatch, getState) => {
        // dispatch(resetFilterSearchMessageSuccess());
        const conversation_id = getState().Conversation.conversation_id;
        dispatch(showConversation(conversation_id));
        dispatch(showConversationRequest(conversation_id));

    }
}

