import {ACTIVE_ROLE_DEFAULT, GET_LIST_ROLE_IN_PAGE, GET_LIST_ROLE_IN_PAGE_SUCCESS} from './constants';

import * as roleApis from "./../../apis/role";
import {getListUserInPageRequest} from "../user/actions";
import {toast} from 'react-toastify';

export const getListRoleInPageSuccess = (data) => ({
    type: GET_LIST_ROLE_IN_PAGE_SUCCESS,
    payload: {
        data
    }
});

export const getListRoleInPage = () => ({
    type: GET_LIST_ROLE_IN_PAGE,
    payload: {}
});

export const activeRoleDefault = (roleDefault) => ({
    type: ACTIVE_ROLE_DEFAULT,
    payload: {
        roleDefault
    }
});

export const getListRoleInPageRequest = (page_id) => {
    return dispatch => {
        dispatch(getListRoleInPage());
        roleApis.getListRole(page_id)
            .then(resp => {
                const {data} = resp.data.data;
                const roleDefault = data[0].id ? data[0].id : null;
                dispatch(activeRoleDefault(roleDefault));
                dispatch(getListRoleInPageSuccess(data));
            }).catch(error => {

        });
    }
}

export const assignRoleInPageRequest = (request) => {
    return (dispatch, getState) => {
        const page_id = getState().Page.page_id;
        const body = {
            role_id: request.role,
            action: "assign",
            email: request.email,
        }
        roleApis.assignRole(page_id, body)
            .then(resp => {
                dispatch(getListUserInPageRequest(page_id));
                roleApis.getListRole(page_id)
                    .then(resp => {
                        const {data} = resp.data.data;
                        toast.success("Assign user success!");
                        dispatch(getListRoleInPageSuccess(data));
                    }).catch(error => {

                });

            }).catch(error => {

        });
    }
}

export const removeUserToRoleRequest = (role_id, user_id, email) => {
    return (dispatch, getState) => {
        const page_id = getState().Page.page_id;
        let body = null;
        if (!email) {
            body = {
                role_id: role_id,
                action: "retract",
                user_id: user_id,
            }
        } else {
            body = {
                role_id: role_id,
                action: "retract",
                email: email,
            }
        }

        roleApis.removeUserToRole(page_id, body)
            .then(resp => {
                const code = resp.data.code;
                if (code === 0) {
                    toast.success("Remove user success!");
                    dispatch(getListUserInPageRequest(page_id));
                    roleApis.getListRole(page_id)
                        .then((rs) => {
                            const {data} = rs.data.data;
                            dispatch(getListRoleInPageSuccess(data));
                        }).catch(error => {

                    });
                }
            }).catch(error => {

        });
    }
}

