import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Chat from './chat/reducers';
import Page from './page/reducers';
import Message from './message/reducers';
import Conversation from './conversation/reducers';
import Tag from './tag/reducers';
import Shift from './shift/reducers';
import Layout from './layout/reducer';
import Role from "./role/reducers";
import User from "./user/reducers";
import Error from "./error/reducers";
import QuickReply from "./quickReply/reducers";
import Report from "./report/reducers";
import Setting from "./setting/reducers";

export default combineReducers({
    Auth,
    Chat,
    Page,
    Message,
    Conversation,
    Tag,
    Shift,
    Role,
    User,
    QuickReply,
    Report,
    Error,
    Layout,
    Setting,
});
