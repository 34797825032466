// @flow
import {
	SET_ACTIVE_TAB,
	OPEN_CONVERSATION_SIDEBAR,
	CLOSE_CONVERSATION_SIDEBAR,
	OPEN_LIST_TAG_SIDEBAR,
	CLOSE_LIST_TAG_SIDEBAR,
	ACTIVE_USER_CHAT,
	CLOSE_USER_CHAT,
	CLOSE_LIST_SHIFT,
	ACTIVE_LIST_SHIFT,
	ACTIVE_REPORT,
	CLOSE_REPORT, ACTIVE_SEARCH_IN_ALL_CONVERSATION, CLOSE_SEARCH_IN_ALL_CONVERSATION
} from "./constants";

const INIT_STATE = {
	activeTab : "group",
	conversationSidebar : false,
	listTagSidebar : false,
	activeUserChat : false,
	activeListShift : false,
	activeReport : false,
	activeSearchInAllConversation : false,
};

const Layout = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_ACTIVE_TAB:
			return {
				...state,
				activeTab: action.payload
			};

		case OPEN_CONVERSATION_SIDEBAR:
			return {
				...state,
				conversationSidebar: true
			};

		case CLOSE_CONVERSATION_SIDEBAR:
			return {
				...state,
				conversationSidebar: false
			};

		case OPEN_LIST_TAG_SIDEBAR:
			return {
				...state,
				listTagSidebar: true
			};

		case CLOSE_LIST_TAG_SIDEBAR:
			return {
				...state,
				listTagSidebar: false
			};

		case ACTIVE_USER_CHAT:
			return {
				...state,
				activeUserChat: true
			};

		case CLOSE_USER_CHAT:
			return {
				...state,
				activeUserChat: false
			};

		case ACTIVE_LIST_SHIFT:
			return {
				...state,
				activeListShift: true
			};

		case CLOSE_LIST_SHIFT:
			return {
				...state,
				activeListShift: false
			};

		case ACTIVE_REPORT:
			return {
				...state,
				activeReport: true
			};

		case CLOSE_REPORT:
			return {
				...state,
				activeReport: false
			};

		case ACTIVE_SEARCH_IN_ALL_CONVERSATION:
			return {
				...state,
				activeSearchInAllConversation: true
			};

		case CLOSE_SEARCH_IN_ALL_CONVERSATION:
			return {
				...state,
				activeSearchInAllConversation: false
			};

		default:
			return state;
	}
};

export default Layout;
