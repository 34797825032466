import {
    API_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS, LOGIN_FORM, LOGIN_FORM_ERROR, LOGIN_FORM_SUCCESS, LOGIN_FORM_UPDATE,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER, LOGOUT_USER_EXPIRED,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    TOKEN_GOOGLE,
    TOKEN_GOOGLE_ERROR,
    TOKEN_GOOGLE_SUCCESS
} from './constants';
import * as authApis from "./../../apis/auth";
import {getListPageRequest} from "../page/actions";
import {API_ME, REACT_APP_AUTH} from "../../apis/constants";
import axios from 'axios';

export const loginUser = (username, password, history) => ({
    type: LOGIN_USER,
    payload: {username, password, history}
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const registerUser = (user) => ({
    type: REGISTER_USER,
    payload: {user}
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: {history}
});

export const logoutUserExpired = () => ({
    type: LOGOUT_USER_EXPIRED,
    payload: {

    }
});

export const forgetPassword = (email) => ({
    type: FORGET_PASSWORD,
    payload: {email}
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const apiError = (error) => ({
    type: API_FAILED,
    payload: error
});

export const tokenGoogle = (data) => ({
    type: TOKEN_GOOGLE,
    payload: data
});

export const tokenGoogleError = (error) => ({
    type: TOKEN_GOOGLE_ERROR,
    payload: {
        error
    }
});

export const tokenGoogleSuccess = (data) => ({
    type: TOKEN_GOOGLE_SUCCESS,
    payload: {
        user : data,
    }
});

export const loginForm = (data) => ({
    type: LOGIN_FORM,
    payload: data
});

export const loginFormError = (error) => ({
    type: LOGIN_FORM_ERROR,
    payload: {
        error
    }
});

export const loginFormSuccess = (data) => ({
    type: LOGIN_FORM_SUCCESS,
    payload: {
        user : data,
    }
});

export const loginFormUpdate = (data) => ({
    type: LOGIN_FORM_UPDATE,
    payload: {
        user : data,
    }
});

export const sendTokenGoogleRequest = (data) => {
    return dispatch => {
        dispatch(tokenGoogle(data));
        let body = {};
        body.id = data.id;
        body.name = data.name;
        body.email = data.email;
        body.tokenId = data.tokenId;
        body.accessToken = data.accessToken;
        authApis.sendTokenGoogle(body)
            .then(resp => {
                const {access_token} = resp.data.data;
                data.access_token = access_token;
                localStorage.setItem("authUser", JSON.stringify(data));
                dispatch(tokenGoogleSuccess(data))
                dispatch(getListPageRequest())
            }).catch(error => {
            dispatch(tokenGoogleError(error))
        });
    }
}

export const loginFormRequest = (data) => {
    return dispatch => {
        dispatch(loginForm(data));
        let body = {};
        body.username = data.username;
        body.password = data.password;
        authApis.loginForm(body)
            .then(resp => {
                const token = resp.data.access_token;
                data.access_token = token;
                dispatch(loginFormSuccess(data));
                if(resp){
                    axios.get(
                        `${REACT_APP_AUTH}/${API_ME}`,
                        { headers: {
                                Authorization: `Bearer ${token}` ,
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        }
                    ).then(res => {
                        data.name = res.data.data.name;
                        dispatch(loginFormUpdate(data));
                        localStorage.setItem("authUser", JSON.stringify(data));
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }).catch(error => {
                dispatch(loginFormError(error))
            });
    }
}

export const logoutRequest = (history) => {
    return dispatch => {
        authApis.logout()
            .then(resp => {
                dispatch(logoutUser(history))
            }).catch(error => {
        });
    }
}

