import {
    ACTIVE_NAME_PAGES, ACTIVE_PAGE_BY_ID,
    ACTIVE_PAGES,
    ACTIVE_PAGES_IN_ROLES, ACTIVE_PAGES_IN_SHIFTS, ACTIVE_PAGES_IN_TAGS,
    CHOOSE_PAGE_IN_ROLES, CHOOSE_PAGE_IN_SHIFTS, CHOOSE_PAGE_IN_TAGS,
    GET_LIST_PAGE,
    GET_LIST_PAGE_ERROR,
    GET_LIST_PAGE_SUCCESS, HIDE_ARCHIVED, SHOW_ARCHIVED, TOTAL_NOT_REPLIED, TOTAL_REPLIED, UPDATE_NOT_REPLY
} from './constants';

const INIT_STATE = {
    listPages : [],
    totalNotReplied : 0,
    totalReplied : 0,
    showArchive : false,
};

const Page = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_PAGE:
            return {
                ...state,
                listPages: []
            };
        case GET_LIST_PAGE_SUCCESS:
            const {data} = action.payload;
            return {
                ...state,
                listPages: data
            };
        case GET_LIST_PAGE_ERROR:
            return {
                ...state,
                listPages: []
            };
        case ACTIVE_PAGES:
            const page_id = action.payload;
            return {
                ...state,
                page_id: page_id
            };

        case ACTIVE_NAME_PAGES:
            const name_page = action.payload;
            return {
                ...state,
                name_page: name_page
            };

        case ACTIVE_PAGES_IN_ROLES:
            return {
                ...state,
                page_id: action.payload.page_id,
                name_page: action.payload.name_page
            };

        case ACTIVE_PAGES_IN_TAGS:
            return {
                ...state,
                page_id: action.payload.page_id,
                name_page: action.payload.name_page
            };

        case ACTIVE_PAGES_IN_SHIFTS:
            return {
                ...state,
                page_id: action.payload.page_id,
                name_page: action.payload.name_page
            };

        case CHOOSE_PAGE_IN_ROLES:
            return {
                ...state,
                page_id: action.payload.page_id,
                name_page: action.payload.name_page
            };

        case CHOOSE_PAGE_IN_TAGS:
            return {
                ...state,
                page_id: action.payload.page_id,
                name_page: action.payload.name_page
            };

        case CHOOSE_PAGE_IN_SHIFTS:
            return {
                ...state,
                page_id: action.payload.page_id,
                name_page: action.payload.name_page
            };

        case UPDATE_NOT_REPLY:
            return {
                ...state,
                listPages: action.payload.data,
            };

        case TOTAL_NOT_REPLIED:
            return {
                ...state,
                totalNotReplied: action.payload.totalNotReplied,
            };

        case TOTAL_REPLIED:
            return {
                ...state,
                totalReplied: action.payload.totalReplied,
            };

        case ACTIVE_PAGE_BY_ID:
            return {
                ...state,
                page_id: action.payload.page_id,
            };

        case SHOW_ARCHIVED:
            return {
                ...state,
                showArchive: true,
            };

        case HIDE_ARCHIVED:
            return {
                ...state,
                showArchive: false,
            };

        default:
            return {...state};
    }
}

export default Page;
