import axiosClient from "../helpers/axiosClient";
import {API_MANAGERS, API_PAGES, API_USER, REACT_APP_CHAT, USERS} from "./constants";
import {combineUrlParams} from "./../helpers/index";

export const getUserInPage = (page_id, limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${USERS}`,
            {
                limit: limit,
            }
        )
    );
}

export const getMe = () => {
    return axiosClient.get(`${REACT_APP_CHAT}/${API_USER}`);
}

export const getUserFilterInPage = (page_id, with_shift_from, with_shift_to, limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${USERS}`,
            {
                with_shift_from: with_shift_from,
                with_shift_to: with_shift_to,
                limit: limit,
            }
        )
    );
}

export const getReportManagers = () => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_MANAGERS}`,
            {

            }
        )
    );
}

