import axiosClient from "../helpers/axiosClient";
import {combineUrlParams} from "./../helpers/index";
import {
    API_CHATS,
    API_CONVERSATIONS,
    API_CONVERSATIONS_TAGS,
    API_PAGES,
    CONVERSATIONS,
    CONVERSATIONS_TAGS,
    REACT_APP_CHAT,
} from "./constants";

export const getListConversations = (chat_id, before = null, sort = "-created_at", limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${CONVERSATIONS}`,
            {
                before: before,
                sort: sort,
                limit: limit,
            }
        )
    );
}

export const getListAllConversationsInPage = (page_id, rating = null , page = 1, sort = "-id", limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${CONVERSATIONS}`,
            {
                sort: sort,
                limit: limit,
                rating: rating,
                page: page,
            }
        )
    );
}

export const showConversations = (conversation_id, limit = 50) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_CONVERSATIONS}/${conversation_id}`,
            {
                limit: limit
            }
        )
    );
}

export const createConversations = (chat_id) => {
    return axiosClient.post(`${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${CONVERSATIONS}`);
}

export const addTagToConversations = (chat_id, tag_id) => {
    return axiosClient.post(`${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${CONVERSATIONS_TAGS}/${tag_id}`);
}

export const removeTagToConversations = (chat_id, tag_id) => {
    return axiosClient.put(`${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${CONVERSATIONS_TAGS}/${tag_id}`);
}

export const addTagToConversationsItem = (chat_id, tag_id, conversation_id) => {
    const data = {
        conversation_id: conversation_id
    }
    return axiosClient.post(`${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${CONVERSATIONS_TAGS}/${tag_id}`, data);
}

export const removeTagToConversationsItem = (chat_id, tag_id, conversation_id) => {
    const data = {
        conversation_id: conversation_id
    }
    return axiosClient.put(`${REACT_APP_CHAT}/${API_CHATS}/${chat_id}/${CONVERSATIONS_TAGS}/${tag_id}`, data);
}

export const createMultiConversations = (page_id, data) => {
    return axiosClient.post(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${CONVERSATIONS}`, data);
}

export const addTagToMultiChat = (chat_ids, tag_id) => {
    let data = {};
    data.chat_ids = chat_ids;
    return axiosClient.post(`${REACT_APP_CHAT}/${API_CONVERSATIONS_TAGS}/${tag_id}`, data);
}

export const removeTagToMultiChat = (chat_ids, tag_id) => {
    let data = {};
    data.chat_ids = chat_ids;
    return axiosClient.put(`${REACT_APP_CHAT}/${API_CONVERSATIONS_TAGS}/${tag_id}`, data);
}

