import {axiosFromDataAuthClient} from "../helpers/axiosFromDataAuthClient";
import axiosClient from "../helpers/axiosClient";
import {
    API_LOGOUT,
    OAUTH_TOKEN,
    OAUTH_TOKEN_GOOGLE,
    REACT_APP_AUTH,
} from "./constants";
import {combineUrlParams} from "../helpers";

export const sendTokenGoogle = (data) => {

    const formData = new FormData();
    data.id && formData.append("id", data.id);
    data.name && formData.append("name", data.name);
    data.email && formData.append("email", data.email);
    data.tokenId && formData.append("id_token", data.tokenId);
    data.accessToken && formData.append("access_token", data.accessToken);

    return axiosFromDataAuthClient.post(`${REACT_APP_AUTH}/${OAUTH_TOKEN_GOOGLE}`, formData);
}

export const loginForm = (data) => {

    const formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("client_id", process.env.REACT_APP_AUTH_CLIENT_ID);
    formData.append("client_secret", process.env.REACT_APP_AUTH_CLIENT_SECRET);
    data.username && formData.append("username", data.username);
    data.password && formData.append("password", data.password);

    return axiosFromDataAuthClient.post(`${REACT_APP_AUTH}/${OAUTH_TOKEN}`, formData);
}

export const logout = () => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_AUTH}/${API_LOGOUT}`
        )
    );
}
