import axios from "axios";
import {configureStore} from '../redux/store';
import {toast} from 'react-toastify';
import {handleCheckOneChat} from "./index";

class axiosClient {
    constructor() {
        const instance = axios.create();
        instance.interceptors.response.use(this.handleSuccess, this.handleError);
        instance.interceptors.request.use(
            async config => {
                let state = configureStore().getState();
                let user = state.Auth.user || {};
                if (user.access_token) {
                    const decodedJwt = this.parseJwt(user.access_token);
                    if (decodedJwt.exp * 1000 < Date.now()) {
                        window.location.replace("/logout-expired");
                    } else {
                        config.headers = {
                            'Authorization': `Bearer ${user.access_token}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                        return config;
                    }
                }
            },
            error => {
                Promise.reject(error)
            });
        this.instance = instance;
    }

    parseJwt(token) {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    handleSuccess(response) {
        return response;
    }

    handleError(error) {
        switch (error.response.status) {
            case 400:
                toast.error(error.response.data.message);
                break;
            case 401:
                // toast.error(error.response.data.message);
                window.location.replace("/logout-expired");
                break;
            case 404:
                if (handleCheckOneChat()) {
                    window.location.replace("/not-found");
                } else {
                    toast.error(error.response.data.message);
                }
                break;
            case 403:
                if (error.response.config.method === 'get') {
                    window.location.replace("/dashboard");
                } else {
                    toast.error(error.response.data.message);
                }
                break;
            case 500:
                toast.error(error.response.data.message);
                break;
            default:
                toast.error(error.response.data.message);
        }
        return Promise.reject(error);
    }

    get(url) {
        return this.instance.get(url);
    }

    post(url, data = null) {
        return this.instance.post(url, data);
    }

    put(url, data = null) {
        return this.instance.put(url, data);
    }

    delete(url, data = null) {
        return this.instance.delete(url, data);
    }

}

export default new axiosClient();
