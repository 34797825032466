import {
    GET_LIST_USER_FILTER_SHIFT_IN_PAGE_SUCCESS,
    GET_LIST_USER_IN_PAGE,
    GET_LIST_USER_IN_PAGE_SUCCESS, SAVE_DATA_ME, UPDATE_LIST_USER_FILTER_SHIFT_IN_PAGE,
    UPDATE_LIST_USER_IN_PAGE
} from './constants';

import * as userApis from "./../../apis/user";
import {toast} from "react-toastify";
import {
    saveEndDateFilterSuccess,
    saveFromDateFilterSuccess,
    updateEndDateFilterSuccess,
    updateFromDateFilterSuccess, updateListDateFilterRequest, updateListDayFilterRequest
} from "../shift/actions";
import {getDifferenceInDays , toTimestamp} from "../../helpers";
import moment from "moment";

export const getListUserInPage = () => ({
    type: GET_LIST_USER_IN_PAGE,
    payload: {}
});

export const getListUserInPageSuccess = (listUser) => ({
    type: GET_LIST_USER_IN_PAGE_SUCCESS,
    payload: {
        listUser : listUser,
    }
});

export const updateListUserInPageSuccess = (listUser) => ({
    type: UPDATE_LIST_USER_IN_PAGE,
    payload: {
        listUser : listUser,
    }
});

export const getListUserFilterShiftInPageSuccess = (listUserFilterInShift) => ({
    type: GET_LIST_USER_FILTER_SHIFT_IN_PAGE_SUCCESS,
    payload: {
        listUserFilterInShift : listUserFilterInShift,
    }
});

export const updateListUserFilterShiftInPageSuccess = (listUserFilterInShift) => ({
    type: UPDATE_LIST_USER_FILTER_SHIFT_IN_PAGE,
    payload: {
        listUserFilterInShift : listUserFilterInShift,
    }
});

export const saveDataMeSuccess = (dataMe) => ({
    type: SAVE_DATA_ME,
    payload: {
        dataMe : dataMe,
    }
});

export const getListUserInPageRequest = (page_id) => {
    return dispatch => {
        dispatch(getListUserInPage());
        userApis.getUserInPage(page_id)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListUserInPageSuccess(data));
            }).catch(error => {

            });
    }
}

export const getListUserFilterShiftInPageRequest = (with_shift_from , with_shift_to) => {
    return (dispatch, getState) => {
        dispatch(saveFromDateFilterSuccess(with_shift_from));
        dispatch(saveEndDateFilterSuccess(with_shift_to));
        let page_id = getState().Page.page_id || null;
        page_id && with_shift_from && with_shift_to && userApis.getUserFilterInPage(page_id , with_shift_from , with_shift_to)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListUserFilterShiftInPageSuccess(data));
            }).catch(error => {
        });
    }
}

export const checkGetListUserFilterShiftInPageRequest = (start_date , end_date) => {
    return (dispatch, getState) => {
        let diff = getDifferenceInDays(start_date , end_date);
        let page_id = getState().Page.page_id;
        let start_old = getState().Shift.fromDateFilter;
        let end_old = getState().Shift.endDateFilter;
        start_date && dispatch(updateFromDateFilterSuccess(toTimestamp(start_date , 'start')));
        end_date && dispatch(updateEndDateFilterSuccess(toTimestamp(end_date , 'end')));
        if(start_date && end_date){
            if(diff <= 30){
                dispatch(updateListDateFilterRequest(start_date , end_date));
                dispatch(updateListDayFilterRequest(start_date , end_date));
                page_id && userApis.getUserFilterInPage(page_id , toTimestamp(start_date , 'start') , toTimestamp(end_date , 'end'))
                    .then(resp => {
                        const {data} = resp.data.data;
                        dispatch(getListUserFilterShiftInPageSuccess(data));
                    }).catch(error => {
                });
            }else{
                toast.error('Invalid period!');
                dispatch(updateFromDateFilterSuccess(start_old));
                dispatch(updateEndDateFilterSuccess(end_old));
            }
        }
    }
}

export const checkGetListUserFilterShiftInPageIOSRequest = (start_date , end_date) => {
    return (dispatch, getState) => {
        let diff = getDifferenceInDays(start_date , end_date);
        console.log(diff)
        let page_id = getState().Page.page_id;
        let start_old = getState().Shift.fromDateFilter;
        let end_old = getState().Shift.endDateFilter;
        let start = moment(start_date, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({hour:0,minute:0,second:0,millisecond:0}).format("x")/1000;
        let end = moment(end_date, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({hour:23,minute:59,second:59,millisecond:0}).format("x")/1000;
        console.log(start)
        console.log(end)
        start && dispatch(updateFromDateFilterSuccess(start));
        end && dispatch(updateEndDateFilterSuccess(end));
        if(start_date && end_date && start && end){
            if(diff <= 30){
                dispatch(updateListDateFilterRequest(start_date , end_date));
                dispatch(updateListDayFilterRequest(start_date , end_date));
                page_id && userApis.getUserFilterInPage(page_id , start , end)
                    .then(resp => {
                        const {data} = resp.data.data;
                        dispatch(getListUserFilterShiftInPageSuccess(data));
                    }).catch(error => {
                });
            }else{
                toast.error('Invalid period!');
                dispatch(updateFromDateFilterSuccess(start_old));
                dispatch(updateEndDateFilterSuccess(end_old));
            }
        }
    }
}


