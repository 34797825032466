export const GET_LIST_TAG = "GET_LIST_TAG";
export const GET_LIST_TAG_SUCCESS = "GET_LIST_TAG_SUCCESS";
export const GET_LIST_TAG_ERROR = "GET_LIST_TAG_ERROR";
export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_ERROR = "CREATE_TAG_ERROR";
export const ADD_TAG_TO_CONVERSATION = "ADD_TAG_TO_CONVERSATION";
export const ADD_TAG_TO_CONVERSATION_SUCCESS = "ADD_TAG_TO_CONVERSATION_SUCCESS";
export const ADD_TAG_TO_CONVERSATION_ERROR = "ADD_TAG_TO_CONVERSATION_ERROR";
export const GET_LIST_TAG_IN_CONVERSATION = "GET_LIST_TAG_IN_CONVERSATION";
export const GET_LIST_TAG_IN_CONVERSATION_SUCCESS = "GET_LIST_TAG_IN_CONVERSATION_SUCCESS";
export const GET_LIST_TAG_IN_CONVERSATION_ERROR = "GET_LIST_TAG_IN_CONVERSATION_ERROR";
export const REMOVE_TAG_IN_CONVERSATION_SUCCESS = "REMOVE_TAG_IN_CONVERSATION_SUCCESS";
export const REMOVE_TAG_SUCCESS = "REMOVE_TAG_SUCCESS";
export const UPDATE_LIST_TAGS = "UPDATE_LIST_TAGS";
export const SAVE_OBJ_TAG_IN_CONVERSATION = "SAVE_OBJ_TAG_IN_CONVERSATION";
export const LIST_OBJ_TAG_IN_CONVERSATION = "LIST_OBJ_TAG_IN_CONVERSATION";
export const SAVE_LIST_TAG_IN_CONVERSATION_SUCCESS = "SAVE_LIST_TAG_IN_CONVERSATION_SUCCESS";
export const UPDATE_LIST_TAG_IN_CONVERSATION_SUCCESS = "UPDATE_LIST_TAG_IN_CONVERSATION_SUCCESS";
export const UPDATE_OBJ_TAG_IN_CONVERSATION_SUCCESS = "UPDATE_OBJ_TAG_IN_CONVERSATION_SUCCESS";
export const UPDATE_OBJ_TAG_IN_CONVERSATION = "UPDATE_OBJ_TAG_IN_CONVERSATION";
export const UPDATE_LIST_TAG_IN_CONVERSATION = "UPDATE_LIST_TAG_IN_CONVERSATION";
export const CHECK_TAG_UPDATE_LIST_TAG_IN_CONVERSATION = "CHECK_TAG_UPDATE_LIST_TAG_IN_CONVERSATION";
export const CHECK_TAG_UPDATE_OBJ_TAG_IN_CONVERSATION = "CHECK_TAG_UPDATE_OBJ_TAG_IN_CONVERSATION";
export const CHECK_TAG_UPDATE_IN_CONVERSATION_SUCCESS = "CHECK_TAG_UPDATE_IN_CONVERSATION_SUCCESS";
export const UPDATE_LIST_TAGS_REMOVE = "UPDATE_LIST_TAGS_REMOVE";
export const UPDATE_LIST_TAGS_ITEM = "UPDATE_LIST_TAGS_ITEM";
export const SAVE_OBJ_TAG_IN_CONVERSATION_ITEM = "SAVE_OBJ_TAG_IN_CONVERSATION_ITEM";
export const SAVE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS = "SAVE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS";
export const UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM = "UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM";
export const UPDATE_LIST_TAG_IN_CONVERSATION_ITEM = "UPDATE_LIST_TAG_IN_CONVERSATION_ITEM";
export const UPDATE_LIST_TAGS_ITEM_REMOVE = "UPDATE_LIST_TAGS_ITEM_REMOVE";
export const UPDATE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS = "UPDATE_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS";
export const UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM_SUCCESS = "UPDATE_OBJ_TAG_IN_CONVERSATION_ITEM_SUCCESS";
export const CHECK_TAG_UPDATE_IN_CONVERSATION_ITEM = "CHECK_TAG_UPDATE_IN_CONVERSATION_ITEM";
export const GET_LIST_TAG_IN_CONVERSATION_ITEM = "GET_LIST_TAG_IN_CONVERSATION_ITEM";
export const LIST_OBJ_TAG_IN_CONVERSATION_ITEM = "LIST_OBJ_TAG_IN_CONVERSATION_ITEM";
export const GET_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS = "GET_LIST_TAG_IN_CONVERSATION_ITEM_SUCCESS";
export const GET_LIST_TAG_SIDEBAR = "GET_LIST_TAG_SIDEBAR";
export const GET_LIST_TAG_SIDEBAR_SUCCESS = "GET_LIST_TAG_SIDEBAR_SUCCESS";
export const REMOVE_TAG_SIDEBAR_SUCCESS = "REMOVE_TAG_SIDEBAR_SUCCESS";
export const UPDATE_TAG_SIDEBAR_SUCCESS = "UPDATE_TAG_SIDEBAR_SUCCESS";
export const GET_PAGINATION_TAG_SUCCESS = "GET_PAGINATION_TAG_SUCCESS";
export const RESET_PAGE_PAGINATION_TAG = "RESET_PAGE_PAGINATION_TAG";
export const GET_FULL_LIST_TAG_SUCCESS = "GET_FULL_LIST_TAG_SUCCESS";

export const UPDATE_LIST_TAGS_AFTER_DONE = "UPDATE_LIST_TAGS_AFTER_DONE";
export const UPDATE_LIST_OBJ_TAG_IN_CONVERSATION_AFTER_DONE = "UPDATE_LIST_OBJ_TAG_IN_CONVERSATION_AFTER_DONE";
export const UPDATE_LIST_TAG_IN_CONVERSATION_AFTER_DONE = "UPDATE_LIST_TAG_IN_CONVERSATION_AFTER_DONE";


