import {
    ACTIVE_CHAT,
    ACTIVE_CHAT_BY_ID,
    ACTIVE_FB_USER_ID,
    ACTIVE_INFO,
    ACTIVE_USER,
    ADD_ALL_CHECKED_TO_CHAT,
    ADD_CHECKED_TO_CHAT,
    ADD_LOGGED_USER,
    ASSIGN_CHAT_SUCCESS,
    CHANGE_HEIGHT_INPUT_CHAT,
    CHANGE_HEIGHT_INPUT_CHAT_MOBILE,
    CHAT_USER,
    CHECK_STATUS_NAME_NEW_MESSAGE,
    CLEAR_FILTER_SEARCH_CHAT,
    CREATE_GROUP,
    DONE_CONVERSATION,
    FILTER_SEARCH_CHAT,
    FULL_USER,
    GET_LIST_CHAT,
    GET_LIST_CHAT_ERROR,
    GET_LIST_CHAT_SUCCESS,
    MARK_READ_CHAT_SUCCESS,
    REMOVE_ALL_CHECKED_TO_CHAT,
    REMOVE_CHECKED_TO_CHAT,
    RESET_CHECKED_TO_CHAT,
    SAVE_NAME_FILTER_CHAT,
    SET_OPTION_FILTER,
    SET_STATUS_LIST_CHAT,
    UPDATE_CHAT_AFTER_DONE_CONVERSATION,
    UPDATE_CHAT_AFTER_DONE_MULTI_CONVERSATION,
    UPDATE_LAST_MESSAGE_IN_CHAT,
    UPDATE_LIST_CHAT_WHEN_NEW_MESSAGE,
    UPDATE_LIST_CHATS_AFTER_ASSIGN,
    UPDATE_PAGE_LOAD_MORE_CHAT
} from './constants';

import * as chatApis from "./../../apis/chat";
import {toast} from "react-toastify";
import {getNewMessageSuccess} from "../message/actions";
import {getFullListTagRequest, getListTagInConversationRequest, getListTagRequest} from "../tag/actions";
import {getListConversationRequest, updateStatusConversationRequest} from "../conversation/actions";
import * as messageApis from "../../apis/message";
import {getListQuickReplyRequest} from "../quickReply/actions";
import {getListUserInPageRequest} from "../user/actions";

export const chatUser = () => ({
    type: CHAT_USER
});

export const activeUser = (userId) => ({
    type: ACTIVE_USER,
    payload: userId
});

export const setFullUser = (fullUser) => ({
    type: FULL_USER,
    payload: fullUser
});

export const addLoggedinUser = (userData) => ({
    type: ADD_LOGGED_USER,
    payload: userData
});

export const createGroup = (groupData) => ({
    type: CREATE_GROUP,
    payload: groupData
})

export const getListChat = (page_id) => ({
    type: GET_LIST_CHAT,
    payload: page_id
});

export const getListChatSuccess = (data) => ({
    type: GET_LIST_CHAT_SUCCESS,
    payload: {
        data
    }
});

export const getListChatError = (error) => ({
    type: GET_LIST_CHAT_ERROR,
    payload: {
        error
    }
});

export const activeChat = (chat_id) => ({
    type: ACTIVE_CHAT,
    payload: chat_id
});

export const activeFbUserId = (fb_user_id) => ({
    type: ACTIVE_FB_USER_ID,
    payload: fb_user_id
});

export const activeInfo = (info) => ({
    type: ACTIVE_INFO,
    payload: {
        info
    }
});

export const updateLastMessageListChat = (listChats) => ({
    type: UPDATE_LAST_MESSAGE_IN_CHAT,
    payload: {
        listChats: listChats
    }
});

export const updateListChatWhenNewMessageSuccess = (obj_update) => ({
    type: UPDATE_LIST_CHAT_WHEN_NEW_MESSAGE,
    payload: {
        obj_update
    }
});

export const checkStatusNameWhenNewMessageSuccess = (obj_update) => ({
    type: CHECK_STATUS_NAME_NEW_MESSAGE,
    payload: {
        obj_update
    }
});

export const setStatusListChatSuccess = (statusName) => ({
    type: SET_STATUS_LIST_CHAT,
    payload: {
        statusName
    }
});

export const doneConversationSuccess = (listChats) => ({
    type: DONE_CONVERSATION,
    payload: {
        listChats
    }
});

export const setOptionFilterSuccess = (filterChat) => ({
    type: SET_OPTION_FILTER,
    payload: {
        filterChat: filterChat
    }
});

export const filterSearchChatSuccess = (listChats) => ({
    type: FILTER_SEARCH_CHAT,
    payload: {
        listChats
    }
});

export const clearFilterSearchChatSuccess = () => ({
    type: CLEAR_FILTER_SEARCH_CHAT,
    payload: {}
});

export const assignChatSuccess = (listChats) => ({
    type: ASSIGN_CHAT_SUCCESS,
    payload: {
        listChats: listChats
    }
});

export const markReadSuccess = (listChats) => ({
    type: MARK_READ_CHAT_SUCCESS,
    payload: {
        listChats: listChats
    }
});

export const updateChatAfterDoneMultiConv = (listChats) => ({
    type: UPDATE_CHAT_AFTER_DONE_MULTI_CONVERSATION,
    payload: {
        listChats: listChats
    }
});

export const updatePageLoadMoreChat = (data) => ({
    type: UPDATE_PAGE_LOAD_MORE_CHAT,
    payload: {
        data: data
    }
});

export const updateChatAfterDoneConversation = (data) => ({
    type: UPDATE_CHAT_AFTER_DONE_CONVERSATION,
    payload: {
        listChats: data
    }
});

export const addCheckedToChatSuccess = (listChecked) => ({
    type: ADD_CHECKED_TO_CHAT,
    payload: {
        listChecked: listChecked
    }
});

export const addAllCheckedToChatSuccess = (listChecked) => ({
    type: ADD_ALL_CHECKED_TO_CHAT,
    payload: {
        listChecked: listChecked
    }
});

export const removeCheckedToChatSuccess = (listChecked) => ({
    type: REMOVE_CHECKED_TO_CHAT,
    payload: {
        listChecked: listChecked
    }
});

export const removeAllCheckedToChatSuccess = () => ({
    type: REMOVE_ALL_CHECKED_TO_CHAT,
    payload: {}
});

export const resetCheckedToChatSuccess = () => ({
    type: RESET_CHECKED_TO_CHAT,
    payload: {}
});

export const updateListChatsAfterAssignSuccess = (listChats) => ({
    type: UPDATE_LIST_CHATS_AFTER_ASSIGN,
    payload: {
        listChats: listChats
    }
});

export const saveNameFilterChatSuccess = (filterChat) => ({
    type: SAVE_NAME_FILTER_CHAT,
    payload: {
        filterChat: filterChat
    }
});

export const changeHeightInputChat = (scrollHeightStore) => ({
    type: CHANGE_HEIGHT_INPUT_CHAT,
    payload: {
        scrollHeightStore: scrollHeightStore
    }
});

export const changeHeightInputChatMobile = (scrollHeightStoreMobile) => ({
    type: CHANGE_HEIGHT_INPUT_CHAT_MOBILE,
    payload: {
        scrollHeightStoreMobile: scrollHeightStoreMobile
    }
});

export const activeChatById = (chat_id) => ({
    type: ACTIVE_CHAT_BY_ID,
    payload: {
        chat_id: chat_id
    }
});

export const saveNameFilterChatRequest = (textSearch) => {
    return (dispatch, getState) => {
        let filterChat = getState().Chat.filterChat;
        filterChat.textSearch = textSearch;
        dispatch(saveNameFilterChatSuccess(filterChat));
    }
}

export const getListChatRequest = (page_id, status = null) => {
    return dispatch => {
        dispatch(getListChat(page_id));
        chatApis.getListChat(page_id, status)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListChatSuccess(data))
            }).catch(error => {
            dispatch(getListChatError(error));
        });
    }
}

// export const getChatByChatIdRequest = (page_id, chat_id, status = null) => {
//     return dispatch => {
//         dispatch(getListChat(page_id));
//         chatApis.getListChat(page_id, status)
//             .then(resp => {
//                 const {data} = resp.data.data;
//                 let data_new = [];
//                 data_new.push(data.find(el => (el._id === chat_id)))
//                 if (data_new.length === 0 || data_new[0] === undefined) {
//                     window.location.replace("/not-found")
//                 } else {
//                     dispatch(getListChatSuccess(data_new))
//                 }
//             }).catch(error => {
//             dispatch(getListChatError(error));
//         });
//     }
// }

export const getChatByChatIdRequest = (page_id, chat_id) => {
    return dispatch => {
        let before = new Date().getTime();
        messageApis.getListMessage(chat_id, before)
            .then(resp => {
                let {chat} = resp.data.data;
                let {code} = resp.data;
                if (code === 0) {
                    let data_new = [];
                    data_new.push(chat);
                    dispatch(getListChatSuccess(data_new));
                    dispatch(getListQuickReplyRequest(page_id));
                    dispatch(getListUserInPageRequest(page_id));
                    dispatch(getFullListTagRequest(page_id));
                }
            }).catch(error => {
            console.log(error);
        });
    }
}

export const newMessageRequest = (newMessage) => {
    return (dispatch, getState) => {
        var chat_id = getState().Chat.chat_id;
        var listPages = getState().Page.listPages;
        var page_id = getState().Page.page_id;
        var findPage = listPages.findIndex((obj => obj._id === page_id));
        var page = listPages[findPage].page_id;
        let url = new URL(window.location.href);
        let pageId = url.searchParams.get("pageId") ? url.searchParams.get("pageId") : null;
        let chatId = url.searchParams.get("chatId") ? url.searchParams.get("chatId") : null;
        if (page === newMessage.chat.page_id) {
            if (chat_id === newMessage.chat._id) {
                var listMessages = getState().Message.listMessages;
                var listMessagesNew = listMessages ? [...listMessages] : [];
                var rs = listMessagesNew.find(message => message._id === newMessage.message._id);
                var listMessagesUpdate = null;
                if (!rs) {
                    listMessagesUpdate = [...listMessagesNew, newMessage.message];
                } else {
                    listMessagesUpdate = [...listMessagesNew];
                }
                dispatch(getNewMessageSuccess(listMessagesUpdate));
            }
            //////////////////////////////////////////////////////////////////////////////////
            var assignee_id = newMessage.chat.assignee_id && newMessage.chat.assignee_id || null;
            var status_name = newMessage.chat.status_name && newMessage.chat.status_name || null;

            var state_assignee = getState().Chat.filterChat.assign && getState().Chat.filterChat.assign.value || assignee_id;
            var state_status = getState().Chat.filterChat.status && getState().Chat.filterChat.status.value || status_name;
            var state_sort = getState().Chat.filterChat.sort && getState().Chat.filterChat.sort.value || null;
            var state_tags = getState().Chat.filterChat.tags && getState().Chat.filterChat.tags || null;
            var state_textSearch = getState().Chat.filterChat.textSearch && getState().Chat.filterChat.textSearch || null;

            ///////////////////////////////////////////////check filter////////////////////////////////////////////
            var arr_tag = [];
            if (state_tags) {
                state_tags.forEach((e) => {
                    arr_tag.push(e.value);
                });
            }

            if (newMessage.chat.last_conversation.tags) {
                var arr_tag_new_message = [];
                newMessage.chat.last_conversation.tags.forEach((e) => {
                    arr_tag_new_message.push(e.id);
                });
            }

            var arr_tag_check = arr_tag.length === 0 ? arr_tag_new_message : arr_tag;

            var listChatsFirst = [...getState().Chat.listChats];
            var listChats = [...listChatsFirst];

            //remove chat in list
            var listChatsRemoveItem = listChats.filter(obj => obj._id !== newMessage.chat._id);
            //add new message to list
            var listChatUpdate = [];
            // var listChatUpdate = [newMessage.chat , ...listChatsRemoveItem];

            //thieu check theo name search

            if (!pageId && !chatId) {
                if (state_assignee || state_status || state_tags || state_sort || state_textSearch) {
                    var checker = (arr, target) => target.every(v => arr.includes(v));
                    if (arr_tag_new_message && arr_tag && checker(arr_tag_new_message, arr_tag)) {
                        if (state_textSearch) {
                            let temp = newMessage.chat.fb_user_name.toUpperCase();
                            if (temp.includes(state_textSearch.toUpperCase()) && state_assignee === assignee_id && status_name === state_status && (state_sort && state_sort === '-last_message_at' || !state_sort)) {
                                listChatUpdate = [newMessage.chat, ...listChatsRemoveItem];
                                dispatch(updateLastMessageListChat(listChatUpdate));
                            } else if (state_textSearch.toUpperCase() === newMessage.chat.fb_user_name.toUpperCase() && state_assignee === assignee_id && status_name === state_status && (state_sort && state_sort === 'last_message_at')) {
                                listChatUpdate = [...listChatsRemoveItem, newMessage.chat];
                                dispatch(updateLastMessageListChat(listChatUpdate));
                            } else {
                                dispatch(updateLastMessageListChat(listChatsRemoveItem));
                            }
                        } else {
                            if (state_assignee === assignee_id && status_name === state_status && (state_sort && state_sort === '-last_message_at' || !state_sort)) {
                                listChatUpdate = [newMessage.chat, ...listChatsRemoveItem];
                                dispatch(updateLastMessageListChat(listChatUpdate));
                            } else if (state_assignee === assignee_id && status_name === state_status && (state_sort && state_sort === 'last_message_at')) {
                                listChatUpdate = [...listChatsRemoveItem, newMessage.chat];
                                dispatch(updateLastMessageListChat(listChatUpdate));
                            } else {
                                dispatch(updateLastMessageListChat(listChatsRemoveItem));
                            }
                        }

                    } else {
                        if (state_textSearch) {
                            let temp = newMessage.chat.fb_user_name.toUpperCase();
                            if (temp.includes(state_textSearch.toUpperCase()) && arr_tag_new_message === arr_tag_check && state_assignee === assignee_id && status_name === state_status && (state_sort && state_sort === '-last_message_at' || !state_sort)) {
                                listChatUpdate = [newMessage.chat, ...listChatsRemoveItem];
                                dispatch(updateLastMessageListChat(listChatUpdate));
                            }
                            if (state_textSearch.toUpperCase() === newMessage.chat.fb_user_name.toUpperCase() && arr_tag_new_message === arr_tag_check && state_assignee === assignee_id && status_name === state_status && (state_sort && state_sort === 'last_message_at')) {
                                listChatUpdate = [newMessage.chat, ...listChatsRemoveItem];
                                dispatch(updateLastMessageListChat(listChatUpdate));
                            } else {
                                dispatch(updateLastMessageListChat(listChatsRemoveItem));
                            }
                        } else {
                            if (arr_tag_new_message === arr_tag_check && state_assignee === assignee_id && status_name === state_status && (state_sort && state_sort === '-last_message_at' || !state_sort)) {
                                listChatUpdate = [newMessage.chat, ...listChatsRemoveItem];
                                dispatch(updateLastMessageListChat(listChatUpdate));
                            }
                            if (arr_tag_new_message === arr_tag_check && state_assignee === assignee_id && status_name === state_status && (state_sort && state_sort === 'last_message_at')) {
                                listChatUpdate = [newMessage.chat, ...listChatsRemoveItem];
                                dispatch(updateLastMessageListChat(listChatUpdate));
                            } else {
                                dispatch(updateLastMessageListChat(listChatsRemoveItem));
                            }
                        }
                    }

                } else {
                    listChatUpdate = [newMessage.chat, ...listChatsRemoveItem];
                    dispatch(updateLastMessageListChat(listChatUpdate));
                }
            } else if (pageId && chatId) {
                if(newMessage.chat._id === chatId){
                    listChatUpdate = [newMessage.chat, ...listChatsRemoveItem];
                    dispatch(updateLastMessageListChat(listChatUpdate));
                }
            }

            //update status conversation
            if (chat_id === newMessage.chat._id) {
                if (newMessage.chat.last_conversation.id && newMessage.chat.last_conversation.status_name) {
                    let id = newMessage.chat.last_conversation.id;
                    let status = newMessage.chat.last_conversation.status;
                    let status_name = newMessage.chat.last_conversation.status_name;
                    let conversationNew = newMessage.chat.last_conversation;
                    conversationNew.assignee = newMessage.chat.assignee && newMessage.chat.assignee;
                    dispatch(updateStatusConversationRequest(id, status, status_name, conversationNew));
                }
            }


            //neu tn moi ton tai trong danh sach ko cho len dau
            // var result = listChats.findIndex((obj => obj._id === newMessage.chat._id));
            // var listChatUpdate = null;
            // if(result === -1){
            //     listChatUpdate = [newMessage.chat , ...listChats];
            // }else{
            //     listChats[result] = newMessage.chat;
            //     listChatUpdate = listChats;
            // }

        }
    }
}

export const setStatusListChatRequest = (statusName) => {
    return dispatch => {
        dispatch(setStatusListChatSuccess(statusName));
    }
}

export const doneConversationRequest = () => {
    return (dispatch, getState) => {
        var listChats = getState().Chat.listChats;
        var statusName = getState().Chat.statusName;
        var chat_id = getState().Chat.chat_id;
        var lists = [...listChats];
        var objIndex = lists.findIndex((obj => obj._id === chat_id));
        if (objIndex !== -1 && statusName !== 'DONE') {
            lists.splice([objIndex], 1);
        }
        dispatch(doneConversationSuccess(lists));
    }
}


export const setOptionFilterRequest = (assign, tags, status, sort) => {
    return dispatch => {
        const filterChat = {
            sort: sort,
            status: status,
            assign: assign,
            tags: tags
        }
        dispatch(setOptionFilterSuccess(filterChat));
    }
}

export const filterSearchChatRequest = (name, page) => {
    return (dispatch, getState) => {
        var page_id = getState().Page.page_id;
        var assignee_id = getState().Chat.filterChat.assign && getState().Chat.filterChat.assign.value;
        var tags = getState().Chat.filterChat.tags && getState().Chat.filterChat.tags;
        var status = getState().Chat.filterChat.status && getState().Chat.filterChat.status.value;
        var sort = getState().Chat.filterChat.sort && getState().Chat.filterChat.sort.value;
        chatApis.getListChatFilter(page_id, name, assignee_id, tags, status, page, sort)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(filterSearchChatSuccess(data))
            }).catch(error => {

        });
    }
}

export const clearFilterSearchChatRequest = () => {
    return (dispatch, getState) => {
        var page_id = getState().Page.page_id;
        var status = null;
        toast.success('Clear filter chat success!');
        dispatch(clearFilterSearchChatSuccess());
        dispatch(getListChat(page_id));
        chatApis.getListChat(page_id, status)
            .then(resp => {
                const {data} = resp.data.data;
                dispatch(getListChatSuccess(data))
            }).catch(error => {
            dispatch(getListChatError(error));
        });
    }
}

export const assignChatToUserRequest = (user_id) => {
    return (dispatch, getState) => {
        var chat_id = getState().Chat.chat_id;
        var listChats = getState().Chat.listChats;
        var listChatsNew = [...listChats];
        chatApis.assignChatToUser(chat_id, user_id)
            .then(resp => {
                const code = resp.data.code;
                const data = resp.data.data;
                if (code === 0) {
                    var index = listChatsNew.findIndex(chat => chat._id === data.chat_id);
                    listChatsNew[index].assignee = data.assignee;
                    dispatch(assignChatSuccess(listChatsNew));
                    toast.success('Assign chat to user success!');
                }

            }).catch(error => {

        });
    }
}

export const markReadRequest = () => {
    return (dispatch, getState) => {
        var chat_id = getState().Chat.chat_id;
        var listChats = getState().Chat.listChats;
        var listChatsUpdate = [...listChats];
        chatApis.markRead(chat_id)
            .then(resp => {
                var code = resp.data.code;
                var data = resp.data.data;
                if (code === 0) {
                    var index = listChatsUpdate.findIndex(chat => chat._id === data._id);
                    listChatsUpdate[index].unread_message_count = data.unread_message_count;
                    dispatch(markReadSuccess(listChatsUpdate));
                    toast.success('Mark read success!');
                }
            }).catch(error => {

        });
    }
}

export const getMoreChatRequest = (page_id, page, name) => {
    return (dispatch, getState) => {
        var listChats = getState().Chat.listChats;
        var page_id = getState().Page.page_id;
        var assignee_id = getState().Chat.filterChat.assign && getState().Chat.filterChat.assign.value;
        var tags = getState().Chat.filterChat.tags && getState().Chat.filterChat.tags;
        var status = getState().Chat.filterChat.status && getState().Chat.filterChat.status.value;
        var sort = getState().Chat.filterChat.sort && getState().Chat.filterChat.sort.value;
        chatApis.getListChatFilter(page_id, name, assignee_id, tags, status, page, sort)
            // chatApis.getListChatScroll(page_id , page)
            .then(resp => {
                const {data} = resp.data.data;
                //check duplicate load more
                var listChatsUpdate = [...listChats].concat(data);
                dispatch(getListChatSuccess(listChatsUpdate));
            }).catch(error => {

        });
    }
}

export const addToListCheckedRequest = (chat_id) => {
    return (dispatch, getState) => {
        let listChecked = getState().Chat.listChecked;
        let listCheckedBefore = [...listChecked];
        listCheckedBefore.push(chat_id);
        let listCheckedUpdate = Array.from(new Set(listCheckedBefore));
        dispatch(addCheckedToChatSuccess(listCheckedUpdate));
    }
}

export const removeToListCheckedRequest = (chat_id) => {
    return (dispatch, getState) => {
        let listChecked = [...getState().Chat.listChecked];
        let listCheckedUpdate = listChecked.filter(function (ele) {
            return ele != chat_id;
        });
        listCheckedUpdate = [...new Set(listCheckedUpdate)];
        dispatch(removeCheckedToChatSuccess(listCheckedUpdate));
    }
}

export const addAllToListCheckedRequest = (listChecked) => {
    return (dispatch) => {
        dispatch(addAllCheckedToChatSuccess(listChecked));
    }
}

export const removeAllToListCheckedRequest = () => {
    return (dispatch) => {
        dispatch(removeAllCheckedToChatSuccess());
    }
}

export const resetListCheckedRequest = () => {
    return (dispatch) => {
        dispatch(resetCheckedToChatSuccess());
    }
}

export const assignUserToMultiChatRequest = (user_id) => {
    return (dispatch, getState) => {
        let chat_ids = getState().Chat.listChecked;
        let page_id = getState().Page.page_id;
        let chat_id = getState().Chat.chat_id;
        let listChats = getState().Chat.listChats;
        chatApis.assignMultiChatToUser(page_id, chat_ids, user_id)
            .then(resp => {
                if (resp.data.code === 0) {
                    toast.success("Assign user to conversation success!");
                    dispatch(removeAllCheckedToChatSuccess());
                    chat_id && dispatch(getListTagInConversationRequest(chat_id));
                    page_id && dispatch(getListTagRequest(page_id));
                    chat_id && dispatch(getListConversationRequest(chat_id));
                    let data = resp.data.data;
                    listChats = [...listChats];
                    let listChatsUpdate = listChats.map(obj => data.find(o => o._id === obj._id) || obj);
                    listChatsUpdate = listChatsUpdate.filter(function (obj) {
                        if (obj.last_conversation) {
                            obj.last_message = obj.last_conversation.last_message;
                        } else {
                            obj.last_message = obj.last_message;
                        }
                        return obj
                    });
                    listChatsUpdate && dispatch(updateListChatsAfterAssignSuccess(listChatsUpdate));
                }
            }).catch(error => {

        });
    }
}

