import {
    CLEAR_SEARCH_REPORT, SAVE_LIST_MANAGER_REPORT,
    SAVE_LIST_PAGE_REPORT,
    SAVE_LIST_USER_REPORT, SAVE_REPORT_DATA,
    SAVE_SEARCH_REPORT, UPDATE_LIST_MANAGER_REPORT,
    UPDATE_LIST_PAGE_REPORT,
    UPDATE_LIST_USER_REPORT,
    UPDATE_SEARCH_REPORT, UPDATE_USER_ID_SEARCH_REPORT
} from './constants';
import * as userApis from "../../apis/user";
import moment from "moment";
import * as reportApis from "../../apis/report";
import {convertDateFromTimeStampInReport, convertDateToTimeStampInReport} from "../../helpers";
import {
    isIOS,
} from "react-device-detect";

export const saveSearchReport = (searchReport) => ({
    type: SAVE_SEARCH_REPORT,
    payload: {
        searchReport : searchReport
    }
});

export const updateSearchReport = (searchReport) => ({
    type: UPDATE_SEARCH_REPORT,
    payload: {
        searchReport : searchReport
    }
});

export const saveListPageReport = (listPage) => ({
    type: SAVE_LIST_PAGE_REPORT,
    payload: {
        listPage : listPage
    }
});

export const updateListPageReport = (listPage) => ({
    type: UPDATE_LIST_PAGE_REPORT,
    payload: {
        listPage : listPage
    }
});

export const saveListManagerReport = (listManager) => ({
    type: SAVE_LIST_MANAGER_REPORT,
    payload: {
        listManager : listManager
    }
});

export const updateListManagerReport = (listManager) => ({
    type: UPDATE_LIST_MANAGER_REPORT,
    payload: {
        listManager : listManager
    }
});

export const saveListUserReport = (listUser) => ({
    type: SAVE_LIST_USER_REPORT,
    payload: {
        listUser : listUser
    }
});

export const updateListUserReport = (listUser) => ({
    type: UPDATE_LIST_USER_REPORT,
    payload: {
        listUser : listUser
    }
});

export const clearSearchReport = (listUser , searchReport) => ({
    type: CLEAR_SEARCH_REPORT,
    payload: {
        listUser : listUser,
        searchReport : searchReport
    }
});

export const saveReportData = (reportData) => ({
    type: SAVE_REPORT_DATA,
    payload: {
        reportData : reportData,
    }
});

export const updateUserIdSearchReport = (searchReport) => ({
    type: UPDATE_USER_ID_SEARCH_REPORT,
    payload: {
        searchReport : searchReport,
    }
});

export const updatePageSearchReportRequest = (page_id) => {
    return (dispatch , getState) => {
        let searchReport = getState().Report.searchReport;
        searchReport.page_id = (page_id !== "All") ? page_id : null;
        dispatch(updateSearchReport(searchReport));
        if(page_id !== "All"){
            userApis.getUserInPage(page_id)
                .then(resp => {
                    const {data} = resp.data.data;
                    let objAll = {
                        id : null ,
                        name : "All" ,
                    }
                    data.unshift(objAll);
                    dispatch(updateListUserReport(data));
                    searchReport.user_id = data[0].id;
                    dispatch(updateSearchReport(searchReport));
                }).catch(error => {
            });
        }else{
            searchReport.user_id = null;
            dispatch(updateSearchReport(searchReport));
            dispatch(updateListUserReport([]));
        }

        //update user_id to null after change page
        dispatch(updateUserIdSearchReportRequest());

    }
}

export const updateUserSearchReportRequest = (user_id) => {
    return (dispatch , getState) => {
        let searchReport = getState().Report.searchReport;
        searchReport.user_id = user_id ? parseInt(user_id) : null;
        dispatch(updateSearchReport(searchReport));
    }
}

export const updateManagerSearchReportRequest = (manager_id) => {
    return (dispatch , getState) => {
        let searchReport = getState().Report.searchReport;
        searchReport.manager_id = manager_id ? parseInt(manager_id) : null;
        dispatch(updateSearchReport(searchReport));
    }
}

export const updateReportBySearchReportRequest = (report_by) => {
    return (dispatch , getState) => {
        let searchReport = getState().Report.searchReport;
        searchReport.report_by = report_by ? report_by : null;
        dispatch(updateSearchReport(searchReport));

        if(report_by === "manager"){
            userApis.getReportManagers()
                .then(resp => {
                    const {data} = resp.data.data;
                    dispatch(updateListManagerReport(data));
                    searchReport.user_id = null;
                    searchReport.page_id = null;
                    searchReport.manager_id = data.length > 0 && data[0].id || null;
                    dispatch(updateSearchReport(searchReport));
                }).catch(error => {
            });
        }else if(report_by === "page"){
            searchReport.user_id = null;
            searchReport.page_id = null;
            searchReport.manager_id = null;
            dispatch(updateSearchReport(searchReport));
            dispatch(updateListManagerReport([]));
        }
    }
}

export const updateFromSearchReportRequest = (from) => {
    return (dispatch , getState) => {
        let searchReport = getState().Report.searchReport;
        searchReport.from = from ? from : null;
        dispatch(updateSearchReport(searchReport));
    }
}

export const updateToSearchReportRequest = (to) => {
    return (dispatch , getState) => {
        let searchReport = getState().Report.searchReport;
        searchReport.to = to ? to : null;
        dispatch(updateSearchReport(searchReport));
    }
}

export const updateUserIdSearchReportRequest = () => {
    return (dispatch , getState) => {
        let searchReport = getState().Report.searchReport;
        searchReport.user_id = null;
        dispatch(updateUserIdSearchReport(searchReport));
    }
}

export const clearSearchReportRequest = () => {
    return (dispatch) => {
        let searchReport = {
            from : moment().subtract(7,'d').format('YYYY-MM-DD'),
            to : moment().subtract(1,'d').format('YYYY-MM-DD'),
            page_id : null,
            user_id : null,
            report_by : 'page'
        }
        let listUser = [];
        dispatch(clearSearchReport(listUser , searchReport));
    }
}

export const handleSearchReport = (from , to) => {
    return (dispatch) => {
        let from_after = null;
        let to_after = null;
        if(isIOS){
            from_after = moment(from, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({hour:0,minute:0,second:0,millisecond:0}).format("x")/1000;
            to_after = moment(to, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({hour:23,minute:59,second:0,millisecond:0}).format("x")/1000;
        }else{
            from_after = convertDateFromTimeStampInReport(from);
            to_after = convertDateToTimeStampInReport(to);
        }

        reportApis.getReportDailyGeneral(from_after , to_after)
            .then(rs => {
                if(rs.data.code === 0){
                    let data_report = rs.data.data;
                    let total = 0;
                    let not_replied = 0;
                    let replied = 0;
                    let done = 0;
                    let late_reply = 0;
                    let dataTicket = [];
                    let dataOutOfDate = [];
                    let dataDetailTicket = [];
                    let dataDetailOutOfDate = [];
                    for(let key in data_report) {
                        total += data_report[key]?.conversations?.total || 0;
                        not_replied += data_report[key]?.conversations?.not_replied || 0;
                        replied += data_report[key]?.conversations?.replied || 0;
                        done += data_report[key]?.conversations?.done || 0;
                        let date = moment(key, 'YYYY-MM-DD').format('DD/MM');
                        let total_ticket = data_report[key]?.conversations?.total || 0;
                        let late_3_hours = data_report[key]?.conversations?.late_3_hours || 0;
                        let late_24_hours = data_report[key]?.conversations?.late_24_hours || 0;
                        late_reply += late_3_hours + late_24_hours;
                        dataTicket.push({date : date , conv : total_ticket});
                        dataOutOfDate.push({
                            date : date ,
                            total : total_ticket,
                            late_3_hours : late_3_hours,
                            late_24_hours : late_24_hours,
                        });
                        dataDetailTicket.push({
                            time : date,
                            total_conv : total_ticket,
                            new : data_report[key]?.conversations?.new || 0,
                            replied : data_report[key]?.conversations?.replied || 0,
                            not_reply : data_report[key]?.conversations?.not_replied || 0,
                            done : data_report[key]?.conversations?.done || 0,
                            late_reply : late_3_hours + late_24_hours,
                            total_mess_sent : data_report[key]?.messages?.total || 0,
                        });
                        let ratio_3_hours = (late_3_hours + late_24_hours) !== 0 ? (late_3_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "-";
                        let ratio_24_hours = (late_3_hours + late_24_hours) !== 0 ? (late_24_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "-";
                        dataDetailOutOfDate.push({
                            time : date,
                            total_conv : total_ticket,
                            total_late_conv : late_3_hours + late_24_hours,
                            late_3h : {
                                qty : late_3_hours,
                                ratio : ratio_3_hours
                            },
                            late_24h : {
                                qty : late_24_hours,
                                ratio : ratio_24_hours
                            }
                        });
                    }

                    let from_report = null;
                    let to_report = null;

                    if(isIOS){
                        from_report = moment(from, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
                        to_report = moment(to, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
                    }else{
                        from_report = moment(from, 'YYYY-MM-DD').format('DD/MM/YYYY');
                        to_report = moment(to, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    }

                    let reportData = {
                        head : {
                            username : null,
                            from : from_report,
                            to : to_report,
                            all : total,
                            not_reply : not_replied,
                            replied : replied,
                            done : done,
                            late_reply : late_reply,
                        },
                        dataTicket : dataTicket ,
                        dataOutOfDate : dataOutOfDate ,
                        dataBug : [] ,
                        dataDetailTicket : dataDetailTicket,
                        dataDetailOutOfDate : dataDetailOutOfDate,
                        dataDetailBug : [] ,
                    }
                    dispatch(saveReportData(reportData));
                }

            }).catch(err => {

        });

    }
}

export const handleSearchReportManager = (from , to , manager_id) => {
    return (dispatch) => {
        let from_after = null;
        let to_after = null;
        if(isIOS){
            from_after = moment(from, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({hour:0,minute:0,second:0,millisecond:0}).format("x")/1000;
            to_after = moment(to, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({hour:23,minute:59,second:0,millisecond:0}).format("x")/1000;
        }else{
            from_after = convertDateFromTimeStampInReport(from);
            to_after = convertDateToTimeStampInReport(to);
        }

        let data_report = {
            period : 'DAILY',
            from : from_after,
            to : to_after,
            user_id : manager_id
        }

        reportApis.getManagerAggregateReports(data_report)
            .then(rs => {
                if(rs.data.code === 0){
                    let data_report = rs.data.data.added_up;
                    let data_report_by_page = rs.data.data.page_detail;
                    let total = 0;
                    let not_replied = 0;
                    let replied = 0;
                    let done = 0;
                    let late_reply = 0;

                    let total_by_page = 0;
                    let not_replied_by_page = 0;
                    let replied_by_page = 0;
                    let done_by_page = 0;
                    let late_reply_by_page = 0;

                    let dataTicket = [];
                    let dataOutOfDate = [];
                    let dataDetailTicket = [];
                    let dataDetailTicketByPage = [];
                    let dataDetailOutOfDate = [];
                    for(let key in data_report) {
                        total += data_report[key]?.conversations?.total || 0;
                        not_replied += data_report[key]?.conversations?.not_replied || 0;
                        replied += data_report[key]?.conversations?.replied || 0;
                        done += data_report[key]?.conversations?.done || 0;
                        let date = moment(key, 'YYYY-MM-DD').format('DD/MM');
                        let total_ticket = data_report[key]?.conversations?.total || 0;
                        let late_3_hours = data_report[key]?.conversations?.late_3_hours || 0;
                        let late_24_hours = data_report[key]?.conversations?.late_24_hours || 0;
                        late_reply += late_3_hours + late_24_hours;
                        dataTicket.push({date : date , conv : total_ticket});
                        dataOutOfDate.push({
                            date : date ,
                            total : total_ticket,
                            late_3_hours : late_3_hours,
                            late_24_hours : late_24_hours,
                        });
                        dataDetailTicket.push({
                            time : date,
                            total_conv : total_ticket,
                            new : data_report[key]?.conversations?.new || 0,
                            replied : data_report[key]?.conversations?.replied || 0,
                            not_reply : data_report[key]?.conversations?.not_replied || 0,
                            done : data_report[key]?.conversations?.done || 0,
                            late_reply : late_3_hours + late_24_hours,
                            total_mess_sent : data_report[key]?.messages?.total || 0,
                        });
                        let ratio_3_hours = (late_3_hours + late_24_hours) !== 0 ? (late_3_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "-";
                        let ratio_24_hours = (late_3_hours + late_24_hours) !== 0 ? (late_24_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "-";
                        dataDetailOutOfDate.push({
                            time : date,
                            total_conv : total_ticket,
                            total_late_conv : late_3_hours + late_24_hours,
                            late_3h : {
                                qty : late_3_hours,
                                ratio : ratio_3_hours
                            },
                            late_24h : {
                                qty : late_24_hours,
                                ratio : ratio_24_hours
                            }
                        });
                    }

                    for(let key2 in data_report_by_page) {
                        total_by_page += data_report_by_page[key2]?.conversations?.total || 0;
                        not_replied_by_page += data_report_by_page[key2]?.conversations?.not_replied || 0;
                        replied_by_page += data_report_by_page[key2]?.conversations?.replied || 0;
                        done_by_page += data_report_by_page[key2]?.conversations?.done || 0;
                        let late_3_hours_by_page = data_report_by_page[key2]?.conversations?.late_3_hours || 0;
                        let late_24_hours_by_page = data_report_by_page[key2]?.conversations?.late_24_hours || 0;
                        late_reply_by_page += late_3_hours_by_page + late_24_hours_by_page;
                        let page_by_page = data_report_by_page[key2]?.name || '';

                        dataDetailTicketByPage.push({
                            page : page_by_page,
                            total_conv : total_by_page,
                            new : data_report_by_page[key2]?.conversations?.new || 0,
                            replied : data_report_by_page[key2]?.conversations?.replied || 0,
                            not_reply : data_report_by_page[key2]?.conversations?.not_replied || 0,
                            done : data_report_by_page[key2]?.conversations?.done || 0,
                            late_reply : late_3_hours_by_page + late_24_hours_by_page,
                            total_mess_sent : data_report_by_page[key2]?.messages?.total || 0,
                        });
                    }

                    let from_report = null;
                    let to_report = null;

                    if(isIOS){
                        from_report = moment(from, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
                        to_report = moment(to, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
                    }else{
                        from_report = moment(from, 'YYYY-MM-DD').format('DD/MM/YYYY');
                        to_report = moment(to, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    }

                    let reportData = {
                        head : {
                            username : null,
                            from : from_report,
                            to : to_report,
                            all : total,
                            not_reply : not_replied,
                            replied : replied,
                            done : done,
                            late_reply : late_reply,
                        },
                        dataTicket : dataTicket ,
                        dataOutOfDate : dataOutOfDate ,
                        dataBug : [] ,
                        dataDetailTicket : dataDetailTicket,
                        dataDetailTicketByPage : dataDetailTicketByPage,
                        dataDetailOutOfDate : dataDetailOutOfDate,
                        dataDetailBug : [] ,
                    }
                    dispatch(saveReportData(reportData));
                }

            }).catch(err => {

        });

    }
}

export const handleSearchReportHavePageId = (from , to , page_id , user_id) => {
    return (dispatch, getState) => {
        let from_after = null;
        let to_after = null;
        if(isIOS){
            from_after = moment(from, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({hour:0,minute:0,second:0,millisecond:0}).format("x")/1000;
            to_after = moment(to, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({hour:23,minute:59,second:0,millisecond:0}).format("x")/1000;
        }else{
            from_after = convertDateFromTimeStampInReport(from);
            to_after = convertDateToTimeStampInReport(to);
        }
        reportApis.getReportDailyHavePageId(from_after , to_after , page_id , user_id)
            .then(rs => {
                if(rs.data.code === 0){
                    let data_report = rs.data.data.data;
                    let total = 0;
                    let not_replied = 0;
                    let replied = 0;
                    let done = 0;
                    let late_reply = 0;
                    let dataTicket = [];
                    let dataOutOfDate = [];
                    let dataBug = [];
                    let dataDetailTicket = [];
                    let dataDetailOutOfDate = [];

                    for(let key in data_report) {
                        total += data_report[key]?.data?.conversations?.total || 0;
                        not_replied += data_report[key]?.data?.conversations?.not_replied || 0;
                        replied += data_report[key]?.data?.conversations?.replied || 0;
                        done += data_report[key]?.data?.conversations?.done || 0;
                        let date = moment(data_report[key]?.from_date, 'YYYY-MM-DD').format('DD/MM');
                        let total_ticket = data_report[key]?.data?.conversations?.total || 0;
                        let late_3_hours = data_report[key]?.data?.conversations?.late_3_hours || 0;
                        let late_24_hours = data_report[key]?.data?.conversations?.late_24_hours || 0;
                        late_reply += late_3_hours + late_24_hours;
                        dataTicket.push({date : date , conv : total_ticket});
                        dataOutOfDate.push({
                            date : date ,
                            total : total_ticket,
                            late_3_hours : late_3_hours,
                            late_24_hours : late_24_hours,
                        });

                        dataDetailTicket.push({
                            time : date,
                            total_conv : total_ticket,
                            new : data_report[key]?.data?.conversations?.new || 0,
                            replied : data_report[key]?.data?.conversations?.replied || 0,
                            not_reply : data_report[key]?.data?.conversations?.not_replied || 0,
                            done : data_report[key]?.data?.conversations?.done || 0,
                            late_reply : late_3_hours + late_24_hours,
                            total_mess_sent : data_report[key]?.data?.messages?.total || 0,
                        });
                        let ratio_3_hours = (late_3_hours + late_24_hours) !== 0 ? (late_3_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "0 %";
                        let ratio_24_hours = (late_3_hours + late_24_hours) !== 0 ? (late_24_hours/(late_3_hours + late_24_hours) * 100).toFixed(1) + " %" : "0 %";
                        dataDetailOutOfDate.push({
                            time : date,
                            total_conv : total_ticket,
                            total_late_conv : late_3_hours + late_24_hours,
                            late_3h : {
                                qty : late_3_hours,
                                ratio : ratio_3_hours
                            },
                            late_24h : {
                                qty : late_24_hours,
                                ratio : ratio_24_hours
                            }
                        });


                        let listUser = getState().Report.listUser;
                        var userId = data_report[key]?.user_id || null;
                        var username = null;
                        listUser.filter(x => {
                            if(x.id === userId){
                                if(x.name && x.name !== "All"){
                                    username = x.name;
                                }else{
                                    username = 'All user';
                                }
                            }
                        });

                    }

                    // let from_report = moment(from, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    // let to_report = moment(to, 'YYYY-MM-DD').format('DD/MM/YYYY');

                    let from_report = null;
                    let to_report = null;

                    if(isIOS){
                        from_report = moment(from, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
                        to_report = moment(to, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY');
                    }else{
                        from_report = moment(from, 'YYYY-MM-DD').format('DD/MM/YYYY');
                        to_report = moment(to, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    }

                    reportApis.getReportTagBug( from_after , to_after , page_id).then(result => {
                        if(result.data.code === 0){
                            let data_bug = result.data.data.data;
                            let total_tag_bug = result.data.data.total;
                            let dataDetailBug = {};
                            dataDetailBug.tag = [];
                            let dataListTagDetailBug = [];
                            Object.keys(data_bug).map(function(objectKey, index) {
                                let value = data_bug[objectKey];
                                let ratio_bug = (value/total_tag_bug) !== 0 ? ((value/total_tag_bug) * 100).toFixed(1) + " %" : "0 %";
                                // dataDetailBug.total_conv = total_conv;
                                dataDetailBug.total_conv = total;
                                dataDetailBug.tag.push({
                                    qty :  value,
                                    ratio :  ratio_bug,
                                });
                                dataBug.push({
                                    name : objectKey ,
                                    value : value,
                                });
                                dataListTagDetailBug.push(objectKey);
                            });

                            let reportData = {
                                head : {
                                    username : username,
                                    from : from_report,
                                    to : to_report,
                                    all : total,
                                    not_reply : not_replied,
                                    replied : replied,
                                    done : done,
                                    late_reply : late_reply,
                                },
                                dataTicket : dataTicket ,
                                dataOutOfDate : dataOutOfDate ,
                                dataBug : dataBug ,
                                dataDetailTicket : dataDetailTicket,
                                dataDetailOutOfDate : dataDetailOutOfDate,
                                dataDetailBug : dataDetailBug || {} ,
                                dataListTagDetailBug : dataListTagDetailBug || [] ,
                            }
                            dispatch(saveReportData(reportData));

                        }

                    }).catch(err => {

                    });
                }
            }).catch(err => {
        });
    }
}


