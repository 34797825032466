import {
    ERROR_QUICK_CREATE_TAG_SIDEBAR,
} from './constants';

const INIT_STATE = {
    errorQuickCreateTag : false
};

const Error = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ERROR_QUICK_CREATE_TAG_SIDEBAR:
            return {
                ...state,
                errorQuickCreateTag: action.payload.errorQuickCreateTag,
            };

        default:
            return {...state};
    }
}

export default Error;
