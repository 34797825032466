import {
    GET_LIST_CHAT,
    GET_LIST_CHAT_SUCCESS,
    GET_LIST_CHAT_ERROR,
    ACTIVE_USER,
    ACTIVE_CHAT,
    ACTIVE_FB_USER_ID,
    ACTIVE_INFO,
    UPDATE_LAST_MESSAGE_IN_CHAT,
    UPDATE_LIST_CHAT_WHEN_NEW_MESSAGE,
    CHECK_STATUS_NAME_NEW_MESSAGE,
    SET_STATUS_LIST_CHAT,
    DONE_CONVERSATION,
    SET_OPTION_FILTER,
    FILTER_SEARCH_CHAT,
    CLEAR_FILTER_SEARCH_CHAT,
    ASSIGN_CHAT_SUCCESS,
    MARK_READ_CHAT_SUCCESS,
    UPDATE_PAGE_LOAD_MORE_CHAT,
    UPDATE_CHAT_AFTER_DONE_CONVERSATION,
    UPDATE_CHAT_AFTER_DONE_MULTI_CONVERSATION,
    ADD_CHECKED_TO_CHAT,
    REMOVE_CHECKED_TO_CHAT,
    RESET_CHECKED_TO_CHAT,
    ADD_ALL_CHECKED_TO_CHAT,
    REMOVE_ALL_CHECKED_TO_CHAT,
    UPDATE_LIST_CHATS_AFTER_ASSIGN,
    SAVE_NAME_FILTER_CHAT,
    CHANGE_HEIGHT_INPUT_CHAT,
    CHANGE_HEIGHT_INPUT_CHAT_MOBILE, ACTIVE_CHAT_BY_ID,
} from './constants';

const INIT_STATE = {
    active_user : null,
    listChecked: [],
    listChats: [],
    groups : [],
    contacts : [],
    statusName: null,
    filterChat : {
        sort : {},
        status : {},
        assign : {},
        tags : [],
        textSearch : null
    },
    page_pagination : 1,
    scrollHeightStore : 43,
    scrollHeightStoreMobile : 43,
};

const Chat = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTIVE_USER:
            return {
                ...state,
                active_user : action.payload
            };
        case ACTIVE_CHAT:
            return {
                ...state,
                chat_id : action.payload
            };
        case ACTIVE_FB_USER_ID:
            return {
                ...state,
                fb_user_id : action.payload
            };
        case GET_LIST_CHAT:
            return {
                ...state,
                listChats: []
            };
        case GET_LIST_CHAT_SUCCESS:
            const { data } = action.payload;
            return {
                ...state,
                listChats: data
            };
        case GET_LIST_CHAT_ERROR:
            return {
                ...state,
                listChats: []
            };
        case ACTIVE_INFO:
            const { info } = action.payload;
            return {
                ...state,
                info: info
            };
        case UPDATE_LAST_MESSAGE_IN_CHAT:
            return {
                ...state,
                listChats: action.payload.listChats
            };
        case UPDATE_LIST_CHAT_WHEN_NEW_MESSAGE:
            return {
                ...state,
                listChats: action.payload.obj_update
            };

        case CHECK_STATUS_NAME_NEW_MESSAGE:
            return {
                ...state,
                listChats: action.payload.obj_update
            };

        case SET_STATUS_LIST_CHAT:
            return {
                ...state,
                statusName: action.payload.statusName
            };

        case DONE_CONVERSATION:
            return {
                ...state,
                listChats: action.payload.listChats
            };

        case SET_OPTION_FILTER:
            return {
                ...state,
                filterChat: action.payload.filterChat
            };

        case FILTER_SEARCH_CHAT:
            return {
                ...state,
                listChats: action.payload.listChats
            };

        case UPDATE_CHAT_AFTER_DONE_MULTI_CONVERSATION:
            return {
                ...state,
                listChats: action.payload.listChats
            };

        case CLEAR_FILTER_SEARCH_CHAT:
            return {
                ...state,
                filterChat : {
                    sort : {},
                    status : {},
                    assign : {},
                    tags : [],
                    textSearch : null
                }
            };

        case ASSIGN_CHAT_SUCCESS:
            return {
                ...state,
                listChats: action.payload.listChats
            };

        case MARK_READ_CHAT_SUCCESS:
            return {
                ...state,
                listChats: action.payload.listChats
            };

        case UPDATE_CHAT_AFTER_DONE_CONVERSATION:
            return {
                ...state,
                listChats: action.payload.listChats
            };

        case UPDATE_LIST_CHATS_AFTER_ASSIGN:
            return {
                ...state,
                listChats: action.payload.listChats
            };

        case UPDATE_PAGE_LOAD_MORE_CHAT:
            return {
                ...state,
                page_pagination: action.payload.data
            };

        case ADD_CHECKED_TO_CHAT:
            return {
                ...state,
                listChecked: action.payload.listChecked
            };

        case REMOVE_CHECKED_TO_CHAT:
            return {
                ...state,
                listChecked: action.payload.listChecked
            };

        case ADD_ALL_CHECKED_TO_CHAT:
            return {
                ...state,
                listChecked: action.payload.listChecked
            };

        case REMOVE_ALL_CHECKED_TO_CHAT:
            return {
                ...state,
                listChecked: []
            };

        case RESET_CHECKED_TO_CHAT:
            return {
                ...state,
                listChecked: []
            };

        case SAVE_NAME_FILTER_CHAT:
            return {
                ...state,
                filterChat : action.payload.filterChat
            };

        case CHANGE_HEIGHT_INPUT_CHAT:
            return {
                ...state,
                scrollHeightStore : action.payload.scrollHeightStore
            };

        case CHANGE_HEIGHT_INPUT_CHAT_MOBILE:
            return {
                ...state,
                scrollHeightStoreMobile : action.payload.scrollHeightStoreMobile
            };

        case ACTIVE_CHAT_BY_ID:
            return {
                ...state,
                chat_id : action.payload.chat_id
            };

        default: return { ...state };
    }
}

export default Chat;
