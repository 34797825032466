import axiosClient from "../helpers/axiosClient";
import {API_PAGES, API_QUICK_REPLIES, QUICK_REPLIES, REACT_APP_CHAT} from "./constants";
import {combineUrlParams} from "./../helpers/index";

export const getListQuickReply = (page_id, name = '', limit = 100, page = 1) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${QUICK_REPLIES}`,
            {
                name: name,
                sort: "-created_at",
                limit: limit,
                page: page
            }
        )
    );
}

export const getMoreListQuickReply = (page_id, page = 1 , name = '', limit = 100) => {
    return axiosClient.get(
        combineUrlParams(
            `${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${QUICK_REPLIES}`,
            {
                sort: "-created_at",
                limit: limit,
                name: name,
                page: page
            }
        )
    );
}

export const createQuickReply = (page_id, data) => {
    return axiosClient.post(`${REACT_APP_CHAT}/${API_PAGES}/${page_id}/${QUICK_REPLIES}`, data);
}

export const removeQuickReply = (quick_reply_id) => {
    return axiosClient.delete(`${REACT_APP_CHAT}/${API_QUICK_REPLIES}/${quick_reply_id}`);
}

export const editQuickReply = (quick_reply_id, data) => {
    return axiosClient.put(`${REACT_APP_CHAT}/${API_QUICK_REPLIES}/${quick_reply_id}`, data);
}

