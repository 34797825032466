import moment from "moment";

export const combineUrlParams = (url = "", params = {}) => {
    const keys = Object.keys(params);
    const paramUrl = keys
        .reduce(
            (result, key) =>
                params[key] !== undefined &&
                params[key] !== null &&
                params[key] !== ""
                    ? [...result, `${key}=${params[key]}`]
                    : [...result],
            []
        )
        .join("&");
    return `${paramUrl}` ? `${url}?${paramUrl}` : `${url}`;
};

export const configLink = () => {
    return [{
        regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
        fn: (key, result) => <span key={key}>
                                     <a target="_blank"
                                        href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                                 </span>
    }, {
        regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
        fn: (key, result) => <span key={key}>
                                     <a target="_blank"
                                        href={`http://${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                                 </span>
    }];
};

export const getArrayDateInTwoDate = (start_date, end_date) => {
    var startDate = new Date(start_date); //YYYY-MM-DD
    var endDate = new Date(end_date); //YYYY-MM-DD
    var date_range = [];
    var st_date = new Date(startDate);
    while (st_date <= endDate) {
        let month = ("0" + (st_date.getMonth() + 1)).slice(-2);
        let day = ("0" + st_date.getDate()).slice(-2);
        let date = [st_date.getFullYear(), month, day].join("-");
        date_range.push(date);
        st_date.setDate(st_date.getDate() + 1);
    }
    return date_range;
};

export const getDayInCurrentWeek = () => {
    let curr = new Date;
    let first = curr.getDate() - curr.getDay();
    let dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let arr_day = [];
    for (let i = 1; i <= 7; i++) {
        let next = new Date(curr.getTime());
        next.setDate(first + i);
        arr_day.push(dayOfWeek[next.getDay()]);
    }
    return arr_day;
};

export const getDateInCurrentWeek = () => {
    let curr = new Date;
    let first = curr.getDate() - curr.getDay();
    let month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let arr_date = [];
    for (let i = 1; i <= 7; i++) {
        let next = new Date(curr.getTime());
        next.setDate(first + i);
        arr_date.push(next.getDate() + '/' + month[next.getMonth()] + '/' + next.getFullYear());
    }
    return arr_date;
};

export const getDateInCurrentWeekIsMonday = () => {
    let curr = new Date;
    let first = curr.getDate() - curr.getDay();
    let month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let arr_date = [];
    for (let i = 1; i <= 7; i++) {
        if (i === 1) {
            let next = new Date(curr.getTime());
            next.setDate(first + i);
            arr_date.push(next.getFullYear() + '-' + month[next.getMonth()] + '-' + next.getDate());
        }
    }
    return arr_date;
};

export const getTimestampStartCurrentWeek = () => {
    let monday = getDateInCurrentWeekIsMonday()[0];
    return moment(monday).startOf('day').unix();
};

export const getTimestampEndCurrentWeek = () => {
    let monday = getDateInCurrentWeekIsMonday()[0];
    return moment(monday).add(6, 'days').endOf('day').unix();
};

//devices IOS
export const getDateInCurrentWeekIsMondayIOS = () => {
    let curr = new Date;
    let first = curr.getDate() - curr.getDay();
    let month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let arr_date = [];
    for (let i = 1; i <= 7; i++) {
        if (i === 1) {
            let next = new Date(curr.getTime());
            next.setDate(first + i);
            arr_date.push(next.getFullYear() + '-' + month[next.getMonth()] + '-' + next.getDate());
        }
    }
    return arr_date;
};

export const getTimestampStartCurrentWeekIOS = () => {
    let monday = getDateInCurrentWeekIsMondayIOS()[0];
    return moment(monday, 'YYYY-MM-DD[T]HH:mm:ss').utcOffset(7).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
    }).format("x") / 1000;
};

export const getTimestampEndCurrentWeekIOS = () => {
    let monday = getDateInCurrentWeekIsMondayIOS()[0];
    return moment(monday, 'YYYY-MM-DD[T]HH:mm:ss').add(6, 'd').utcOffset(7).set({
        hour: 23,
        minute: 59,
        second: 0,
        millisecond: 0
    }).format("x") / 1000;
};

export const groupByTimeStampInShift = (array, key = 'from') => {
    return array.reduce((result, currentItem) => {
        let date = new Date(currentItem[key] * 1000);
        let datetime = date.getDate() +
            "/" + (date.getMonth() + 1) +
            "/" + date.getFullYear();
        datetime = convertDateToTimeStampInShift(datetime);
        (result[datetime] = result[datetime] || []).push(currentItem);
        return result;
    }, {});
};

export const convertObjectInShift = (array) => {
    return array.map(obj => ({
        ...obj,
        shifts: groupByTimeStampInShift(obj.shifts)
    }));
};

export const convertDateToTimeStampInShift = (date) => {
    let myDate = date.split("/");
    let newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    return newDate.getTime() / 1000;
};

export const getHourMinute = (timestamp) => {
    let hour = new Date(timestamp * 1000).getHours();
    let minute = new Date(timestamp * 1000).getMinutes();
    if (hour <= 9) {
        hour = '0' + hour;
    }
    if (minute <= 9) {
        minute = '0' + minute;
    }
    return hour + ':' + minute;
};

//date : YYYY-MM-DD
export const convertDateToDayOfWeek = (date) => {
    let dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let dt = new Date(date);
    let numberDay = dt.getDay();
    return dayOfWeek[numberDay];
};

//startDate , endDate : YYYY-MM-DD
export const getDayInAnyTime = (startDate, endDate) => {
    let arr_date = [];

    let getDaysArray = function (s, e) {
        for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
            a.push(new Date(d));
        }
        return a;
    };

    let daylist = getDaysArray(new Date(startDate), new Date(endDate));

    daylist.map((v) => arr_date.push(convertDateToDayOfWeek(v.toISOString().slice(0, 10)))).join("");

    return arr_date;
};

//startDate , endDate : YYYY-MM-DD
export const getDateInAnyTime = (startDate, endDate) => {
    let arr_day = [];

    let getDaysArray = function (s, e) {
        for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
            a.push(new Date(d));
        }
        return a;
    };

    let daylist = getDaysArray(new Date(startDate), new Date(endDate));

    daylist.map((v) => arr_day.push(new Date(v.toISOString().slice(0, 10)).getDate() +
        "/" + (new Date(v.toISOString().slice(0, 10)).getMonth() + 1) +
        "/" + new Date(v.toISOString().slice(0, 10)).getFullYear())).join("");

    return arr_day;
};


export const toTimestamp = (strDate, type) => {
    let datum = null;
    if (type === 'start') {
        datum = Date.parse(strDate + ' ' + '00:00');
    } else if (type === 'end') {
        datum = Date.parse(strDate + ' ' + '23:59');
    }
    return datum / 1000;
}

export const checkActiveShift = (from, end) => {
    let now = Math.round(new Date().getTime() / 1000);
    if (from <= now && now <= end) {
        return true;
    } else {
        return false;
    }
}

export const convertTimestampToDate = (timestamp) => {
    return new Date(timestamp).getFullYear() +
        "-" + (new Date(timestamp).getMonth() + 1) +
        "-" + new Date(timestamp).getDate();
}

export const getDifferenceInDays = (date1, date2) => {
    let date1_format = new Date(date1);
    let date2_format = new Date(date2);
    let diffInMs = Math.abs(date2_format - date1_format);
    return diffInMs / (1000 * 60 * 60 * 24);
}

export const convertDateFromTimeStampInReport = (date) => {
    let timeStamp = new Date(date + " 12:00 AM");
    return timeStamp.getTime() / 1000;
};

export const convertDateToTimeStampInReport = (date) => {
    let timeStamp = new Date(date + " 11:59 PM");
    return timeStamp.getTime() / 1000;
};

export const randomArrColor = (number) => {
    let arrColor = [];
    for (let i = 1; i <= number; i++) {
        let color = randomColor();
        arrColor.push(color);
    }
    return arrColor;
};

export const randomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const randomColorLimit = (index) => {
    let arr = [
        "#fca904",
        "#37C85E",
        "#f30007",
        "#8F5BA1",
        "#18E6F8",
        "#1323EB",
        "#E31987",
        "#21074B",
        "#bee385",
        "#F39173",
        "#778172",
        "#816808",
        "#2c3d0a",
        "#5b6bee",
        "#f15537",
        "#599ED6",
        "#092E77",
        "#eea0a0",
        "#0C5833",
        "#811e1e",
        "#472301",
    ];
    let color = null;
    if (index > 20) {
        color = randomColor();
    } else {
        color = arr[index];
    }
    return color;
};

export const parseToken = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

export const handleCheckOneChat = () => {
    let url = new URL(window.location.href);
    let pageId = url.searchParams.get("pageId") ? url.searchParams.get("pageId") : null;
    let chatId = url.searchParams.get("chatId") ? url.searchParams.get("chatId") : null;
    return !!(chatId && pageId);
};
